import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import useInterval from '@use-it/interval';
import { useTransition, useChain, animated } from 'react-spring';

function Slider(props) {
	const [ slideIndex, setSlideIndex ] = useState(0);
	//	const item = props.items[slideIndex]
	const changeSlider = () => setSlideIndex((index) => (index + 1) % props.items.length);
	useInterval(changeSlider, props.duration || 5000);
	//const imageBackgroundAnimationRef = useRef();
	const imageBackgroundAnimation = useTransition(props.items[slideIndex], (item) => item.key, {
		from: { opacity: 0, transform: 'scale(1.1)' },
		enter: { opacity: 1, transform: 'scale(1.0)' },
		leave: { opacity: 0, transform: 'scale(0.9)' }
		//ref: imageBackgroundAnimationRef
	});

	const sliderElement = {
		position: 'absolute',
		top: 0,
		width: props.isFullScreen ? '100%' : props.width,
		height: props.isFullScreen ? '100%' : props.height,
		display: 'flex',
		flex: 1
	};

	return imageBackgroundAnimation.map(({ item, props, key }) => {
		return (
			<animated.div
				key={key}
				style={{
					position: "absolute",
					width: "100%",
					height: "100%",
					...props
				}}
			>
				{item.component}
			</animated.div>
		);
	});
}

export default Slider;
