import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { TiCancel } from 'react-icons/ti';
import { ADHESION, CADASTRO_ONGOLD } from '../../../../../utils/consts.js';
import { RedirectLinkPlano } from '../../../../Header/styles.js';
import { TablePlan, TableHeader, TableBody, TableFooter } from './TablePlans.css.jsx';

import './styles.css';

function TablePlans() {
	return (
		<div>
			{' '}
			<TablePlan>
				<TableHeader>
					<tr>
						<th>
							<h3>Opções</h3>
						</th>
						<th>
							<h2 style={{ marginTop: '20px' }}>Grátis</h2>
							<h5 style={{ color: 'red', fontSize: '0.8rem', fontWeight: 'bold' }}>*Contém anúncios</h5>
						</th>
						<th>
							<h2>Bronze</h2>
						</th>
						<th>
							<h2>Silver</h2>
						</th>
						<th>
							<h2>Gold</h2>
						</th>
					</tr>
				</TableHeader>
				<TableBody>
					<tr>
						<td style={{ fontFamily: 'Helvetica' }}>Categorias </td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />{' '}
						</td>
					</tr>
					<tr>
						<td style={{ fontFamily: 'Helvetica' }}>Clientes </td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />{' '}
						</td>
					</tr>
					<tr>
						<td style={{ fontFamily: 'Helvetica' }}>Emissão de Pedidos </td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />
						</td>
						<td>
							<FaCheck />
						</td>
					</tr>
					<tr>
						<td style={{ fontFamily: 'Helvetica' }}>Emissão de Vendas </td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />{' '}
						</td>
					</tr>
					<tr>
						<td style={{ fontFamily: 'Helvetica' }}>Produtos </td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />
						</td>
						<td>
							<FaCheck />
						</td>
					</tr>
					<tr>
						<td style={{ fontFamily: 'Helvetica' }}>Unidades </td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />{' '}
						</td>
					</tr>
					<tr>
						<td style={{ fontFamily: 'Helvetica' }}>Versão Web </td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />
						</td>
						<td>
							<FaCheck />
						</td>
						<td>
							<FaCheck />
						</td>
					</tr>
					<tr>
						<td style={{ fontFamily: 'Helvetica' }}>Versão Mobile (Smartphone)</td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />
						</td>
						<td>
							<FaCheck />
						</td>
						<td>
							<FaCheck />
						</td>
					</tr>
					<tr>
						<td style={{ fontFamily: 'Helvetica' }}>Dashboard </td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />
						</td>
						<td>
							<FaCheck />
						</td>
					</tr>
					<tr>
						<td style={{ fontFamily: 'Helvetica' }}>Imagem de Produto </td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />
						</td>
						<td>
							<FaCheck />
						</td>
						<td>
							<FaCheck />
						</td>
					</tr>
					<tr>
						<td style={{ fontFamily: 'Helvetica' }}>Emissão de Pedido e NFC-e </td>
						<td style={{ fontWeight: 'bold' }}>
							10 <h5 style={{ color: '#000', fontSize: '0.8rem', fontWeight: 'bold' }}>por mês</h5>
						</td>
						<td style={{ fontWeight: 'bold' }}>
							400 <h5 style={{ color: '#000', fontSize: '0.8rem', fontWeight: 'bold' }}>por mês</h5>
						</td>
						<td style={{ fontWeight: 'bold' }}>
							1000 <h5 style={{ color: '#000', fontSize: '0.8rem', fontWeight: 'bold' }}>por mês</h5>
						</td>
						<td style={{ fontWeight: 'bold' }}>
							5000 <h5 style={{ color: '#000', fontSize: '0.8rem', fontWeight: 'bold' }}>por mês</h5>
						</td>
					</tr>
					<tr>
						<td style={{ fontFamily: 'Helvetica' }}>Nº de Usuários </td>
						<td style={{ fontWeight: 'bold' }}>1 </td>
						<td style={{ fontWeight: 'bold' }}>3</td>
						<td style={{ fontWeight: 'bold' }}>8</td>
						<td style={{ fontWeight: 'bold' }}>15</td>
					</tr>
					<tr>
						<td style={{ fontFamily: 'Helvetica' }}>Espaço em Disco </td>
						<td style={{ fontWeight: 'bold' }}>5MB </td>
						<td style={{ fontWeight: 'bold' }}>30MB</td>
						<td style={{ fontWeight: 'bold' }}>150MB</td>
						<td style={{ fontWeight: 'bold' }}>300MB</td>
					</tr>

					<tr>
						<td style={{ fontFamily: 'Helvetica' }}>Ajuste de Estoque </td>
						<td>
							<TiCancel size={24} />{' '}
						</td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />{' '}
						</td>
						<td>
							<FaCheck />{' '}
						</td>
					</tr>
					<tr>
						<td style={{ fontFamily: 'Helvetica' }}>Suporte Humanizado </td>
						<td>
							<TiCancel size={24} />{' '}
						</td>
						<td>
							<FaCheck />
						</td>
						<td>
							<FaCheck />
						</td>
						<td>
							<FaCheck />
						</td>
					</tr>
					<tr>
						<td style={{ fontFamily: 'Helvetica' }}>Relatórios Básicos </td>
						<td>
							<TiCancel size={24} />{' '}
						</td>
						<td>
							<FaCheck />
						</td>
						<td>
							<FaCheck />
						</td>
						<td>
							<FaCheck />
						</td>
					</tr>
					{/* <tr>
                <td style={{ fontFamily: 'Helvetica' }}>Contém Anúncios </td>
                <td><TiCancel size={24} /> </td>
                <td><FaCheck /></td>
                <td><FaCheck /></td>
                <td><FaCheck /></td>
            </tr> */}
					<tr>
						<td style={{ fontFamily: 'Helvetica' }}>Controle de Estoque </td>
						<td>
							<TiCancel size={24} />{' '}
						</td>
						<td>
							<FaCheck />
						</td>
						<td>
							<FaCheck />
						</td>
						<td>
							<FaCheck />
						</td>
					</tr>

					<tr>
						<td style={{ fontFamily: 'Helvetica' }}>Contas a Pagar (Financeiro) </td>
						<td>
							<TiCancel size={24} />{' '}
						</td>
						<td>
							<FaCheck />
						</td>
						<td>
							<FaCheck />
						</td>
						<td>
							<FaCheck />
						</td>
					</tr>

					<tr>
						<td style={{ fontFamily: 'Helvetica' }}>Relatórios Avançados </td>
						<td>
							<TiCancel size={24} />{' '}
						</td>
						<td>
							<TiCancel size={24} />
						</td>
						<td>
							<FaCheck />
						</td>
						<td>
							<FaCheck />
						</td>
					</tr>

					<tr>
						<td style={{ fontFamily: 'Helvetica' }}>Entrada de NF-e p/ XML </td>
						<td>
							<TiCancel size={24} />{' '}
						</td>
						<td>
							<TiCancel size={24} />
						</td>
						<td>
							<FaCheck />
						</td>
						<td>
							<FaCheck />
						</td>
					</tr>
					{/* <tr>
                <td style={{ fontFamily: 'Helvetica' }}>Treinamento Remoto </td>
                <td><TiCancel size={24} /> </td>
                <td><TiCancel size={24} /></td>
                <td><FaCheck /></td>
                <td><FaCheck /></td>
            </tr> */}

					{/* <tr>
                <td style={{ fontFamily: 'Helvetica' }}>Anúncios </td>
                <td><FaCheck /> </td>
                <td><TiCancel size={24} /> </td>
                <td><TiCancel size={24} /> </td>
                <td><TiCancel size={24} /> </td>
            </tr> */}
					{/* <tr>
                <td style={{ fontFamily: 'Helvetica' }}> </td>
                <td style={{ fontWeight: 'bold', fontSize: '3rem' }}><span style={{ fontSize: '1rem', fontFamily: 'Helvetica' }}> R$</span> 0,00 </td>
                <td style={{ fontWeight: 'bold', fontSize: '3rem', color: '#000' }}><span style={{ fontSize: '1rem'  }}> R$</span>  50,00 </td>
                <td style={{ fontWeight: 'bold', fontSize: '3rem', color: '#000' }}><span style={{ fontSize: '1rem' }}> R$</span>  80,00 </td>
                <td style={{ fontWeight: 'bold', fontSize: '3rem', color: '#000' }}><span style={{ fontSize: '1rem' }}> R$</span>  120,00 </td>
            </tr> */}
				</TableBody>
				<TableFooter>
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
				</TableFooter>
			</TablePlan>
			<br></br>
			<TablePlan>
				<TableHeader>
					<tr>
						<th style={{ fontFamily: 'Helvetica' }}></th>
						<th
							style={{
								borderBottomLeftRadius: '0px',
								borderBottomRightRadius: '0px',
								borderTopRightRadius: '0px',
								borderTopLeftRadius: '15px',
							}}
						>
							<h2 style={{ fontSize: '0.8rem', textAlign: 'center' }}>
								<span style={{ color: '#000', fontWeight: 'bold' }}>*</span> Este plano não possui mensalidade nem taxas
								nas vendas
							</h2>
						</th>
						<th
							style={{
								borderBottomLeftRadius: '0px',
								borderBottomRightRadius: '0px',
								borderTopRightRadius: '0px',
								borderTopLeftRadius: '0px',
							}}
						>
							<h2 style={{ fontSize: '0.8rem', textAlign: 'center' }}>
								<span style={{ color: '#000', fontWeight: 'bold' }}>*</span> A mensalidade será cobrada em 30 dias
							</h2>
							<h2 style={{ fontSize: '0.8rem', textAlign: 'center', fontWeight: 'bold' }}>
								<span style={{ color: '#000', fontWeight: 'bold' }}>*</span> Valor da adesão de <br /> {ADHESION}
							</h2>
						</th>
						<th
							style={{
								borderBottomLeftRadius: '0px',
								borderBottomRightRadius: '0px',
								borderTopRightRadius: '0px',
								borderTopLeftRadius: '0px',
							}}
						>
							<h2 style={{ fontSize: '0.8rem', textAlign: 'center' }}>
								<span style={{ color: '#000', fontWeight: 'bold' }}>*</span> A mensalidade será cobrada em 30 dias
							</h2>
							<h2 style={{ fontSize: '0.8rem', textAlign: 'center', fontWeight: 'bold' }}>
								<span style={{ color: '#000', fontWeight: 'bold' }}>*</span> Valor da adesão de <br /> {ADHESION}
							</h2>
						</th>
						<th
							style={{
								borderBottomLeftRadius: '0px',
								borderBottomRightRadius: '0px',
								borderTopRightRadius: '15px',
								borderTopLeftRadius: '0px',
							}}
						>
							<h2 style={{ fontSize: '0.8rem', textAlign: 'center' }}>
								<span style={{ color: '#000', fontWeight: 'bold' }}>*</span> A mensalidade será cobrada em 30 dias
							</h2>
							<h2 style={{ fontSize: '0.8rem', textAlign: 'center', fontWeight: 'bold' }}>
								<span style={{ color: '#000', fontWeight: 'bold' }}>*</span> Valor da adesão de <br /> {ADHESION}
							</h2>
						</th>
					</tr>
					<tr>
						<th style={{ fontFamily: 'Helvetica' }}></th>
						<th
							style={{
								borderBottomLeftRadius: '15px',
								borderBottomRightRadius: '0px',
								borderTopRightRadius: '0px',
								borderTopLeftRadius: '0px',
								textAlign: 'center',
							}}
						>
							<h2 style={{ fontSize: '2rem', fontWeight: 'bold' }}>
								<span style={{ fontSize: '1rem', fontFamily: 'Helvetica', color: '#000' }}> R$</span> 0,00
							</h2>
						</th>
						<th
							style={{
								borderBottomLeftRadius: '0px',
								borderBottomRightRadius: '0px',
								borderTopRightRadius: '0px',
								borderTopLeftRadius: '0px',
								textAlign: 'center',
							}}
						>
							<h2 style={{ fontSize: '2rem', fontWeight: 'bold' }}>
								<span style={{ fontSize: '1rem', fontFamily: 'Helvetica', color: '#000' }}> R$</span> 69,99
							</h2>
							<h2 style={{ fontSize: '1rem', fontFamily: 'Helvetica', color: '#000', textAlign: 'center' }}>
								Licença mensal recorrente
							</h2>
						</th>
						<th
							style={{
								borderBottomLeftRadius: '0px',
								borderBottomRightRadius: '0px',
								borderTopRightRadius: '0px',
								borderTopLeftRadius: '0px',
								textAlign: 'center',
							}}
						>
							<h2 style={{ fontSize: '2rem', fontWeight: 'bold' }}>
								<span style={{ fontSize: '1rem', fontFamily: 'Helvetica', color: '#000' }}> R$</span> 99,99
							</h2>
							<h2 style={{ fontSize: '1rem', fontFamily: 'Helvetica', color: '#000', textAlign: 'center' }}>
								Licença mensal recorrente
							</h2>
						</th>
						<th
							style={{
								borderBottomLeftRadius: '0px',
								borderBottomRightRadius: '15px',
								borderTopRightRadius: '0px',
								borderTopLeftRadius: '0px',
								textAlign: 'center',
							}}
						>
							<h2 style={{ fontSize: '2rem', fontWeight: 'bold' }}>
								<span style={{ fontSize: '1rem', fontFamily: 'Helvetica', color: '#000', marginLeft: '5px' }}> R$</span>{' '}
								229,99
							</h2>
							<h2 style={{ fontSize: '1rem', fontFamily: 'Helvetica', color: '#000', textAlign: 'center' }}>
								Licença mensal recorrente
							</h2>
						</th>
					</tr>
				</TableHeader>
			</TablePlan>
			<br />
			<br />
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
				}}
				className='cadastro-lojista-button'
			>
				{/* <OgButtonRounded style={{ background: '#EDD47A', color: '#000', borderColor: '#EDD47A' }}>Adquirir plano</OgButtonRounded> */}
				{/* <RedirectLinkPlano href='https://compradaqui.com.br/' target='_blank' style={{ background: '#EDD47A', color: '#000', borderColor: '#EDD47A' }}>Adquirir plano</RedirectLinkPlano> */}
				<RedirectLinkPlano
					href={CADASTRO_ONGOLD}
					target='_blank'
					style={{ background: '#00C18A', color: '#fff', borderColor: '#00C18A' }}
				>
					Experimente Grátis
				</RedirectLinkPlano>
			</div>
		</div>
	);
}

export default TablePlans;
