// nodejs library that concatenates strings
import React, { useState } from 'react';
//Style
import { Logo } from '../../../css/styles';
import '../../../../src/main.css';
//img 
import logo from '../../../assets/images/logo.png';

import { Row, Col } from 'react-grid-system';

import { Link } from 'react-router-dom';
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

import {
  MainNavItemLink,
  MenuNavToogle,
  SideMenu,
  SideMenuItem,
  SideMenuInner,
} from '../../../css/styles';

import { CSSTransition, Transition } from 'react-transition-group';
import { MdMenu, MdClose, MdDone } from 'react-icons/md';
function SectionNavbar() {
  const [isSideMenuOpened, setisSideMenuOpened] = useState(false);
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const showSideMenu = () => {
    setisSideMenuOpened(!isSideMenuOpened);
  };
  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            href="/"
            // target="_blank"
            title="Ongold Tech"
          >
            <Logo src={logo} />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
                <NavLink
                  data-placement="bottom"
                  // title="Follow us on Twitter"
                  style={{ fontFamily: 'Helvetica', fontWeight: 'normal', textTransform: 'none', fontSize: '1rem' }}
                  href="/"
                >
                  Início
                </NavLink>
              </NavItem>
              <NavItem className='oculto1'>
              <NavLink
                data-placement="bottom"
                // title="Follow us on Twitter"
                style={{ fontFamily: 'Helvetica', fontWeight: 'normal', textTransform: 'none', fontSize: '1rem' }}
                href="/#sobreOngold"
              >
                Sobre a Ongold
              </NavLink>
            </NavItem>
            <NavItem className='oculto1'>
              <NavLink
                data-placement="bottom"
                // title="Follow us on Twitter"
                style={{ fontFamily: 'Helvetica', fontWeight: 'normal', textTransform: 'none', fontSize: '1rem' }}
                href="/#segmentos"
              >
                Segmentos
              </NavLink>
            </NavItem>
            <NavItem className='oculto'>
              <NavLink
                data-placement="bottom"
                // title="Like us on Facebook"
                style={{ fontFamily: 'Helvetica', fontWeight: 'normal', textTransform: 'none', fontSize: '1rem' }}
                href="/#sobreOngold"
              >
                Sobre a Ongold
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                // title="Like us on Facebook"
                style={{ fontFamily: 'Helvetica', fontWeight: 'normal', textTransform: 'none', fontSize: '1rem' }}
                href="/#faleconosco"
              >
                Fale conosco
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                data-placement="bottom"
                to="/plans"
                title="Follow us on Instagram"
                style={{ fontFamily: 'Helvetica', fontWeight: 'normal', textTransform: 'capitalize', fontSize: '1rem'}}
              >
                <MainNavItemLink to="/plans">Planos</MainNavItemLink>
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                data-placement="bottom"
                // title="Star on GitHub"
                style={{ fontFamily: 'Helvetica', fontWeight: 'normal', textTransform: 'none', fontSize: '1rem' }}
                href="/login"
              >
                Área do lojista
              </NavLink>
            </NavItem>

            <NavItem className='oculto'>
              <NavLink
                data-placement="bottom"
                // title="Star on GitHub"
                style={{ fontFamily: 'Helvetica', fontWeight: 'normal', textTransform: 'none', fontSize: '1rem' }}
                href="/parceiro"
              >
                Área do parceiro
              </NavLink>
            </NavItem>

            <NavItem className='oculto'>
              <NavLink
                data-placement="bottom"
                // title="Star on GitHub"
                style={{ fontFamily: 'Helvetica', fontWeight: 'normal', textTransform: 'none', fontSize: '1rem' }}
                href="/suporte">
                Restrito
              </NavLink>
            </NavItem>
            {/* <NavItem>
            <Button color="neutral"><i className="fa fa-heart" /></Button>
            </NavItem> */}

            {/* <NavItem>
              <UncontrolledDropdown>
                <DropdownToggle
                  aria-expanded={false}
                  aria-haspopup={true}
                  caret
                  color="secondary"
                  data-toggle="dropdown"
                  id="dropdownMenuButton"
                  type="button"
                >
                  
                </DropdownToggle>
                <DropdownMenu aria-labelledby="dropdownMenuButton">
                  <DropdownItem href="#pablo" onClick={e => e.preventDefault()}>
                    Action
                  </DropdownItem>
                  <DropdownItem href="#pablo" onClick={e => e.preventDefault()}>
                    Another action
                  </DropdownItem>
                  <DropdownItem href="#pablo" onClick={e => e.preventDefault()}>
                    Something else here
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem> */}

          </Nav>
          <Col xs={2} style={{ marginTop: '-3rem' }}>
            <MenuNavToogle>
              <MdMenu
                size="2.3em"
                color="#EDD47A"
                style={{ cursor: 'pointer' }}
                onClick={showSideMenu}
              />
              <CSSTransition
                in={isSideMenuOpened}
                timeout={300}
                classNames="fade"
                unmountOnExit
              >
                <SideMenu show={isSideMenuOpened ? 'block' : 'none'}>
                  <MdClose
                    size="2em"
                    color="#4D4D4D"
                    style={{
                      position: 'absolute',
                      top: 23,
                      right: 8,
                      cursor: 'pointer'
                    }}
                    onClick={showSideMenu}
                  />
                  <SideMenuInner>
                    {/* <Row nogutter>
                            <Col>
                              <Link style={{ textDecoration: 'none' }} to="/about">
                                <SideMenuItem style={{ textDecoration: 'none' }}>Sobre a Ongold</SideMenuItem>{' '}
                              </Link>
                            </Col>
                    </Row> */}

                    <Row nogutter>
                      <Col>
                        <Link style={{ textDecoration: 'none' }} to="/login">
                          <SideMenuItem style={{ textDecoration: 'none' }}>Área do Lojista</SideMenuItem>{' '}
                        </Link>
                      </Col>
                    </Row>
                    <Row nogutter>
                      <Col>
                        <Link style={{ textDecoration: 'none' }} to="/parceiro" target="_blank">
                          <SideMenuItem style={{ textDecoration: 'none' }}>Área do Parceiro</SideMenuItem>
                        </Link>
                      </Col>
                    </Row>
                    <Row nogutter>
                      <Col>
                        <Link style={{ textDecoration: 'none' }} to="/suporte">
                          <SideMenuItem style={{ textDecoration: 'none' }}>Restrito</SideMenuItem>{' '}
                        </Link>
                      </Col>
                    </Row>
                  </SideMenuInner>
                </SideMenu>
              </CSSTransition>
            </MenuNavToogle>
          </Col>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default SectionNavbar;
