import React from 'react';
import styled, { keyframes } from 'styled-components';

const Overlay = styled.div`
	::before {
		content: ' ';
		width: 100%;
		height: 100%;
		position: absolute;
    background-repeat: no-repeat;
		background-image: linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5));
	}
`;

const SlideBody = styled.div`
	position: absolute;
	z-index: 20;
	width: 100%;
	height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
	background-image: url(${(props) => props.backgroundImage});

	${(props) =>
		props.enableOverlay &&
		`
		&:before {
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		position: absolute;
		z-index: -1;
		background-image: linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5));
	}
	`};
`;

export default function Slide(props) {
	
	return (
		<SlideBody backgroundImage={props.backgroundImage} enableOverlay={props.enableOverlay || false}>
			{props.children}
		</SlideBody>
	);
}
