import React, { Component } from 'react';
import {withRouter, Link} from 'react-router-dom'
import { Container, Row, Col, Hidden, ScreenClassRender } from 'react-grid-system';
import {ReactComponent as LeftOrnamentGiant} from '../../../../css/svg/solution_lines_left.svg'
import {LoginWrapper, LoginForm, LoginField, OgButtonRounded} from './styles'
import SuggestionForm from '../../../SuggestionForm';


import {Flip, Fade} from 'react-reveal'


// import { Container } from './styles';

class Contact extends Component {
   
  

  render() {
    return (
        <ScreenClassRender render={screenClass => (
          <Container fluid style={{
              background: '#FFF', 
              marginTop: ['xs', 'sm'].includes(screenClass) ? '65px' : 0, 
              paddingBottom: 50,
              height: '750px',
              overflow: 'hidden', 
              
             
              }}>
           
            <Container style={{marginTop: 50}}>
               <SuggestionForm showLeftOrnament={false} Title="ENVIE UMA MENSAGEM" />
            </Container>
          

            <Hidden xs sm>
              <div style={{position: 'absolute', top: '-140px', left: '0'}}>
                  <LeftOrnamentGiant width={100}/>
              </div>
            </Hidden>
          </Container>

           
        
     )}/>
    );
  }
}


export default withRouter(Contact);