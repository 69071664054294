import styled from 'styled-components';
import {device} from '../../css/devices'

export const Container = styled.div`
  
`;

export const Logo = styled.img`
`

export const ContactUl = styled.ul`
    list-style: none;

`
export const ContactLi = styled.li`
    margin-bottom: 15px;
    font-size: 12px;
    font-weight: bold;
    color: #4D4D4D;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media ${device.xs} {
        justify-content: flex-start;
    }
`

export const SocialIcons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const BottomMenu = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

`
export const BottomMenuItem = styled.li`
    margin-bottom: 15px;
    font-size: 12px;
    font-weight: bold;
    color: #4D4D4D;
`