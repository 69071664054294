import React from "react";
// import Background from '../assets/images/dev.png';
import Background from '../../../assets/images/card-contato.png'
import { BiPhone, BiSupport } from 'react-icons/bi';
// reactstrap components
import {
  Button,
  Card,
  Container,
  CardBody,
  Row,
  Col
} from "reactstrap";
import { ProductText } from "../Pages/Products/styles";
import { MdAttachMoney } from "react-icons/md";
import { RiShoppingBag3Line } from "react-icons/ri";
import { AiOutlineWhatsApp } from "react-icons/ai";

import './styles.css';

function SectionContato() {
  return (
    <>
      <section className="section section-lg pt-0">
        <Container>
          <Row>
            <Col md="12">
              <Card className="card-profile card-plain" style={{ border: 'none' }}>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <div className="info">
                        <div className="icon icon-info">
                          <RiShoppingBag3Line size={48} style={{ color: '#EDD47A' }} />
                          {/* <i class="fa fa-cubes" style={{ color: '#EDD47A', marginLeft: '-2.5rem' }}></i> */}
                        </div>
                        <div className="description">
                          <h4 className="info-title" style={{ fontWeight: 'bold' }}>Vendas</h4>
                          <ProductText className="description" style={{ color: '#4D4D4D', fontSize: '1.3rem' }}>
                            <BiPhone size={23} style={{ color: '#4D4D4D', marginRight: '5px'  }} />
                            (81) 3628-1977
                            <br />
                            <Button
                              block
                              className="btn-white"
                              color="default"
                              href="https://wa.me/558197987157"
                              target="_blank"
                              size="lg"
                              style={{ background: '#E7CF78', borderColor: '#E7CF78', color: '#000', marginTop: '5px' }}
                            >
                              <AiOutlineWhatsApp size={19} style={{ color: '#4D4D4D', marginRight: '5px' }} />
                              (81) 9 9798-7157
                            </Button>

                          </ProductText>
                          {/* <Button className="btn-link" color="info" href="#pablo">
                                                        See more
                                                    </Button> */}
                        </div>
                      </div>
                    </Col>

                    <Col md="4">
                      <div className="info">
                        <div className="icon icon-info">
                          <BiSupport size={48} style={{ color: '#EDD47A' }} />
                          {/* <i class="fa fa-map" style={{ color: '#EDD47A', marginLeft: '-2.5rem' }}></i> */}
                        </div>
                        <div className="description">
                          <h4 className="info-title" style={{ fontWeight: 'bold' }}>Suporte</h4>
                          <ProductText className="description" style={{ color: '#4D4D4D', fontSize: '1.3rem' }}>
                            <BiPhone size={23} style={{ color: '#4D4D4D', marginRight: '5px' }} />
                            (81) 3628-6521
                            <br />
                            <Button
                              block
                              className="btn-white"
                              color="default"
                              href="https://wa.me/558199784400"
                              target="_blank"
                              size="lg"
                              style={{ background: '#E7CF78', borderColor: '#E7CF78', color: '#000', marginTop: '5px' }}
                            >
                              <AiOutlineWhatsApp size={19} style={{ color: '#4D4D4D', marginRight: '5px' }} />
                              (81) 9 9978-4400
                            </Button>
                          </ProductText>
                          {/* <Button className="btn-link" color="info" href="#pablo">
                                                        See more
                                                    </Button> */}
                        </div>
                      </div>
                    </Col>
                    {/* <hr  style= {{ height: '100px', width: '1px', background: '#4D4D4D' }}/> */}
                    <Col md="4">
                      <div className="info">
                        <div className="icon icon-info">
                          <MdAttachMoney size={48} style={{ color: '#EDD47A' }} />
                          {/* <i class="fa fa-handshake-o" style={{ color: '#EDD47A', marginLeft: '-2.5rem' }}></i> */}
                        </div>
                        <div className="description">
                          <h4 className="info-title" style={{ fontWeight: 'bold' }}>Financeiro</h4>
                          <ProductText className="description" style={{ color: '#4D4D4D', fontSize: '1.3rem' }}>
                            <BiPhone size={23} style={{ color: '#4D4D4D', marginRight: '5px' }} />
                            (81) 3628-0075
                            <br />
                            <Button
                              block
                              className="btn-white"
                              color="default"
                              href="https://wa.me/558195664008"
                              target="_blank"
                              size="lg"
                              style={{ background: '#E7CF78', borderColor: '#E7CF78', color: '#000', marginTop: '5px' }}
                            >
                              <AiOutlineWhatsApp size={19} style={{ color: '#4D4D4D', marginRight: '5px' }} />
                              (81) 9 9566-4008
                            </Button>
                          </ProductText>
                          {/* <Button className="btn-link" color="info" href="#pablo">
                                                        See more
                                                    </Button> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default SectionContato;
