import React, { Component, useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Hidden, ScreenClassRender } from 'react-grid-system';
import { CSSTransition, Transition } from 'react-transition-group';
import { useSpring, animated, config, interpolate } from 'react-spring';
// import AnimatedButton from 'react-animated-buttons';
import Slider from '../Slider';
import Slide from '../Slider/Slide';
import { FaWhatsapp } from "react-icons/fa"
//import { CarouselProvider, Slider, Slide, DotGroup, Dot } from 'pure-react-carousel';
import { ReactComponent as LeftOrnament } from '../../css/svg/left_ornament.svg';
import '../../../src/main.css'


import { withRouter, Link } from 'react-router-dom';
import { Fade } from 'react-reveal';

import {
  MainNavContainer,
  Background,
  MainNav,
  MainNavItem,
  MainNavItemLink,
  OgTitle,
  OgButtonRounded,
  Logo,
  MenuNavToogle,
  Image,
  Overlay,
  SideMenu,
  SideMenuItem,
  SideMenuInner,
  TWSMenu,
  TWSHead,
  TWSInner,
  TWSItem,
  RedirectLink
} from './styles';
import './carouselCSS.scss';
import '../../css/Transitions.css';
import '../../../src/main.css'

import SectionNavbar from "../Body/Section/SectionNavbar";

import Solutions from '../Solutions';

import { MdMenu, MdClose, MdDone } from 'react-icons/md';
import { IoMdChatboxes } from 'react-icons/io';
import background from '../../assets/images/background.png';
import background2 from '../../assets/images/background2.png';
import logo from '../../assets/images/logo.png';

function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

function Header(props) {
  const [isSideMenuOpened, setisSideMenuOpened] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [ShowTalkWithUsMenu, setShowTalkWithUsMenu] = useState(false);

  const showSideMenu = () => {
    setisSideMenuOpened(!isSideMenuOpened);
  };

  const toogleTalkWithUsMenu = () => {
    setShowTalkWithUsMenu(!ShowTalkWithUsMenu);
  };

  const { pathname } = props.location;


  const [animateMessage, setAnimateMessage] = React.useState(false);
  React.useEffect(() => {
    if (true) {
      var timeout;
      setAnimateMessage(true);
      const interval = setInterval(() => {
        setAnimateMessage(true);
        timeout = setTimeout(() => {
          setAnimateMessage(false);
        }, 2000); //5000
      }, 3000); //10000
      return () => {
        if (timeout) clearTimeout(timeout);
        clearInterval(interval);
        setAnimateMessage(false);
      };
    }
  }, []);

  const { x } = useSpring({
    from: { x: 0 },
    x: 1,
    config: { duration: 10000 }
  });

  const [animateBell, setAnimateBell] = React.useState(false);

  React.useEffect(() => {
    if (true) {
      var timeout;
      setAnimateBell(true);
      const interval = setInterval(() => {
        setAnimateBell(true);
        timeout = setTimeout(() => {
          setAnimateBell(false);
        }, 2000); //5000
      }, 3000); //10000
      return () => {
        if (timeout) clearTimeout(timeout);
        clearInterval(interval);
        setAnimateBell(false);
      };
    }
  }, []);

  const { x: y } = useSpring({
    from: { x: 0 },
    x: animateBell ? 1 : 0,
    config: { duration: 10000 }
  });


  return (
    <ScreenClassRender
      render={(screenClass) => (
        <Container
          fluid
          style={{
            padding: 0,
            height: ['xs', 'sm'].includes(screenClass) ? '450px' : '755px',
            overflowY: 'hidden'
          }}
        >
          <div
            style={{
              position: 'absolute',
              zIndex: 0,
              width: '100%',
              height: '100%',
              overflow: 'hidden',
            }}
          >
            {pathname !== '/' && (
              <Overlay>
                <div
                  style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    position: 'absolute',
                    width: '100%',
                    height: '100%'
                  }}
                />
              </Overlay>
            )}

            {pathname === '/' && (
              <Slider
                isFullScreen={true}
                duration={10000}
                items={[
                  // {
                  //   key: 1,
                  //   component: (
                  //     <Slide key={1} backgroundImage={background} enableOverlay={true}>
                  //       <Hidden sm xs>
                  //         <LeftOrnament style={{ position: 'absolute', top: '100px' }} />
                  //       </Hidden>
                  //       <div
                  //         style={{
                  //           display: 'flex',
                  //           flexDirection: 'column',
                  //           justifyContent: 'flex-start',
                  //           marginTop: ['xs', 'sm'].includes(screenClass) ? '25%' : '10%',
                  //           marginLeft: ['xs', 'sm'].includes(screenClass)
                  //             ? '15px'
                  //             : '15%'
                  //         }}
                  //       >
                  //         <Fade duration={400} left cascade>
                  //           <OgTitle
                  //             style={{
                  //               width: ['xs', 'sm'].includes(screenClass)
                  //                 ? '90%'
                  //                 : '500px'
                  //             }}
                  //           >
                  //             TECNOLOGIA DIGITAL APLICADA À GESTÃO DE SUA EMPRESA
                  // 					</OgTitle>
                  //         </Fade>
                  //         <Fade duration={1200} left cascade>
                  //           <div>
                  //             <ul
                  //               style={{
                  //                 listStyle: 'none',
                  //                 color: '#EDD47A',
                  //                 fontSize: '1rem',
                  //                 marginTop: -15,
                  //                 padding: 0
                  //               }}
                  //             >
                  //               <li>
                  //                 {' '}
                  //                 <MdDone /> Otimização e simplificação dos processos
                  // 								de venda
                  // 							</li>
                  //               <li>
                  //                 {' '}
                  //                 <MdDone /> Controle das atividades
                  // 								Administrativas/Financeiras
                  // 							</li>
                  //               <li>
                  //                 {' '}
                  //                 <MdDone /> Aumento da produtividade
                  // 							</li>
                  //               <li>
                  //                 {' '}
                  //                 <MdDone /> Redução de Custos
                  // 							</li>
                  //               <li>
                  //                 {' '}
                  //                 <MdDone /> Gestão Integrada
                  // 							</li>
                  //               <li>
                  //                 {' '}
                  //                 <MdDone /> Controle dos prazos
                  // 							</li>
                  //             </ul>
                  //           </div>
                  //         </Fade>
                  //         <div style={{ marginTop: '30px', zIndex: 10000, }}>
                  //           <Fade top delay={300}>
                  //             <OgButtonRounded to="/management">Saiba Mais</OgButtonRounded>
                  //           </Fade>
                  //         </div>
                  //       </div>
                  //     </Slide>
                  //   )
                  // },
                  // {
                  //   key: 2,
                  //   component: (
                  //     <Slide key={2} backgroundImage={background2} enableOverlay={true}>
                  //       <Hidden sm xs>
                  //         <LeftOrnament style={{ position: 'absolute', top: '100px' }} />
                  //       </Hidden>
                  //       <div
                  //         style={{
                  //           display: 'flex',
                  //           flexDirection: 'column',
                  //           justifyContent: 'flex-start',
                  //           marginTop: ['xs', 'sm'].includes(screenClass) ? '25%' : '10%',
                  //           marginLeft: ['xs', 'sm'].includes(screenClass)
                  //             ? '15px'
                  //             : '15%'
                  //         }}
                  //       >
                  //         <Fade top>
                  //           <OgTitle
                  //             style={{
                  //               width: ['xs', 'sm'].includes(screenClass)
                  //                 ? '90%'
                  //                 : '500px',
                  //               fontSize: ['xs', 'sm'].includes(screenClass)
                  //                 ? '1.5rem'
                  //                 : '2.5em'
                  //             }}
                  //           >
                  //             É ASSIM QUE A ONGOLD DESENVOLVE SOLUÇÕES COM TECNOLOGIAS
                  //             DIGITAIS.
                  // 					</OgTitle>
                  //         </Fade>
                  //         <div style={{ marginTop: 30 }}>
                  //           <OgButtonRounded to="/about">Saiba Mais</OgButtonRounded>
                  //         </div>
                  //       </div>
                  //     </Slide>
                  //   )
                  // }, 
                  {
                    key: 1,
                    component: (
                      <Slide key={1} backgroundImage={background} enableOverlay={true}>
                        <Hidden sm xs>
                          <LeftOrnament style={{ position: 'absolute', top: '100px' }} />
                        </Hidden>
                        <div
                          style={{
                            display: 'flex',
                            // background: '#EDD47A',
                            width: '70%',
                            display: 'inline-block',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            marginTop: ['xs', 'sm'].includes(screenClass) ? '15%' : '5%',
                            marginLeft: ['xs', 'sm'].includes(screenClass)
                              ? '15px'
                              : '15%'
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              // background: '#000',
                              width: '50%',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                              marginTop: ['xs', 'sm'].includes(screenClass) ? '25%' : '15%',
                              marginLeft: ['xs', 'sm'].includes(screenClass)
                                ? '15px'
                                : '0%'
                            }}
                          >
                            <Fade duration={400} left cascade>
                              <OgTitle
                                style={{
                                  width: ['xs', 'sm'].includes(screenClass)
                                    ? '90%'
                                    : '500px'
                                }}
                              >
                                Gerency ERP
                              </OgTitle>
                            </Fade>
                            <Fade duration={1200} left cascade>
                              <div>
                                <ul
                                  style={{
                                    listStyle: 'none',
                                    color: '#EDD47A',
                                    fontSize: '1.5rem',
                                    marginTop: 15,
                                    padding: 0,
                                    width: '350px'
                                  }}
                                >
                                  <li className='descMobi'>
                                    {' '}
                                    É uma suíte integrada para empresas que procuram soluções para obter alta performance, gerenciamento e qualidade em processos adminstrativos.
                                  </li>
                                </ul>
                              </div>
                            </Fade>
                            <div style={{ marginTop: '30px', zIndex: 10000, width: '15rem'}}>
                              <Fade top delay={300}>
                                <OgButtonRounded to="/products/gerencyfinanceiro">Conheça Agora</OgButtonRounded>
                                {/* <AnimatedButton to="/management" variant="outlined"  animationDuration={1} animationType="pulse"
                                style={{ width: '30px' }}>Saiba Mais</AnimatedButton> */}

                                {/* <animated.div
                                  style={{
                                    transform: x
                                      .interpolate({
                                        range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                                        output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1]
                                      })
                                      .interpolate((x) => `scale(${x})`)
                                  }}
                                >
                                  <OgButtonRounded to="/management">Saiba Mais</OgButtonRounded>
                                </animated.div> */}

                              </Fade>
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              // background: '#ea4335',
                              width: '50%',
                              textAlign: 'center',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                              marginTop: ['xs', 'sm'].includes(screenClass) ? '25%' : '-31%',
                              marginLeft: ['xs', 'sm'].includes(screenClass)
                                ? '15px'
                                : '50%'
                            }}
                          >
                            <Fade top delay={300}>
                              <Hidden sm xs >
                                <img 
                                  style={{ marginTop: '-3rem', width: '130%' }}
                                  src="./assets/images/caixa-gerency.png"
                                />
                              </Hidden>
                            </Fade>
                          </div>
                        </div>
                      </Slide>
                    ) 
                  },
                  {
                    key: 2,
                    component: (
                      <Slide key={2} backgroundImage={background2} enableOverlay={true}>
                        <Hidden sm xs>
                          <LeftOrnament style={{ position: 'absolute', top: '100px' }} />
                        </Hidden>
                        <div
                          style={{
                            display: 'flex',
                            // background: '#EDD47A',
                            width: '70%',
                            display: 'inline-block',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            marginTop: ['xs', 'sm'].includes(screenClass) ? '15%' : '5%',
                            marginLeft: ['xs', 'sm'].includes(screenClass)
                              ? '15px'
                              : '15%'
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              // background: '#000',
                              width: '50%',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                              marginTop: ['xs', 'sm'].includes(screenClass) ? '25%' : '15%',
                              marginLeft: ['xs', 'sm'].includes(screenClass)
                                ? '15px'
                                : '0%'
                            }}
                          >
                            <Fade duration={400} left cascade>
                              <OgTitle
                                style={{
                                  width: ['xs', 'sm'].includes(screenClass)
                                    ? '90%'
                                    : '500px'
                                }}
                              >
                                Gmex
                              </OgTitle>
                            </Fade>
                            <Fade duration={1200} left cascade>
                              <div>
                                <ul
                                  style={{
                                    listStyle: 'none',
                                    color: '#EDD47A',
                                    fontSize: '1.5rem',
                                    marginTop: 15,
                                    padding: 0,
                                    width: '350px'
                                  }}
                                >
                                  <li className='descMobi'>
                                    {' '}
                                    Mobilidade e facilidade para você que precisa emitir pedidos e NFCe. Emita notas gratuitas.
                                  </li>
                                </ul>
                              </div>
                            </Fade>
                            <div style={{ marginTop: '30px', zIndex: 10000, width: '15rem'}}>
                              <Fade top delay={300}>
                                <OgButtonRounded to="/products/gmex">Baixe Agora</OgButtonRounded>
                              </Fade>
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              // background: '#ea4335',
                              width: '50%',
                              textAlign: 'center',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                              marginTop: ['xs', 'sm'].includes(screenClass) ? '25%' : '-31%',
                              marginLeft: ['xs', 'sm'].includes(screenClass)
                                ? '15px'
                                : '50%'
                            }}
                          >
                            <Fade top delay={300}>
                              <Hidden sm xs>
                                <img style={{ marginTop: '2rem', width: '120%' }}
                                  src="./assets/images/products/products_baselaptop/gmex.png"
                                />
                              </Hidden>
                            </Fade>
                          </div>
                        </div>
                      </Slide>
                    )
                  },
                  {
                    key: 3,
                    component: (
                      <Slide key={3} backgroundImage={background2} enableOverlay={true}>
                        <Hidden sm xs>
                          <LeftOrnament style={{ position: 'absolute', top: '100px' }} />
                        </Hidden>
                        <div
                          style={{
                            display: 'flex',
                            // background: '#EDD47A',
                            width: '70%',
                            display: 'inline-block',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            marginTop: ['xs', 'sm'].includes(screenClass) ? '15%' : '5%',
                            marginLeft: ['xs', 'sm'].includes(screenClass)
                              ? '15px'
                              : '15%'
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              // background: '#000',
                              width: '50%',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                              marginTop: ['xs', 'sm'].includes(screenClass) ? '25%' : '15%',
                              marginLeft: ['xs', 'sm'].includes(screenClass)
                                ? '15px'
                                : '0%'
                            }}
                          >
                            <Fade duration={400} left cascade>
                              <OgTitle
                                style={{
                                  width: ['xs', 'sm'].includes(screenClass)
                                    ? '90%'
                                    : '500px'
                                }}
                              >
                                CompraDaqui
                              </OgTitle>
                            </Fade>
                            <Fade duration={1200} left cascade>
                              <div>
                                <ul
                                  style={{
                                    listStyle: 'none',
                                    color: '#EDD47A',
                                    fontSize: '1.5rem',
                                    marginTop: 15,
                                    padding: 0,
                                    width: '350px'
                                  }}
                                >
                                  <li className='descMobi'>
                                    {' '}
                                    Alcance mais clientes, aumente a lucratividade, proporcione ao seu
                                    cliente uma experiência de compra na palma mão e ainda participe do
                                    clube de descontos em todas as lojas dentro do APP.
                                  </li>
                                </ul>
                              </div>
                            </Fade>
                            <div style={{ marginTop: '30px', zIndex: 10000, width: '15rem'}}>
                              <Fade top delay={300}>
                                  {/* <OgButtonRounded> */} <RedirectLink href='https://compradaqui.com.br/' target='_blank'>Saiba Mais</RedirectLink>{/* </OgButtonRounded> */}
                              </Fade>
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              // background: '#ea4335',
                              width: '50%',
                              textAlign: 'center',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                              marginTop: ['xs', 'sm'].includes(screenClass) ? '25%' : '-31%',
                              marginLeft: ['xs', 'sm'].includes(screenClass)
                                ? '15px'
                                : '50%'
                            }}
                          >
                            <Fade top delay={300}>
                              <Hidden sm xs>
                                <img style={{ marginTop: '-3rem', width: '60%' }}
                                  src="./assets/images/products/banner-app.png"
                                />
                              </Hidden>
                            </Fade>
                          </div>
                        </div>
                      </Slide>
                    )
                  },
                  // {
                  //   key: 3,
                  //   component: (
                  //     <Slide key={1} backgroundImage={background2} enableOverlay={true}>
                  //       <Hidden sm xs>
                  //         <LeftOrnament style={{ position: 'absolute', top: '100px' }} />
                  //       </Hidden>
                  //       <div
                  //         style={{
                  //           display: 'flex',
                  //           // background: '#EDD47A',
                  //           width: '70%',
                  //           display: 'inline-block',
                  //           flexDirection: 'column',
                  //           justifyContent: 'flex-start',
                  //           marginTop: ['xs', 'sm'].includes(screenClass) ? '15%' : '5%',
                  //           marginLeft: ['xs', 'sm'].includes(screenClass)
                  //             ? '15px'
                  //             : '15%'
                  //         }}
                  //       >
                  //         <div
                  //           style={{
                  //             display: 'flex',
                  //             // background: '#000',
                  //             width: '50%',
                  //             flexDirection: 'column',
                  //             justifyContent: 'flex-start',
                  //             marginTop: ['xs', 'sm'].includes(screenClass) ? '25%' : '15%',
                  //             marginLeft: ['xs', 'sm'].includes(screenClass)
                  //               ? '15px'
                  //               : '0%'
                  //           }}
                  //         >
                  //           <Fade duration={400} left cascade>
                  //             <OgTitle
                  //               style={{
                  //                 width: ['xs', 'sm'].includes(screenClass)
                  //                   ? '90%'
                  //                   : '500px'
                  //               }}
                  //             >
                  //               Gerency Pro
                  //             </OgTitle>
                  //           </Fade>
                  //           <Fade duration={1200} left cascade>
                  //             <div>
                  //               <ul
                  //                 style={{
                  //                   listStyle: 'none',
                  //                   color: '#EDD47A',
                  //                   fontSize: '1rem',
                  //                   marginTop: 15,
                  //                   padding: 0,
                  //                   width: '350px'
                  //                 }}
                  //               >
                  //                 <li>
                  //                   {' '}
                  //                   Software Emissor Simplificado de NFe e NFCe.
                  //                   Com o Gerency PRO você emite Notas fiscais eletrônicas no seu micro computador de maneira Rápida e Simples.
                  //                   Ideal para microempresas e microempreendedor Individual.
                  //                 </li>
                  //               </ul>
                  //             </div>
                  //           </Fade>
                  //           <div style={{ marginTop: '30px', zIndex: 10000, }}>
                  //             <Fade top delay={300}>
                  //               <OgButtonRounded to="/management">Saiba Mais</OgButtonRounded>
                  //             </Fade>
                  //           </div>
                  //         </div>
                  //         <div
                  //           style={{
                  //             display: 'flex',
                  //             // background: '#ea4335',
                  //             width: '50%',
                  //             textAlign: 'center',
                  //             flexDirection: 'column',
                  //             justifyContent: 'flex-start',
                  //             marginTop: ['xs', 'sm'].includes(screenClass) ? '25%' : '-31%',
                  //             marginLeft: ['xs', 'sm'].includes(screenClass)
                  //               ? '15px'
                  //               : '50%'
                  //           }}
                  //         >
                  //           <Fade top delay={300}>
                  //             <Hidden sm xs>
                  //               <img style={{ marginTop: '1rem' }}
                  //                 src="./assets/images/products/nfce_cover.png"
                  //               />
                  //             </Hidden>
                  //           </Fade>
                  //         </div>
                  //       </div>
                  //     </Slide>
                  //   )
                  // },
                ]}
              />
            )}
          </div>

          <SectionNavbar />
        </Container>
      )}
    />
  );
}

export default withRouter(Header);
