import React, { Component } from 'react';
import { Container, Row, Col, Hidden, ScreenClassRender } from 'react-grid-system';
import { Fade } from 'react-reveal'
import { ReactComponent as LeftOrnamentGiant } from '../../../../css/svg/left-ornament-giant.svg';
import {
  OgTitle,
  OgButtonRounded,
  Subtitle,
  ImageWrapper,
  MsvUl,
  Msvli,
  PlrsUl,
  MsvTitle,
  MsvParagraph,
  Background,
  Image
} from './styles';
import SuggestionForm from '../../../SuggestionForm';
import '../../../../../src/main.css';

// import { Container } from './styles';

export default class WhoWeAre extends Component {
  render() {
    return (
      <ScreenClassRender
        render={(screenClass) => (
          <Container fluid>
            <Row /* style={{ height: ['xs', 'sm'].includes(screenClass) ? 'auto' : '671px' }} */>
              <Hidden xs sm>
                <Col md={1} style={{ marginRight: '45px', paddingLeft: 0 }}>
                  <LeftOrnamentGiant
                    style={{
                      position: 'absolute',
                      marginTop: '15px'
                    }}
                  />
                </Col>
              </Hidden>
              <Col>
                <Row>
                  <Col>
                    <Fade top>
                      <OgTitle style={{
                        marginTop: ['xs', 'sm'].includes(screenClass) ? "100px" : "60px"
                      }}>
                        QUEM<br />SOMOS
										</OgTitle>
                    </Fade>
                    <Fade top delay={300}>
                      <Subtitle>
                        É assim que a ONGOLD TECH <br />desenvolve soluções com <br />tecnologias
											digitais.
										</Subtitle>
                    </Fade>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row
              style={{
                marginTop: ['xs', 'sm'].includes(screenClass) ? '60px' : '350px',
                padding: '10px 0px'
              }}
            >
              <Container>
                <Row>
                  <Col
                    md={6}
                    xl={6}
                    xs={12}
                    style={{
                      paddingLeft: ['xs', 'sm'].includes(screenClass) ? '15px' : '90px',
                      paddingTop: ['xs', 'sm'].includes(screenClass) ? '0' : '10px'
                    }}
                  >
                    <h1
                      style={{
                        fontFamily: 'Helvetica',
                        color: '#4D4D4D'
                      }}
                    >
                      História
										</h1>
                    <p
                      style={{
                        fontSize: '17px',
                        fontFamily: 'Helvetica',
                        textAlign: ['xs', 'sm'].includes(screenClass) ? 'justify' : 'left',
                        color: '#4D4D4D'
                      }}
                    >
                      {' '}
											Com mais de 20 anos de experiência na criação e desenvolvimento de software,
											a ONGOLD TECH tem seu foco direcionado para a inovação. Pesquisamos,
											projetamos e desenvolvemos ferramentas sólidas para construir uma estrutura
											confiável onde a qualidade e a satisfação dos nossos clientes são
											primordiais.{' '}
                    </p>

                    <div
                      style={{
                        position: ['xs', 'sm'].includes(screenClass)
                          ? 'relative'
                          : 'absolute',
                        width: ['xs', 'sm'].includes(screenClass) ? '90%' : '100%',
                        padding: ['xs', 'sm'].includes(screenClass) ? '15px' : '30px',
                        zIndex: 1000,
                        background: '#4D4D4D'
                      }}
                    >
                      <p
                        style={{
                          color: 'white',
                          fontFamily: 'Helvetica'
                        }}
                      >
                        A ONGOLD TECH está preparada para suprir as necessidades de micro,
                        pequenas, médias empresas desenvolvendo soluções tecnológicas que trazem
                        retornos significativos nos processos operacionais, de produção e de
                        gestão da organização proporcionando uma experiência memorável. Esse é
                        nosso objetivo.
											</p>
                    </div>
                  </Col>
                  <Hidden xs sm>
                    <Col md={6} xl={6} xs={12} style={{ padding: 0, height: '100%' }}>
                      <ImageWrapper>
                        <img
                          src="./assets/images/business2.jpg"
                          alt=""
                          style={{
                            width: '100%',
                            height: '93%'
                          }}
                        />
                      </ImageWrapper>
                    </Col>
                  </Hidden>
                </Row>
              </Container>
            </Row>
            <Row
              style={{
                background: '#F2F2F2',
                display: 'flex',
                flexDirection: 'row',
                marginTop: '20px',
                padding: '40px 0px'
              }}
            >
              <Container>
                <Row>
                  <Col md={6} xl={6}>
                    <div style={{ padding: 30, background: '#EDD47A' }} className='missaodiv'>
                      <MsvTitle>MISSÃO</MsvTitle>
                      <MsvParagraph>
                        Criar e desenvolver soluções inovadoras com excelência utilizando
                        Tecnologia Digital para promover a transformação, o crescimento e ganho
                        aos nossos clientes, proporcionando uma experiência memorável com
                        qualidade, respeito, transparência e ética e que nossos colaboradores
                        compartilhem do nosso sucesso.
											</MsvParagraph>
                    </div>
                  </Col>
                  <Col md={6} xl={6}>
                    <div
                      style={{
                        height: 200,
                        padding: 30,
                        textAlign: 'justify',
                        background: '#EDD47A'
                      }} 
                      className='visaodiv'
                    >
                      <MsvTitle>VISÃO</MsvTitle>
                      <MsvParagraph>
                        Ser uma empresa modelo com soluções inovadoras em Tecnologias Digitais
                        com alta qualidade e excelência do atendimento ao cliente.
											</MsvParagraph>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Row>
            <Row style={{ marginTop: '5px', margin: '40px 0px' }}>
              <Container>
                <Row>
                  <Col>
                    <h2 style={{ color: '#4D4D4D', fontSize: '2.1em' }}>VALORES</h2>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4} xl={4}>
                    <div style={{ padding: '15px 0' }}>
                      <div style={{ height: 30, display: 'flex', alignItems: 'center' }}>
                        <h3>Excelência e Qualidade</h3>
                      </div>
                      <p style={{ marginTop: '6px' }}>
                        Buscar a melhoria contínua na gestão da organização através da
                        implantação de processos em todos no níveis organizacionais, obtendo
                        ótimos resultados por meios de requisitos e avaliações para alcançar com
                        sucesso nosso objetivo que é atender as necessidades e expectativas de
                        nossos clientes e colaboradores.
											</p>
                    </div>
                  </Col>
                  <Col xs={12} md={4} xl={4}>
                    <div style={{ padding: '15px 0' }}>
                      <div style={{ height: 30, display: 'flex', alignItems: 'center' }}>
                        <h3>Foco no Cliente</h3>
                      </div>
                      <p style={{ marginTop: '6px' }}>
                        Manter o cliente como o centro das atenções e ponto decisivo nas tomadas
                        de decisões identificando seus desejos e necessidades.
											</p>
                    </div>
                  </Col>
                  <Col xs={12} md={4} xl={4}>
                    <div style={{ padding: '15px 0' }}>
                      <div style={{ height: 30, display: 'flex', alignItems: 'center' }}>
                        <h3>Inovação</h3>
                      </div>
                      <p style={{ marginTop: '6px' }}>
                        Explorar novas ideias buscando fazer com mais eficácia, nossas soluções.
											</p>
                    </div>
                  </Col>
                  <Col xs={12} md={4} xl={4}>
                    <div style={{ padding: '15px 0' }}>
                      <div style={{ height: 30, display: 'flex', alignItems: 'center' }}>
                        <h3>Credibilidade e Confiança</h3>
                      </div>
                      <p style={{ marginTop: '6px' }}>
                        Buscar ser sempre verdadeiro em nossos relacionamentos e atitudes para
                        conquistar a confiança de nossos clientes, colaboradores e parceiros.
											</p>
                    </div>
                  </Col>
                  <Col xs={12} md={4} xl={4}>
                    <div style={{ padding: '15px 0' }}>
                      <div style={{ height: 30, display: 'flex', alignItems: 'center' }}>
                        <h3>Ética e Transparência</h3>
                      </div>
                      <p style={{ marginTop: '6px' }}>
                        {' '}
												Seguir condutas e práticas que expressem comprometimento e
												responsabilidade com todos de forma transparente e com credibilidade.
											</p>
                    </div>
                  </Col>
                  <Col xs={12} md={4} xl={4}>
                    <div style={{ padding: '15px 0' }}>
                      <div style={{ height: 30, display: 'flex', alignItems: 'center' }}>
                        <h3>Respeito ao Ser Humano</h3>
                      </div>
                      <p style={{ marginTop: '6px' }}>
                        {' '}
												Ter reverência, consideração e entender com paciência as pessoas
												buscando cuidar uma da outra de forma autêntica e assertiva.
											</p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Row>

            <Row style={{ display: 'flex', justifyContent: 'center' }}>
              {/* <Background>
								<Image
									src=""
									style={{ width: '100%', height: '100%' }}
								/>
							</Background> */}

              <Container
                style={{
                  width: "100%",
                  backgroundImage: "url(./assets/images/ongold_business2.png)",
                  backgroundSize: 'cover',
                  color: 'white',
                  fontFamily: 'Helvetica',
                  padding: '20px 5px 20px 5px'
                }}
              >
                <Row>
                  <Col>
                    <h1 style={{ textAlign: 'center' }}>PILARES</h1>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'stretch',
                        flexFlow: 'wrap'
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          paddingRight: '10px',
                          width: '250px'
                        }}
                      >
                        <div
                          style={{
                            border: '2px solid white',
                            color: 'white',
                            padding: '15px',
                            marginBottom: '10px'
                          }}
                        >
                          <h4 style={{ margin: 0 }}>Causa:</h4>
                          <span>Transformação digital</span>
                        </div>
                        <div
                          style={{
                            border: '2px solid white',
                            color: 'white',
                            padding: '15px',
                            marginBottom: '10px'
                          }}
                        >
                          <h4 style={{ margin: 0 }}>Cultura:</h4>
                          <span>
                            Inovação com Tecnologias Digitais e atendimento com excelência
                            ao cliente.
													</span>
                        </div>
                        <div
                          style={{
                            border: '2px solid white',
                            color: 'white',
                            padding: '15px'
                          }}
                        >
                          <h4 style={{ margin: 0 }}>Propósito:</h4>
                          <span>Satisfação do Cliente</span>
                        </div>
                      </div>

                      <div
                        style={{
                          border: '2px solid white',
                          color: 'white',
                          padding: '5px 15px',
                          marginTop: ['xs', 'sm'].includes(screenClass) ? '10px' : 0
                        }}
                      >
                        <h4 style={{ marginLeft: '20px' }}>Princípios:</h4>
                        <PlrsUl>
                          <li>Ouvir e servir aos clientes.</li>
                          <li>Gestão ética, inovadora e participativa</li>
                          <li>A inovação orienta nossas ações</li>
                          <li>Melhorar continuamente os processos e serviços</li>
                          <li>Comprometimento socioambiental.</li>
                        </PlrsUl>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Row>
          </Container>
        )}
      />
    );
  }
}
