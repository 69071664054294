import styled from 'styled-components';
import {Link} from 'react-router-dom'
import {Container as ContainerGridSystem} from 'react-grid-system'
import {device} from '../../../../css/devices'


export const Container = styled(ContainerGridSystem)`
    display: flex; 
    flex-direction: row; 
    flex-flow: 'wrap';

    @media ${device.xs} {
        flex-flow: column;

    }

`

export const LoginWrapper = styled.div`
    width: 380px;
    
    padding: 20px;
    background: #EDD47A;
    display:flex;
    flex-direction: column;

    h1{
        font-size: 6vh;
        text-align: center;
        color: #4D4D4D;       
    }

    @media ${device.xs} {
        width: 100%;
    }
`
export const LoginForm = styled.div`
   
   
`
export const LoginField = styled.div`
   display:flex;
   flex-direction: column;
   margin-bottom: 20px; 

   span {
       margin-bottom: 5px;
   }
   
   input {
       height: 35px;       
       font-size: 20px;
       -webkit-appearance: none;
       background: white;
       border: none;
       outline: none;
   }
`
export const Background = styled.div`
    position: absolute;
    top:0;
    width: 100%;
    height: 200px;
    z-index: -10000;
    background: #e0e0e0;
`

export const Image = styled.img`
    width: 80%; 
    height: 80%; 
    object-fit: contain; 

    @media ${device.xs} {
        width: 100%; 
        height: 100%; 
        object-fit: contain; 
    }
`;


export const OgButtonRounded = styled.button`
    padding: 8px 40px;
    background: transparent;
    border: 3px solid #4D4D4D;
    border-radius: 60px;
    color: black;
    font-family: 'Helvetica';
    font-weight: 600;
    font-size: 1.1em;
    text-decoration: none;
    transition: 0.2s ease;
    cursor: pointer;

    :hover{
        background: #4D4D4D;
        color: white;
    }
`;

