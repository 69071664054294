import React, { useEffect, useState } from 'react';
import Lottie from "react-lottie";
import { Fade } from 'react-reveal';
import * as emailAnimation from '../../assets/lottie_files/send-email.json'
import * as errorEmailAnimation from '../../assets/lottie_files/error-animation.json'
import { Container, Row, Col, Hidden, ScreenClassRender } from 'react-grid-system';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers'
import * as yup from "yup"
import InputMask from "react-input-mask"
import { Input, Span, Textarea, OgButtonRounded, OrnamentWrapper, SingleOrnament } from './styles';
import Axios from 'axios';
import {
  Button
} from "reactstrap";

import './styles.css';


const schema = yup.object().shape({
  nome: yup.string().required("Por favor, preencha o seu nome").min(5, "Por favor, digite ao menos 5 caracteres."),
  email: yup.string().required("Por favor, preencha o seu email.").email("Por favor, informe um email válido"),
  telefone: yup.number().min(9, "Informe um celular válido"),
});

export default function DemoForm(props) {
  const { register, handleSubmit, errors } = useForm(
    { resolver: yupResolver(schema) }
  );
  const [isSendingEmail, setSendingEmail] = useState(false);
  const [successEmailSend, setSuccessEmailSend] = useState(false);
  const [failureEmailSend, setFailureEmailSend] = useState(false);

  const [isStopped, setStopped] = useState(true);
  const [isPaused, setPaused] = useState(true);

  const [valueNome, setNome] = useState("")
  const [valueEmail, setEmail] = useState("")
  const [valueTelefone, setTelefone] = useState("")

  const defaultLottieOptions = {
    loop: false,

    autoplay: true,
    animationData: emailAnimation.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const errorLottieOption = {
    loop: false,
    autoplay: true,
    animationData: errorEmailAnimation.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const sendEmail = async (form_data) => {

    const data = {
      nome: valueNome,
      email: valueEmail,
      telefone: valueTelefone,
      assunto: `Solicitação de Demonstração do ${props.product}`,
      mensagem: `${valueNome} está interessado em testar o produto ${props.product || ''}`
    }

    console.log(data)
    /*  console.log(data)
     setSuccessEmailSend(true);
     setStopped(false) */

    Axios.post('http://ongoldtech.com/application/public/mail', data, {
      headers: {

      }
    })
      .then(({ status, data }) => {
        console.log(data)
        if (!data.success) {
          setSuccessEmailSend(false);
          setFailureEmailSend(true);
          return false;
        }

        setSendingEmail(false);
        setSuccessEmailSend(true);
        setNome("")
        setEmail("")
        setTelefone("")

      }).catch(() => {
        setSuccessEmailSend(false);
        setFailureEmailSend(true);
        return false;
      })
  }

  const retrySend = () => {
    setFailureEmailSend(false);
    setSuccessEmailSend(false);
    setSendingEmail(false);
  }

  return (
    <div>
      {failureEmailSend && <Row>
        <Col>
          <Fade>
            <Lottie
              options={errorLottieOption}
              height={200}
              width={200}
              isStopped={false}
            />
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <div><p>Ops! Não foi possível enviar sua mensagem. Lamentamos o incômodo.</p></div>
              <div><OgButtonRounded onClick={retrySend}>Tentar Novamente</OgButtonRounded></div>
            </div>
          </Fade>
        </Col>
      </Row>}
      {successEmailSend && <Row>
        <Col>
          <Fade>
            <Lottie
              options={defaultLottieOptions}
              height={200}
              width={200}
              isStopped={false}
            />
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
              <h3>Mensagem enviada com sucesso!</h3>
            </div>
          </Fade>
        </Col>
      </Row>}
      {!isSendingEmail && !successEmailSend && !failureEmailSend && <Container style={{ fontFamily: 'Helvetica' }}>
        <Fade>
          <Row>
            <Col xs={12} xl={12}><h1 style={{ textAlign: 'center', color: '#4D4D4D' }}>Entre em contato agora</h1></Col>
          </Row>
          <form onSubmit={handleSubmit(sendEmail)}>
            <Row>
              <Col xl={12} md={12}>
                {/* <Row>
                  <Col xl={6} md={6} xs={12}>
                    <div>
                      <Span>* Seu nome</Span>
                      <Input ref={register} name="nome" onChange={({ target }) => setNome(target.value)} type="text" />
                      <p>{errors.nome?.message}</p>
                    </div>
                  </Col>
                  <Col xl={4} md={4} xs={12}>
                    <div>
                      <Span>* Seu email</Span>
                      <Input ref={register} name="email" type="text" onChange={({ target }) => setEmail(target.value)} />
                      <p>{errors.email?.message}</p>
                    </div>
                  </Col>
                  <Col xl={2} md={2} xs={12}>
                    <div>
                      <Span>Celular</Span>
                      <InputMask ref={register} type="text" mask="(99) 9 9999-9999" name="telefone"
                        onChange={({ target }) => setTelefone(target.value.replace(/[^0-9]+/g, ""))} value={valueTelefone}

                        style={{
                          width: '100%',
                          height: 40,
                          border: '1px solid #4D4D4D',
                          fontSize: '1.2em',
                          paddingLeft: 5,
                        }} />
                      <p>{errors.telefone?.message}</p>
                    </div>
                  </Col>
                </Row> */}
                <Row style={{ marginTop: '20px' }}>
                  <Col xl={12} md={12} xs={12}>
                    <div className='contact-us-button' style={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}>
                      {/* <OgButtonRounded href="https://wa.me/558136286521" >Enviar uma mensagem</OgButtonRounded> */}
                      <Button
                        block
                        className="btn-white"
                        color="default"
                        href="https://wa.me/558197987157"
                        target="_blank"
                        size="lg"
                        style={{background: '#E7CF78', borderColor: '#E7CF78', color: '#fff'}}
                      >
                        FALE COM NOSSA EQUIPE
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        </Fade>
      </Container>}
    </div>

  );

}
