import {createGlobalStyle } from 'styled-components';



export const GlobalStyles = createGlobalStyle`
    html, body, #root {
      background: #FFF;
    }

    @font-face {
            font-family: 'Heebo Black';
            src: url('./Heebo/Heebo-Black.ttf') format('truetype');
    }
    @font-face {
            font-family: 'Heebo Bold';
            src: url('./Heebo/Heebo-Bold.ttf') format('truetype');
    }
    @font-face {
            font-family: 'Heebo ExtraBold';
            src: url('./Heebo/Heebo-ExtraBold.ttf') format('truetype');
    }
    @font-face {
            font-family: 'Heebo Light';
            src: url('./Heebo/Heebo-Light.ttf') format('truetype');
    }
    @font-face {
            font-family: 'Heebo Medium';
            src: url('./Heebo/Heebo-Medium.ttf') format('truetype');
    }
    @font-face {
            font-family: 'Heebo Regular';
            src: url('./Heebo/Heebo-Regular.ttf') format('truetype');
    }
    @font-face {
            font-family: 'Heebo Thin';
            src: url('./Heebo/Heebo-Thin.ttf') format('truetype');
    }
   


    @font-face {
        font-family: 'Helvetica';
        src: url('./assets/fonts/Helvetica/Helvetica.ttf')  format('truetype');
    }
    @font-face {
        font-family: 'Helvetica';
        src: url('./assets/fonts/Helvetica/Helvetica-Bold.ttf') format('truetype');
        font-weight: 500;
        font-weight: bold;
    }
    @font-face {
        font-family: 'Helvetica';
        src: url('./assets/fonts/Helvetica/Helvetica-BoldOblique.ttf') format('truetype');
        font-weight: bold;
        font-style: oblique;
    }
    @font-face {
        font-family: 'Helvetica';
        src: url('./assets/fonts/Helvetica/Helvetica-Oblique.ttf') format('truetype');
        font-style: oblique;
    }

    
`
