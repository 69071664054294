export const data = [
    {
        id: 1,
        header: {
            title: 'Supermercados e Mercadinhos',
            subtitle: `Adotar um sistema ERP garante uma comunicação 
            mais eficiente entre as áreas, já que possibilita um compartilhamento 
            linear e instantâneo de dados e informações.`
        },
        body: {
            solutionBenefit: `
                Um sistema ERP para supermercados é uma ferramenta tecnológica 
                que possibilita automatizar e integrar todas as atividades, 
                que vão desde as administrativas, controle de estoque, gestão fiscal, 
                atendimento PDV, CRM, entre outras  padronizando os processos da empresa, 
                reduzindo os erros e, consequentemente, aumentando a produtividade. 
            `,
            features: [
                {
                    title: `Tomada de decisão`,
                    text: `Com nosso ERP para supermercado, você consegue ter em mãos 
                    informações precisas e atualizadas sobre a situação do seu negócio. 
                    Dessa maneira, fica muito mais fácil tomar decisões importantes 
                    de acordo com fatos e dados reais.`
                },
                {
                    title: `Vendas`,
                    text: `O supermercadista visa a manter um bom número de vendas, 
                    lucrar e garantir sempre bons resultados. Nosso ERP é um sistema 
                    de gestão empresarial que engloba todas as suas necessidades. `
                },
                {
                    title: `Processos`,
                    text: `O que acha de garantir o controle do estoque do seu 
                    supermercado? Nós sabemos que essa é uma luta constante do varejista. 
                    Escassez de produtos é uma situação que acarreta em prejuízos para 
                    um negócio a curto, médio e longo prazo. Começa quando o cliente 
                    opta por buscar um item na concorrência e se encerra com a perda 
                    de sua fidelidade.`
                },
                {
                    title: `Gestão`,
                    text: `Com a nossa solução, é possível, por exemplo, comparar o 
                    histórico de vendas de um determinado período em relação a um 
                    anterior e conhecer o percentual de lucratividade e rentabilidade 
                    como também permite conhecer melhor os clientes e elaborar 
                    estratégias de vendas com foco nas necessidades de compra.`
                },
                {
                    title: `Modernidade`,
                    text: `O mercado está mudando, o público e a concorrência também. 
                    Por isso, remar contra a maré só irá afastá-lo das melhores oportunidades.`
                },
            ],

            finishing: `Nosso sistema será uma ferramenta para seu apoio em ofertas, 
                campanhas, gerenciamento do negócio, controle de estoque e muito mais. 
                Não se trata de um gasto, mas de um investimento de retorno certo.`,
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }
            ]
        }
    },
    {
        id: 2,
        header: {
            title: 'Auto Peças e Serviços',
            subtitle: 'Gerir uma loja de autopeças tem os seus desafios. '
        },
        body: {
            solutionBenefit: `O aumento da concorrência e a diferenciação 
            para atender clientes cada vez mais exigentes e antenados com 
            as novidades do mercado, têm impulsionado os gestores a buscar 
            Software para autopeças para ajudar a resolve estes desafios.`,
            features: [
                {
                    title: 'Performance e resultados',
                    text: `Controlar um grande mix de produtos, reduzir as 
                    rupturas e manter estoques compatíveis com um fluxo de 
                    caixa saudável. Ter indicadores de performance com uma 
                    gestão orientada à resultados, crescimento com 
                    sustentabilidade, incrementar as margens e agilizar 
                    e controlar as vendas.`
                }
            ],
            finishing: `Um ERP para autopeças completo para a sua empresa, 
                desde o cadastro do produto até a contabilização dos 
                resultados da sua operação você encontra no sistema 
                para centro automotivo.`,
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }
            ]
        }
    },
    {
        id: 3,
        header: {
            title: 'Material de Construção',
            subtitle: `O nosso software de gestão tem como principal objetivo 
            manter informações organizadas ao mesmo tempo em que otimiza 
            e integra processos.`
        },
        body: {
            solutionBenefit: `O Planejamento de Recursos Empresariais (ERP) 
            é uma ferramenta indispensável para empresas de todos os portes 
            que querem melhorar suas operações diárias e ganhar mais 
            competitividade. Entre as vantagens de um sistema ERP para 
            uma loja de materiais de construção estão a redução de erros 
            humanos, padronização e modernização de diversas atividades 
            do dia a dia.`,
            features: [
                {
                    title: `Gestão Organizada`,
                    text: `Administrar uma loja de materiais de construção 
                   envolve diversos processos, como o controle de estoque,
                   o fluxo de caixa (saídas e entradas), as devoluções, 
                   os registros, a emissão de notas fiscais e, é claro, 
                   os vários relatórios. E um software ERP ajuda justamente 
                   a manter tudo isso organizado. Ao centralizar todas 
                   as informações dos diferentes setores em um único lugar,
                   o sistema proporciona o acesso facilitado e ágil aos 
                   dados para garantir uma gestão eficiente do negócio.`
                },
                {
                    title: `Maior Controle do seu Negócio`,
                    text: `Com o ERP, você acompanha tudo o que está acontecendo 
                   na sua loja. A ferramenta permite, por exemplo, fazer todos 
                   os orçamentos via sistema com data de validade e atrelados ao 
                   cadastro do cliente. Assim, é possível se planejar para entrar 
                   em contato com cada um deles para fechar a venda no momento 
                   mais adequado. E ainda: todo o controle das vendas, 
                   do estoque e das entradas e saídas de dinheiro ficam 
                   registradas no software, podendo ser acessadas de
                   modo fácil e rápido.`
                },
                {
                    title: `Otimização da Gestão Financeira`,
                    text: `Adotar um sistema ERP também ajuda a manter as finanças 
                   da sua loja de materiais de construção sempre organizadas. 
                   Com o apoio da ferramenta, é possível  ter acesso em tempo real 
                   aos dados financeiros da empresa, sabendo, por exemplo, como 
                   está o fluxo de caixa, a situação das contas a pagar e o 
                   preço dos produtos.`
                },
                {
                    title: `Melhor Gestão de Estoque`,
                    text: `Implementar um ERP na loja também é uma forma de otimizar 
                   a gestão de estoque e resolver problemas comuns no segmento de 
                   materiais de construção, como errar na quantidade ideal de 
                   produtos a serem pedidos para os fornecedores.`
                },
            ],
            finishing: `Um software ERP para lojas de materiais de construção é 
            essencial para fazê-las se desenvolverem de maneira sustentável e 
            facilitando as tomadas de decisões de forma mais precisa.`,
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }
            ]
        }
    },
    {
        id: 4,
        header: {
            title: 'Padarias e Confeitarias',
            subtitle: `O futuro dos negócios no setor de panificação e confeitaria 
            dependerá basicamente da capacidade de administrar as informações nas 
            empresas. E para que isso aconteça será necessário um investimento em um ERP`
        },
        body: {
            solutionBenefit: `Utilizar um banco de dados pode gerar 
            indicadores importantes para criar ou mudar a estratégia de uma 
            empresa, com informações bem monitoradas, podem levar a decisões 
            mais corretas, rápidas e precisas dentro do ambiente da padaria 
            e confeitaria.`,
            features: [
                {
                    title: `Vender mais`,
                    text: `A receita certa para alavancar as vendas, com sistemas simples 
                    e intuitivos  que permitem a otimização de todos os processos, desde a 
                    cozinha até o delivery. Com uma gestão dinâmica e inteligente, 
                    automatizando todas as rotinas do seu negócio a soluções para atrair 
                    e fidelizar mais clientes. Tenha tempo necessário para focar naquilo 
                    que realmente importa, o crescimento do seu negócio.`
                },
                {
                    title: `Compras`,
                    text: `Saiba o que, quando e quanto comprar.`
                },
                {
                    title: `Precificação`,
                    text: `Tenha um controle sobre o custo, preço e lucratividade de seus produtos.`
                },
                {
                    title: `Cartão de Credito`,
                    text: `Não perca dinheiro com as vendas de Cartão de Crédito.`
                },
                {
                    title: `Estoque`,
                    text: `Faça a gerencia de seu mix de produtos de forma simples e eficiente.`
                },
                {
                    title: `Financeiro`,
                    text: `Controle seus recebíveis e pagamentos de forma segura.`
                },
            ],
            finishing: `Não faltam razões para apontar as vantagens que existem ao aproveitar o 
            que a tecnologia tem de melhor e instalar um ERP. Não fique atrás da concorrência 
            e nos procure para ter o melhor sistema também para a sua loja!`,
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }
            ]
        }
    },
    {
        id: 5,
        header: {
            title: 'Sapatarias e Bolsas',
            subtitle: `Você precisa de uma ferramenta flexível e completa para 
            lidar com o estoque, vendas, finanças, e a adequação fiscal?`
        },
        body: {
            features: [
                {
                    title: `Vendas`,
                    text: `A receita certa para alavancar as vendas, com sistemas 
                    simples e intuitivos  que permitem a otimização de todos os processos. 
                    Com uma gestão dinâmica e inteligente, automatizando todas as rotinas 
                    da sua empresa. Tenha tempo necessário para focar naquilo que realmente 
                    importa, o crescimento do seu negócio.`
                },
                {
                    title: `Compras`,
                    text: `Saiba o que, quando e quanto comprar`
                },
                {
                    title: `Precificação`,
                    text: `Tenha um controle sobre o custo, preço e lucratividade 
                    de seus produtos.`
                },
                {
                    title: `Cartão de Credito`,
                    text: `Não perca dinheiro com as vendas de Cartão de Crédito.`
                },
                {
                    title: `Estoque`,
                    text: `Faça a gerencia de seu mix de produtos de forma simples 
                    e eficiente`
                },
                {
                    title: `Financeiro`,
                    text: `Controle seus recebíveis e pagamentos de forma segura.`
                },

            ],
            finishing: `Com o nosso ERP você terá o controle das compras, preços 
            de venda e promoções, empréstimos e transferências de produtos entre lojas, 
            cobranças e muito mais.`,
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }
            ]
        }
    },
    {
        id: 6,
        header: {
            title: 'Livrarias e Papelarias',
            subtitle: `Você precisa de uma ferramenta flexível e completa para 
            lidar com o estoque, vendas, finanças, e a adequação fiscal?`
        },
        body: {
            features: [
                {
                    title: `Vendas`,
                    text: `A receita certa para alavancar as vendas, com sistemas 
                    simples e intuitivos  que permitem a otimização de todos os processos. 
                    Com uma gestão dinâmica e inteligente, automatizando todas as rotinas 
                    da sua empresa. Tenha tempo necessário para focar naquilo que realmente 
                    importa, o crescimento do seu negócio.`
                },
                {
                    title: `Compras`,
                    text: `Saiba o que, quando e quanto comprar`
                },
                {
                    title: `Precificação`,
                    text: `Tenha um controle sobre o custo, preço e lucratividade 
                    de seus produtos.`
                },
                {
                    title: `Cartão de Credito`,
                    text: `Não perca dinheiro com as vendas de Cartão de Crédito.`
                },
                {
                    title: `Estoque`,
                    text: `Faça a gerencia de seu mix de produtos de forma simples 
                    e eficiente`
                },
                {
                    title: `Financeiro`,
                    text: `Controle seus recebíveis e pagamentos de forma segura.`
                },

            ],
            finishing: `Com o nosso ERP você terá o controle das compras, preços 
            de venda e promoções, empréstimos e transferências de produtos entre lojas, 
            cobranças e muito mais.`,
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }
            ]
        }
    },
    {
        id: 7,
        header: {
            title: 'Restaurantes e Churrascarias',
            subtitle: `A importância de um bom ERP está ligado principalmente aos 
            fatos de automatizar processos, registrar informações e gerar meios de 
            mensurar resultados de maneira mais fácil. Em outras palavras, eles 
            ajudam você a padronizar processos do dia a dia, tornando-os mais 
            simples com economia de tempo e recursos. `
        },
        body: {
            solutionBenefit: `Nosso sistema é ideal para 
             profissionalizar a gestão do estabelecimento. Com ele, é possível 
             organizar melhor as finanças e otimizar a parte mais burocrática do 
             trabalho, dando tempo para que os gerentes se ocupem com tarefas 
             críticas da gestão.`,
            features: [
                {
                    title: `Experiência do cliente`,
                    text: `Ofereça muito mais que uma experiência gastronômica, 
                    começando pela otimização do tempo de seus clientes. Além 
                    dos sistemas práticos para a cozinha. Conte ainda com criador 
                    de promoções personalizadas e Tudo para atender melhor e 
                    vender mais.`
                },
                {
                    title: `Pagamento e mobilidade`,
                    text: `Além da sugestão de produtos adicionais para aumentar 
                    o ticket médio, oferecemos venda na mesa, no balcão, na comanda 
                    e delivery, tudo dentro das exigências fiscais e integrado com TEF. `
                },
                {
                    title: `Vender mais`,
                    text: `A receita certa para alavancar as vendas, com sistemas simples 
                    e intuitivos  que permitem a otimização de todos os processos, desde a 
                    cozinha até o delivery. Com uma gestão dinâmica e inteligente, 
                    automatizando todas as rotinas do seu negócio a soluções para atrair 
                    e fidelizar mais clientes.`
                },
            ],
            finishing: 'Tenha tempo necessário para focar naquilo que realmente importa, o crescimento do seu negócio.',
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }
            ]
        }
    },
    {
        id: 8,
        header: {
            title: 'Móveis e Eletrodomésticos',
            subtitle: `Você precisa de uma ferramenta flexível e completa para 
            lidar com o estoque, vendas, finanças, e a adequação fiscal?`
        },
        body: {
            solutionBenefit: `O sistema ERP vai revolucionar seus processos 
            gerenciais, melhorando controles e oferecendo indicadores para 
            uma gestão mais assertiva.`,
            features: [
                {
                    title: `Vendas`,
                    text: `A receita certa para alavancar as vendas, com sistemas 
                    simples e intuitivos  que permitem a otimização de todos os processos. 
                    Com uma gestão dinâmica e inteligente, automatizando todas as rotinas 
                    da sua empresa. Tenha tempo necessário para focar naquilo que realmente 
                    importa, o crescimento do seu negócio.`
                },
                {
                    title: `Compras`,
                    text: `Saiba o que, quando e quanto comprar`
                },
                {
                    title: `Precificação`,
                    text: `Tenha um controle sobre o custo, preço e lucratividade 
                    de seus produtos.`
                },
                {
                    title: `Cartão de Credito`,
                    text: `Não perca dinheiro com as vendas de Cartão de Crédito.`
                },
                {
                    title: `Estoque`,
                    text: `Faça a gerencia de seu mix de produtos de forma simples 
                    e eficiente`
                },
                {
                    title: `Financeiro`,
                    text: `Controle seus recebíveis e pagamentos de forma segura.`
                },

            ],
            finishing: `Com o nosso ERP você terá o controle das compras, preços 
            de venda e promoções, empréstimos e transferências de produtos entre lojas, 
            cobranças e muito mais.`,
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }
            ]
        }
    },
    {
        id: 9,
        header: {
            title: 'Vestuário e Acessórios',
            subtitle: `A tecnologia digital vem sendo cada vez mais 
            usada para ajudar e facilitar a vida de empreendedores e empresários 
            que em geral têm pela frente toda a gestão de um negócio durante 
            o dia a dia.`
        },
        body: {
            solutionBenefit: `O ERP  é  ideal para lojas de roupa, acessórios e 
            calçados  pois  agilizam o atendimento e o processamento de dados 
            e consequentemente aumenta a rentabilidade e a melhora a 
            experiência de satisfação do cliente no atendimento.`,
            features: [
                {
                    list: [
                        `Controle total do seu estoque e inventário`,
                        `Programa de Descontos`,
                        `Sugestão de compra baseado no consumo`,
                        `Indicadores de performance de vendas por Loja, produto, vendedor...`,
                        `Gestão financeira completa e integração com bancos e Cartões de Crédito`,
                        `Financeiro completo com Demonstrativo do Resultado e Fluxo de Caixa`,
                    ]
                },
            ],
            finishing: `Não faltam razões para apontar as vantagens que existem ao aproveitar 
            o que a tecnologia tem de melhor e instalar um ERP. Não fique atrás da concorrência 
            e nos procure para ter o melhor sistema também para a sua loja!`,
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }
            ]
        }
    },
    {
        id: 10,
        header: {
            title: 'Conveniência e Drugstore',
            subtitle: `Você precisa de uma ferramenta flexível e completa para 
            lidar com o estoque, vendas, finanças, e a adequação fiscal?`
        },
        body: {
            features: [
                {
                    title: `Vendas`,
                    text: `A receita certa para alavancar as vendas, com sistemas 
                    simples e intuitivos  que permitem a otimização de todos os processos. 
                    Com uma gestão dinâmica e inteligente, automatizando todas as rotinas 
                    da sua empresa. Tenha tempo necessário para focar naquilo que realmente 
                    importa, o crescimento do seu negócio.`
                },
                {
                    title: `Compras`,
                    text: `Saiba o que, quando e quanto comprar`
                },
                {
                    title: `Precificação`,
                    text: `Tenha um controle sobre o custo, preço e lucratividade 
                    de seus produtos.`
                },
                {
                    title: `Cartão de Credito`,
                    text: `Não perca dinheiro com as vendas de Cartão de Crédito.`
                },
                {
                    title: `Estoque`,
                    text: `Faça a gerencia de seu mix de produtos de forma simples 
                    e eficiente`
                },
                {
                    title: `Financeiro`,
                    text: `Controle seus recebíveis e pagamentos de forma segura.`
                },

            ],
            finishing: `Com o nosso ERP você terá o controle das compras, preços 
            de venda e promoções, empréstimos e transferências de produtos entre lojas, 
            cobranças e muito mais.`,
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }
            ]
        }
    },
    {
        id: 11,
        header: {
            title: 'Cama, Mesa e Banho',
            subtitle: `Você precisa de uma ferramenta flexível e completa para 
            lidar com o estoque, vendas, finanças, e a adequação fiscal?`
        },
        body: {
            features: [
                {
                    title: `Vendas`,
                    text: `A receita certa para alavancar as vendas, com sistemas 
                    simples e intuitivos  que permitem a otimização de todos os processos. 
                    Com uma gestão dinâmica e inteligente, automatizando todas as rotinas 
                    da sua empresa. Tenha tempo necessário para focar naquilo que realmente 
                    importa, o crescimento do seu negócio.`
                },
                {
                    title: `Compras`,
                    text: `Saiba o que, quando e quanto comprar`
                },
                {
                    title: `Precificação`,
                    text: `Tenha um controle sobre o custo, preço e lucratividade 
                    de seus produtos.`
                },
                {
                    title: `Cartão de Credito`,
                    text: `Não perca dinheiro com as vendas de Cartão de Crédito.`
                },
                {
                    title: `Estoque`,
                    text: `Faça a gerencia de seu mix de produtos de forma simples 
                    e eficiente`
                },
                {
                    title: `Financeiro`,
                    text: `Controle seus recebíveis e pagamentos de forma segura.`
                },

            ],
            finishing: `Com o nosso ERP você terá o controle das compras, preços 
            de venda e promoções, empréstimos e transferências de produtos entre lojas, 
            cobranças e muito mais.`,
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }
            ]
        }
    },
    {
        id: 12,
        header: {
            title: 'Atacado',
            subtitle: `Investir em tecnologia digital é, cada vez mais, um diferencial 
            competitivo, e ter um bom sistema de gestão empresarial, ou ERP 
            (Enterprise Resource Planning), fará a sua distribuidora e atacadista 
            lucrar muito mais.`
        },
        body: {
            solutionBenefit: `
                Atendendo as necessidades inerentes ao negócio, um sistema ERP 
                leva as informações gerenciais para a palma da sua mão, 
                proporcionando mais agilidade na tomada de decisão, com informações 
                claras e precisas, aumentando a sua competitividade e identificado 
                as melhores oportunidades de mercado.                
            `,
            features: [
                {
                    title: `Gestão Organizada`,
                    text: `Administrar uma loja de materiais de construção envolve 
                    diversos processos, como o controle de estoque, o fluxo de caixa 
                    (saídas e entradas), as devoluções, os registros, a emissão de 
                    notas fiscais e, é claro, os vários relatórios. E um software ERP 
                    ajuda justamente a manter tudo isso organizado. Ao centralizar 
                    todas as informações dos diferentes setores em um único lugar, 
                    o sistema proporciona o acesso facilitado e ágil aos dados 
                    para garantir uma gestão eficiente do negócio.`
                },
                {
                    title: `Maior Controle do seu Negócio`,
                    text: `Com o ERP, você acompanha tudo o que está acontecendo na 
                    sua loja. A ferramenta permite, por exemplo, fazer todos os 
                    orçamentos via sistema com data de validade e atrelados ao 
                    cadastro do cliente. Assim, é possível se planejar para entrar 
                    em contato com cada um deles para fechar a venda no momento 
                    mais adequado. E ainda: todo o controle das vendas, do estoque 
                    e das entradas e saídas de dinheiro ficam registradas no 
                    software, podendo ser acessadas de modo fácil e rápido.`
                },
                {
                    title: `Otimização da gestão financeira`,
                    text: `Adotar um sistema ERP também ajuda a manter as finanças 
                    da sua loja de materiais de construção sempre organizadas. 
                    Com o apoio da ferramenta, é possível  ter acesso em tempo 
                    real aos dados financeiros da empresa, sabendo, por exemplo, 
                    como está o fluxo de caixa, a situação das contas a pagar e 
                    o preço dos produtos.`
                },
                {
                    title: `Melhor gestão de estoque`,
                    text: `Implementar um ERP na loja também é uma forma de otimizar 
                    a gestão de estoque e resolver problemas comuns no segmento de 
                    materiais de construção, como errar na quantidade ideal de 
                    produtos a serem pedidos para os fornecedores.`
                },
            ],
            finishing: `Um software ERP para lojas de materiais de construção é 
            essencial para fazê-las se desenvolverem de maneira sustentável e 
            facilitando as tomadas de decisões forma mais precisa.`,
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }
            ]
        }
    },
    {
        id: 13,
        header: {
            title: 'Food Service e Fast Food',
            subtitle: `A importância de um bom ERP está ligado principalmente aos 
            fatos de automatizar processos, registrar informações e gerar meios de 
            mensurar resultados de maneira mais fácil. Em outras palavras, eles 
            ajudam você a padronizar processos do dia a dia, tornando-os mais 
            simples com economia de tempo e recursos. `
        },
        body: {
            solutionBenefit: `Nosso sistema é ideal para 
             profissionalizar a gestão do estabelecimento. Com ele, é possível 
             organizar melhor as finanças e otimizar a parte mais burocrática do 
             trabalho, dando tempo para que os gerentes se ocupem com tarefas 
             críticas da gestão.`,
            features: [
                {
                    title: `Experiência do cliente`,
                    text: `Ofereça muito mais que uma experiência gastronômica, 
                    começando pela otimização do tempo de seus clientes. Além 
                    dos sistemas práticos para a cozinha. Conte ainda com criador 
                    de promoções personalizadas e Tudo para atender melhor e 
                    vender mais.`
                },
                {
                    title: `Pagamento e mobilidade`,
                    text: `Além da sugestão de produtos adicionais para aumentar 
                    o ticket médio, oferecemos venda na mesa, no balcão, na comanda 
                    e delivery, tudo dentro das exigências fiscais e integrado com TEF. `
                },
                {
                    title: `Vender mais`,
                    text: `A receita certa para alavancar as vendas, com sistemas simples 
                    e intuitivos  que permitem a otimização de todos os processos, desde a 
                    cozinha até o delivery. Com uma gestão dinâmica e inteligente, 
                    automatizando todas as rotinas do seu negócio a soluções para atrair 
                    e fidelizar mais clientes.`
                },
            ],
            finishing: 'Tenha tempo necessário para focar naquilo que realmente importa, o crescimento do seu negócio.',
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }
            ]
        }
    },
    {
        id: 14,
        header: {
            title: 'Magazines e Utilidades',
            subtitle: `Você precisa de uma ferramenta flexível e completa para 
            lidar com o estoque, vendas, finanças, e a adequação fiscal?`
        },
        body: {
            features: [
                {
                    title: `Vendas`,
                    text: `A receita certa para alavancar as vendas, com sistemas 
                    simples e intuitivos  que permitem a otimização de todos os processos. 
                    Com uma gestão dinâmica e inteligente, automatizando todas as rotinas 
                    da sua empresa. Tenha tempo necessário para focar naquilo que realmente 
                    importa, o crescimento do seu negócio.`
                },
                {
                    title: `Compras`,
                    text: `Saiba o que, quando e quanto comprar`
                },
                {
                    title: `Precificação`,
                    text: `Tenha um controle sobre o custo, preço e lucratividade 
                    de seus produtos.`
                },
                {
                    title: `Cartão de Credito`,
                    text: `Não perca dinheiro com as vendas de Cartão de Crédito.`
                },
                {
                    title: `Estoque`,
                    text: `Faça a gerencia de seu mix de produtos de forma simples 
                    e eficiente`
                },
                {
                    title: `Financeiro`,
                    text: `Controle seus recebíveis e pagamentos de forma segura.`
                },

            ],
            finishing: `Com o nosso ERP você terá o controle das compras, preços 
            de venda e promoções, empréstimos e transferências de produtos entre lojas, 
            cobranças e muito mais.`,
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }
            ]
        }
    },
    {
        id: 15,
        header: {
            title: 'Perfumaria e Cosméticos',
            subtitle: `Você precisa de uma ferramenta flexível e completa para 
            lidar com o estoque, vendas, finanças, e a adequação fiscal?`
        },
        body: {
            features: [
                {
                    title: `Vendas`,
                    text: `A receita certa para alavancar as vendas, com sistemas 
                    simples e intuitivos  que permitem a otimização de todos os processos. 
                    Com uma gestão dinâmica e inteligente, automatizando todas as rotinas 
                    da sua empresa. Tenha tempo necessário para focar naquilo que realmente 
                    importa, o crescimento do seu negócio.`
                },
                {
                    title: `Compras`,
                    text: `Saiba o que, quando e quanto comprar`
                },
                {
                    title: `Precificação`,
                    text: `Tenha um controle sobre o custo, preço e lucratividade 
                    de seus produtos.`
                },
                {
                    title: `Cartão de Credito`,
                    text: `Não perca dinheiro com as vendas de Cartão de Crédito.`
                },
                {
                    title: `Estoque`,
                    text: `Faça a gerencia de seu mix de produtos de forma simples 
                    e eficiente`
                },
                {
                    title: `Financeiro`,
                    text: `Controle seus recebíveis e pagamentos de forma segura.`
                },

            ],
            finishing: `Com o nosso ERP você terá o controle das compras, preços 
            de venda e promoções, empréstimos e transferências de produtos entre lojas, 
            cobranças e muito mais.`,
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }
            ]
        }
    },
    {
        id: 16,
        header: {
            title: 'Joalheria e Bijuteria',
            subtitle: `Você precisa de uma ferramenta flexível e completa para 
            lidar com o estoque, vendas, finanças, e a adequação fiscal?`
        },
        body: {
            features: [
                {
                    title: `Vendas`,
                    text: `A receita certa para alavancar as vendas, com sistemas 
                    simples e intuitivos  que permitem a otimização de todos os processos. 
                    Com uma gestão dinâmica e inteligente, automatizando todas as rotinas 
                    da sua empresa. Tenha tempo necessário para focar naquilo que realmente 
                    importa, o crescimento do seu negócio.`
                },
                {
                    title: `Compras`,
                    text: `Saiba o que, quando e quanto comprar`
                },
                {
                    title: `Precificação`,
                    text: `Tenha um controle sobre o custo, preço e lucratividade 
                    de seus produtos.`
                },
                {
                    title: `Cartão de Credito`,
                    text: `Não perca dinheiro com as vendas de Cartão de Crédito.`
                },
                {
                    title: `Estoque`,
                    text: `Faça a gerencia de seu mix de produtos de forma simples 
                    e eficiente`
                },
                {
                    title: `Financeiro`,
                    text: `Controle seus recebíveis e pagamentos de forma segura.`
                },
                
            ],
            finishing: `Com o nosso ERP você terá o controle das compras, preços 
            de venda e promoções, empréstimos e transferências de produtos entre lojas, 
            cobranças e muito mais.`,
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }
            ]
        }
    },
]