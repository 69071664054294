import React, { Component, useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Hidden, ScreenClassRender } from 'react-grid-system';
import { SolutionItem, SolutionContainer, SolutionWrapper } from './styles';
import {
	MdShoppingCart,
	MdDirectionsCar,
	MdFormatPaint,
	MdRestaurant,
	MdPersonalVideo,
	MdLocalOffer
} from 'react-icons/md';
/* Import Svg's */
import { ReactComponent as Bread } from '../../../css/svg/bread.svg';
import { ReactComponent as ShoeFormal } from '../../../css/svg/shoe-formal.svg';
import { ReactComponent as Book } from '../../../css/svg/book.svg';
import { ReactComponent as Convenience } from '../../../css/svg/convenience.svg';
import { ReactComponent as TowelOnHanger } from '../../../css/svg/towel-on-hanger.svg';
import { ReactComponent as SharpFastFood } from '../../../css/svg/sharp-fastfood.svg';
import { ReactComponent as Spray } from '../../../css/svg/spray.svg';
import { ReactComponent as Diamond } from '../../../css/svg/diamond.svg';

function Solutions(props) {
	return (
		<ScreenClassRender
			render={(screenClass) => (
				<SolutionWrapper show={props.show}> 
					<SolutionContainer>
						<Row nogutter>
							<Col
								style={{
									borderRight: [ 'xs', 'sm' ].includes(screenClass) ? 'none' : '1px #707070 solid'
								}}
								
							>
								<SolutionItem>
									<MdShoppingCart size="1.5em" color="#4D4D4D" />
									<br />Supermercado <br/>e Mercadinhos
								</SolutionItem>
							</Col>
							<Col
								style={{
									borderRight: [ 'xs', 'sm' ].includes(screenClass) ? 'none' : '1px #707070 solid'
								}}
								
							>
								<SolutionItem>
									<MdDirectionsCar size="1.5em" color="#4D4D4D" />
									<br />Auto Peças e <br />Serviços
								</SolutionItem>
							</Col>
							<Col
								style={{
									borderRight: [ 'xs', 'sm' ].includes(screenClass) ? 'none' : '1px #707070 solid'
								}}
								
							>
								<SolutionItem>
									<MdFormatPaint size="1.5em" color="#4D4D4D" />
									<br />Material de <br />Construção
								</SolutionItem>
							</Col>
							<Col
								style={{
									borderRight: [ 'xs', 'sm' ].includes(screenClass) ? 'none' : '1px #707070 solid'
								}}
								
							>
								<SolutionItem>
									<Bread size="1.5em" color="#4D4D4D" />
									<br />Padarias <br/>e Confeitarias
								</SolutionItem>
							</Col>
							<Col
							
								
							>
								<SolutionItem>
									<ShoeFormal size="1.5em" color="#4D4D4D" />
									<br />Sapatarias e <br /> Bolsas
								</SolutionItem>
							</Col>							
						</Row>
						<Row nogutter>
							<Col
								style={{
									borderRight: [ 'xs', 'sm' ].includes(screenClass) ? 'none' : '1px #707070 solid'
								}}
								
							>
								<SolutionItem>
									<MdRestaurant size="1.5em" color="#4D4D4D" />
									<br />Restaurantes <br/>e Churrascarias
								</SolutionItem>
							</Col>
							<Col
								style={{
									borderRight: [ 'xs', 'sm' ].includes(screenClass) ? 'none' : '1px #707070 solid'
								}}
								
							>
								<SolutionItem>
									<MdPersonalVideo size="1.5em" color="#4D4D4D" />
									<br />Móveis <br/>e Eletrodomésticos
								</SolutionItem>
							</Col>
							<Col
								style={{
									borderRight: [ 'xs', 'sm' ].includes(screenClass) ? 'none' : '1px #707070 solid'
								}}
								
							>
								<SolutionItem>
									<ShoeFormal size="1.5em" color="#4D4D4D" />
									<br />Vestuário <br/>e Acessórios
								</SolutionItem>
							</Col>
							<Col
								style={{
									borderRight: [ 'xs', 'sm' ].includes(screenClass) ? 'none' : '1px #707070 solid'
								}}
								
							>
								<SolutionItem>
									<Convenience style={{ width: '1.5em' }} />
									<br />Conveniência <br/>e Drugstore
								</SolutionItem>
							</Col>
							<Col
								style={{
								
								}}
								
							>
								<SolutionItem>
									<TowelOnHanger style={{ width: '1.5em', paddingBottom: '3px' }} />
									<br />Cama, Mesa <br />e Banho
								</SolutionItem>
							</Col>
						</Row>
						<Row nogutter>
							<Col
								style={{
									borderRight: [ 'xs', 'sm' ].includes(screenClass) ? 'none' : '1px #707070 solid'
								}}
								
							>
								<SolutionItem>
									<SharpFastFood style={{ width: '1.5em' }} />
									<br />Food Service <br /> e Fast Food
								</SolutionItem>
							</Col>
							<Col
								style={{
									borderRight: [ 'xs', 'sm' ].includes(screenClass) ? 'none' : '1px #707070 solid'
								}}
								
							>
								<SolutionItem>
									<MdLocalOffer size="1.5em" color="#4D4D4D" />
									<br />Magazines e <br />Utilidades
								</SolutionItem>
							</Col>
							<Col
								style={{
									borderRight: [ 'xs', 'sm' ].includes(screenClass) ? 'none' : '1px #707070 solid'
								}}
								
							>
								<SolutionItem>
									<Spray style={{ width: '1.1em', marginTop: '-3px' }} />
									<br />Perfumaria e<br />Cosméticos
								</SolutionItem>
							</Col>							
							<Col
								style={{
									borderRight: [ 'xs', 'sm' ].includes(screenClass) ? 'none' : '1px #707070 solid'
								}}
								
							>
								<SolutionItem>
									<Book style={{ width: '1.5em', paddingTop: '3px' }} />
									<br />Livrarias e <br/>Papelarias
								</SolutionItem>
							</Col>
              <Col
								
								
							>
								<SolutionItem>
									<MdDirectionsCar size="1.5em" color="#4D4D4D" />
									<br />Atacado
								</SolutionItem>
							</Col>
						</Row>
					</SolutionContainer>
				</SolutionWrapper>
			)}
		/>
	);
}

export default Solutions;
/* 
<SolutionItem>
                        Supermercados
                    </SolutionItem>
                    <SolutionItem>
                        Restaurantes e Churrascarias
                    </SolutionItem>
                    <SolutionItem>
                        Food Service e Fast Food
                    </SolutionItem> */
