import React, { Component } from 'react';
import {withRouter, Link} from 'react-router-dom'
import { Container, Row, Col, Hidden, ScreenClassRender } from 'react-grid-system';
import {MdKeyboardArrowLeft, MdKeyboardArrowRight, MdAdd} from 'react-icons/md'
import Truncate from 'react-truncate';
import {ReactComponent as LeftOrnamentGiant} from '../../../../css/svg/solution_lines_left.svg'
import {LoginWrapper, LoginForm, LoginField, OgButtonRounded} from './styles'
import '../../../../../src/main.css';


import {Flip, Fade} from 'react-reveal'


// import { Container } from './styles';

class ClientLogin extends Component {
   
  

  render() {
    return (
        <ScreenClassRender render={screenClass => (
          <Container fluid style={{
              background: '#FFF', 
              marginTop: '5px', 
              padding: 0,
              overflow: 'hidden', 
              height: '670px',
              display:'flex',
              flexDirection: 'column',
              justifyContent: 'center', 
              
              }}>
           
            <Container>
                <Row>
                    <LoginWrapper>
                       <h1>Área do Lojista em desenvolvimento</h1>
                       
                       {/* <LoginForm>
                          <LoginField>
                            <span>Login</span>
                            <input type="text" />
                          </LoginField>
                          <LoginField>
                            <span>Senha</span>
                            <input type="password" />
                          </LoginField>

                          <div>
                            <input type="checkbox" value="N"/> Salvar login
                          </div>

                          <div style={{display: 'flex', justifyContent: 'center', marginTop: 35}}>
                            <OgButtonRounded> Entrar </OgButtonRounded>
                          </div>
                       </LoginForm> */}
                    </LoginWrapper>
                </Row>
            </Container>


            <Hidden xs sm>
              <div style={{position: 'absolute', top: '-140px', left: '0'}}>
                  <LeftOrnamentGiant width={100}/>
              </div>
            </Hidden>
          </Container>

           
        
     )}/>
    );
  }
}


export default withRouter(ClientLogin);