import styled from 'styled-components';
import { device } from '../../../../css/devices';

export const OgButtonRounded = styled.a`
	padding: 8px 40px;
	background: transparent;
	border: 2px solid #edd47a;
	border-radius: 60px;
	color: white;
	font-family: 'Helvetica';
	font-weight: 600;
	text-decoration: none;
	transition: 0.2s ease;

	:hover {
		background: #edd47a;
		color: black;
	}
`;


export const OgTitle = styled.h1`
	color: white;
	font-size: 70px;
	font-weight: 600;
	font-family: 'Helvetica';
`;

export const Subtitle = styled.h3`
	font-size: 24px;
	color: #cab56d;
	font-family: 'Helvetica';
	font-weight: 500;
`;

export const Background = styled.div`
	z-index: 1;
	overflow: hidden;
	position: absolute;
	width: 100%;
	height: 475px;

	@media ${device.xs} {
		height: 620px;
	}

	::before {
		content: ' ';
		width: 100%;
		height: 475px;
		position: absolute;
		background-image: linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9));
		z-index: 1;

		@media ${device.xs} {
			height: 620px;
		}
	}
`;

export const Image = styled.img`
	min-width: 100%;
	position: relative;
	z-index: 0;
`;

export const ImageWrapper = styled.div`
	width: 100%;
	height: 100%;
	padding-top: 27px;

	:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-image: url(./assets/svg/ornament-05.svg);
		background-repeat: no-repeat;
		background-position: right;
		background-size: inherit;
	}
`;

export const MsvTitle = styled.h1`
	font-family: 'Helvetica';
	font-size: 2.1em;
	text-align: center;
	color: #4d4d4d;
`;
export const MsvParagraph = styled.p`
	text-align: justify;
	color: #4d4d4d;
	font-family: 'Helvetica';
`;
export const MsvUl = styled.ul`
	width: 100%;
	padding-top: 5px;
	padding-bottom: 15px;
	padding-left: 30px;
	color: #4d4d4d;
	font-family: 'Helvetica';
	background: #edd47a;
`;
export const Msvli = styled.li`
	font-weight: bold;
	padding-top: 10px;
`;

export const PlrsUl = styled.ul`
	padding-left: '20px';

	li {
		padding-bottom: 20px;
	}
`;
