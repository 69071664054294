import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom'
import { Container, Row, Col, Hidden, ScreenClassRender } from 'react-grid-system';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdAdd } from 'react-icons/md'
import Truncate from 'react-truncate';
import { ReactComponent as LeftOrnamentGiant } from '../../../../css/svg/solution_lines_left.svg'
import { LoginWrapper, LoginForm, LoginField, OgButtonRounded } from './styles'
import '../../../../../src/main.css';


import { Flip, Fade } from 'react-reveal'


// import { Container } from './styles';

class PoliticaPrivacidade extends Component {



  render() {
    return (
      <ScreenClassRender render={screenClass => (
        <Container fluid style={{
          background: '#FFF',
          marginTop: '5px',
          padding: 0,
          overflow: 'hidden',
          // height: '670px',
          display: 'flex',
          flexDirection: 'column',
        }}>


          <h2 className="title" style={{ color: '#2E3D62' }}>Politica de Privacidade</h2>
          <h6 className="subtitle" style={{ color: '#2E3D62' }}>Para entender como usamos os dados que você nos fornece, leia a política de privacidade.</h6>

          <div className='containerP'>
            <div className="blog-details-content">
              <h5 className="title2">1. Introdução</h5>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                A JW da Silva Informática LTDA ME (Ongold), CNPJ 09.626.023/0001-80, está
                comprometida com a proteção da sua privacidade.
                <br /> <br /> A nossa Política de Privacidade foi concebida para comunicar as nossas
                práticas relativas à coleta, utilização e divulgação de informações que o USUÁRIO
                possa fornecer através do atendimento no nosso escritório, telefone, site
                https://www.ongoldtech.com.br e outros sites associados, micro sites,
                aplicativos e
                subsistes.
                <br /> <br /> Para que nossos serviços sejam oferecidos da melhor maneira possível,
                nossa equipe, site, plataforma e aplicativos podem coletar diversos dados e
                informações, visando oferecer a melhor experiência e eficiência para você. Nós, do
                Ongold, estamos cientes da importância da sua privacidade e tomamos todas as
                medidas possíveis para assegurá-la.
                <br /> <br /> As condições e informações apresentadas neste termo estão de acordo com a
                legislação brasileira, atendendo, inclusive, os termos da Lei no. 13.709/2018 (“LGPD
                – Lei Geral de Proteção de Dados”) e normas relacionadas.
                <br /> <br /> A aceitação dos termos desta política será realizada através do acesso aos
                nossos sites, após seu cadastro no primeiro acesso a nossa plataforma (site ou
                aplicativo para celular) ou para casos excepcionais, através de e-mail. Através
                deste aceite, você declara ciência e total acordo de como suas informações e dados
                serão utilizados.
              </p>
              <p style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold', color: '#2E3D62', fontSize: '1.5rem' }}> 1.1. Controlador de dados </span>
                <br /> Quando esta política menciona “Ongold”, “nós”,
                “nos” ou “nosso”, bem como as variações gramaticais da primeira pessoa do plural,
                ela se refere à JW da Silva Informática LTDA ME que é responsável por suas
                informações sob esta Política de Privacidade (o “Controlador de Dados”).
              </p>
            </div>
            <div className="blog-details-content">
              <h5 className="title2">2. Coleta de dados e atividades</h5>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                2.1. Os dados pessoais e informações do USUÁRIO serão sempre submetidos voluntariamente pelo mesmo
                e poderão ser coletados das seguintes formas:
              </p>
              <ul className="content-list">
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', fontWeight: 'bold', color: '#2E3D62' }}>
                  Equipe interna
                </li>
                <p style={{ fontSize: '1.1rem', marginLeft: '5.5rem', marginTop: '-0.2rem' }}>
                  Os dados do USUÁRIO são fornecidos pelo mesmo e coletados pela equipe interna do
                  Ongold, através de telefone, e-mail, WhatsApp ou chat. </p>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', fontWeight: 'bold', marginTop: '1rem', color: '#2E3D62' }}>
                  Plataforma digital, site ou aplicativo
                </li>
                <p style={{ fontSize: '1.1rem', marginLeft: '5.5rem', marginTop: '-0.2rem' }}>
                  Os dados do USUÁRIO são fornecidos pelo mesmo e coletados pela plataforma (site
                  e aplicativo) fornecida pela Ongold (portal, aplicativo ou site).</p>
              </ul>

              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                2.2. Os dados e informações que coletamos incluem, porém não se limitam a:
              </p>
              <ul className="content-list">
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', fontWeight: 'bold', color: '#2E3D62' }}>
                  Dados cadastrais:
                </li>
                <p style={{ fontSize: '1.1rem', marginLeft: '5.5rem', marginTop: '-0.2rem' }}>
                  Nome completo, e-mail, CPF, telefone, rua, bairro, data de nascimento, país e
                  município de domicílio, gênero, CEP e foto. </p>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', fontWeight: 'bold', marginTop: '1rem', color: '#2E3D62' }}>
                  Dados de nfe e nfce:
                </li>
                <p style={{ fontSize: '1.1rem', marginLeft: '5.5rem', marginTop: '-0.2rem' }}>
                  Forma pagamento, valor do pagamento, valor total, data emissão, chave de acesso,
                  CNPJ emitente, inscrição estadual emitente, razão social emitente, telefone,
                  nome de fantasia emitente, logradouro emitente, número emitente, município
                  emitente, estado emitente, código do produto, gtin do produto, valor produto,
                  descrição produto, NCM, CEST, CFOP, unidade, quantidade, valor unitário, valor
                  desconto, total tributos.</p>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', fontWeight: 'bold', color: '#2E3D62' }}>
                  Dados de navegação:
                </li>
                <p style={{ fontSize: '1.1rem', marginLeft: '5.5rem', marginTop: '-0.2rem' }}>
                  Data e hora de cadastro do USUÁRIO, data e hora de saída do USUÁRIO. </p>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', fontWeight: 'bold', color: '#2E3D62' }}>
                  Dados de bibliotecas de terceiros:
                </li>
                <p style={{ fontSize: '1.1rem', marginLeft: '5.5rem', marginTop: '-0.2rem' }}>
                  Dados utilizados para melhorar a experiência do USUÁRIO na plataforma (site e
                  aplicativo). Android Advertising ID. </p>
              </ul>

              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                2.3. As atividades e ações realizadas pelo USUÁRIO e registradas na plataforma (site e aplicativo),
                incluem, porém não se limitam a:
              </p>
              <ul className="content-list">
                <p style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem' }}>
                  (i) telas acessadas, </p>
                <p style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem' }}>
                  (ii) data e hora das ações realizadas, </p>
                <p style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem' }}>
                  (iii) operações realizadas dentro da plataforma (site e aplicativo), </p>
                <p style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem' }}>
                  (ii) d(iv) dados sobre o comportamento do USUÁRIO dentro da plataforma ou site. </p>
              </ul>

              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                2.4. A Plataforma (site
                e aplicativo) também pode fazer uso de tecnologias como (i) cookies, cabendo ao USUÁRIO
                configurar o seu navegador de Internet caso deseje desativá-los consulte o tópico.
              </p>
            </div>

            <div className="blog-details-content" id="cookiesdiv">
              <h5 className="title2">3. Cookies</h5>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold', color: '#2E3D62', fontSize: '1.5rem' }}> 3.1. O que são cookies? </span>
                <br /> Cookies são arquivos criados pelos sites que você visita. Eles tornam sua
                experiência online mais fácil, economizando informações de navegação. Com os cookies, os
                sites podem manter você conectado, lembrar suas preferências do site e fornecer conteúdo
                relevante localmente.
                <br /><br /> Existem dois tipos de cookies: COOKIES PRIMÁRIOS: criados pelo site que você
                acessa. O site é exibido na barra de endereços. COOKIES DE TERCEIROS: criados por outros
                sites. Esses sites possuem uma parte do conteúdo, como anúncios ou imagens, que você vê
                na página da Web que acessa.
              </p>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold', color: '#2E3D62', fontSize: '1.5rem' }}> 3.2. Como utilizamos os cookies </span>
                <br /> Os Cookies são utilizados no site para coletar informações para nossa equipe
                técnica, administrativa e principalmente para melhorar a sua experiencia na navegação do
                site. Sendo categorizados conforme abaixo:
              </p>
              <ul className="content-list">
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', fontWeight: 'bold', color: '#2E3D62' }}>
                  Cookies de autenticação:
                </li>
                <p style={{ fontSize: '1.1rem', marginLeft: '5.5rem', marginTop: '-0.2rem' }}>
                  Servem para reconhecer um determinado USUÁRIO, possibilitando o acesso e
                  utilização do Site e/ou Aplicativo com conteúdo e/ou serviços restritos e
                  proporcionando experiências de navegação mais personalizadas. </p>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', fontWeight: 'bold', marginTop: '1rem', color: '#2E3D62' }}>
                  Cookies de segurança:
                </li>
                <p style={{ fontSize: '1.1rem', marginLeft: '5.5rem', marginTop: '-0.2rem' }}>
                  São utilizados para ativar recursos de segurança dos Sites e/ou Aplicativos, com
                  a finalidade de auxiliar o monitoramento e/ou detecção de atividades maliciosas
                  ou vedadas por esta Política de Privacidade, bem como de proteger as informações
                  do USUÁRIO do acesso por terceiros não autorizados.</p>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', fontWeight: 'bold', marginTop: '1rem', color: '#2E3D62' }}>
                  Cookies de pesquisa, análise e desempenho:
                </li>
                <p style={{ fontSize: '1.1rem', marginLeft: '5.5rem', marginTop: '-0.2rem' }}>
                  A finalidade deste tipo de cookie é ajudar a entender o desempenho do Site e/ou
                  Aplicativo, medir a audiência do Site e/ou Aplicativo, verificar os hábitos de
                  navegação dos USUÁRIOS no Site e/ou Aplicativo.</p>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', fontWeight: 'bold', marginTop: '1rem', color: '#2E3D62' }}>
                  Cookies de segmentação:
                </li>
                <p style={{ fontSize: '1.1rem', marginLeft: '5.5rem', marginTop: '-0.2rem' }}>
                  E stão ligados aos serviços prestados por terceiros, tais como, por exemplo,
                  botões de "Like" e botões de "Compartilhar" contidos em outros sites que não o
                  site Ongold.com.br. O terceiro fornece esses serviços por reconhecer que
                  você visitou nosso site, mas de maneira nenhuma se confunde com a Ongold em
                  si.</p>
              </ul>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                Em relação aos cookies acima mencionados, você pode controlar se serão usados
                ou não (vide tópico abaixo “Como Desativar os Cookies”), mas seu bloqueio pode nos
                impedir de oferecer alguns serviços ou prejudicar a boa navegação junto ao nosso site.
              </p>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold', color: '#2E3D62', fontSize: '1.5rem' }}> 3.3. Como desativar os cookies </span>
                <br /> Normalmente as configurações para desativar os cookies estão presentes nas “opções”
                ou o menu “Preferências” do seu navegador. Para saber melhor consulte as opções de ajuda
                de seu navegador. Abaixo você encontrará alguns links para os navegadores mais
                conhecidos: Internet Explorer, Firefox, Chrome, Safari Web e iOS.
                <br /><br /> Os cookies praticados pelo site da Ongold são seguros e necessários a boa
                utilização das ferramentas e opções oferecidas pelo site. Caso você opte por não os
                aceitar e/ou executá-los eventualmente o comportamento de algumas funcionalidades da
                plataforma(site e aplicativo) poderão ter suas funções comprometidas durante a sua
                visita.
              </p>
            </div>

            <div className="blog-details-content">
              <h5 className="title2">4. Como nós utilizados as informações que coletamos</h5>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                Podemos usar, armazenar e processar informações pessoais para (i) fornecer, entender,
                melhorar e desenvolver a Plataforma(site e aplicativo) Ongold, (ii) criar e manter
                um ambiente confiável e seguro (como cumprir nossas obrigações legais e garantir a
                conformidade com a Política de Privacidade) e (iii) fornecer, personalizar, avaliar e
                melhorar nossa publicidade e marketing.
              </p>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold', color: '#2E3D62', fontSize: '1.5rem' }}>  4.1. Fornecer, melhorar e desenvolver </span>
                <br /> Plataforma (site e aplicativo) da Ongold podemos usar as informações pessoais
                para fornecer, melhorar e desenvolver a Plataforma, como para:
              </p>
              <ul className="content-list">
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Permitir que você acesse e utilize a Plataforma;
                </li>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Operar, proteger, melhorar e otimizar a experiência e a Plataforma, como ao
                  realizar análise estatísticas e conduzir pesquisas;
                </li>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Se você nos fornecer suas informações de contato, podemos processar essas
                  informações para: (i) facilitar seus convites de referência, (ii) enviar suas
                  solicitações para referências, (iii) detecção e prevenção defraude, e (iv)
                  qualquer finalidade que você autorizar no momento da coleta de dados;
                </li>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Para operar, proteger, melhorar, otimizar a experiência e a Plataforma,
                  personalizar e modelar sua experiência.
                </li>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Nós processamos essas informações pessoais para esses propósitos devido ao nosso
                  interesse legítimo de usá-las para melhorar a Plataforma (site e aplicativo) do
                  Ongold e a experiência dos nossos USUÁRIOS; além disso, as processamos onde
                  for necessário para a execução adequada do ambiente.
                </li>
              </ul>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold', color: '#2E3D62', fontSize: '1.5rem' }}>   4.2. Criar e manter um ambiente seguro e confiável </span>
                <br /> Podemos usar as informações pessoais para criar e manter um ambiente confiável e
                seguro, como para:
              </p>
              <ul className="content-list">
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Detectar e evitar fraude, spam, abuso, incidentes de seguranças e outras
                  atividades nocivas;
                </li>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Realizar investigações de segurança e avaliações de risco;
                </li>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Verificar ou autenticar as informações ou identificações fornecidas por você;
                </li>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Cumprir nossas obrigações legais;
                </li>
              </ul>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '-0.2rem' }}>
                Em conexão com as atividades acima, podemos realizar perfis com base em suas
                interações com a plataforma (site e aplicativo) Ongold suas informações de
                perfil e outros conteúdos que você envie para a plataforma (site e aplicativo) e
                informações obtidas de terceiros. Se você contestar a decisão com base no
                processo automatizado, entre em contato conosco, conforme fornecido na seção
                Fale Conosco abaixo.
                <br /><br /> Nós processamos essas informações pessoais para esses propósitos devido
                ao nosso interesse legítimo em proteger a Plataforma (site e aplicativo) do
                Ongold, para avaliar o cumprimento das leis aplicáveis.</p>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold', color: '#2E3D62', fontSize: '1.5rem' }}>   4.3. Fornecer, personalizar, medir e melhorar nossa publicidade e marketing </span>
                <br /> Podemos usar as informações pessoais para fornecer, personalizar, avaliar e
                melhorar nossa publicidade e marketing, como para:
              </p>
              <ul className="content-list">
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Enviar a você mensagens promocionais, de marketing, informes publicitários e
                  outras informações que possam ser de seu interesse e propagandas em plataforma
                  (site e aplicativo) de mídias sociais como Instagram, Facebook ou Google);
                </li>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Personalizar, medir e melhorar nossa publicidade;
                </li>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Administrar atividades promocionais ou eventos patrocinados ou gerenciados pela
                  Ongold;
                </li>
              </ul>
            </div>

            <div className="blog-details-content">
              <h5 className="title2">5. Compartilhamento e divulgação</h5>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                Nos casos em que a Ongold for obrigada a divulgar ou partilhar os dados
                pessoais do USUÁRIO, afim de cumprir qualquer obrigação legal (por exemplo,
                em virtude de uma ordem judicial, ou para efeitos de prevenção de fraude ou
                outros crimes bem como em resposta à solicitação de informações apresentada
                por autoridade competente, se entendermos que a divulgação está de acordo ou
                é exigida conforme as leis, regulamentos ou procedimentos processuais
                aplicáveis;
              </p>
              <ul className="content-list">
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Para proteger os direitos, bens e/ou a segurança da plataforma, dos USUÁRIOS
                  do site, ou de quaisquer terceiros. Isso inclui a troca de informação com
                  outras empresas e organizações para fins de proteção defraude;
                </li>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Podemos compartilhar informações com terceiros os quais você opta por nos
                  deixar compartilhar informações (conforme descrito na seção 7 – Seus
                  Direitos), por exemplo, nos casos de outros aplicativos ou sites que se
                  integram a nossa plataforma;
                </li>
              </ul>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem', color: '#2E3D62' }}>
                Se, mediante notificação, você concordar em compartilhá-las (conforme descrito na
                seção 7 – Seus Direitos); De forma agregada e/ou anônima que não possa ser usada
                para identificá-lo. Para análise, utilizada em bibliotecas de terceiros. Os termos
                de uso e política de privacidade destas bibliotecas podem ser encontrados no link
                <a href="https://policies.google.com/privacy?hl=pt-BR" target="_blank" style={{ color: '#2859cc', fontWeight: 'bold' }}> Políticas da
                  Google Play Services </a>
              </p>
            </div>

            <div className="blog-details-content">
              <h5 className="title2">6. Responsabilidades</h5>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                6.1. Criar e manter um ambiente seguro e confiável.
              </p>
              <ul className="content-list">
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Por todo e qualquer conteúdo por ele carregado,
                  enviado e/ou transmitido ao Site e/ou Plataforma;
                </li>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Pela reparação de todos e quaisquer danos, diretos ou indiretos (inclusive
                  decorrentes de violação de quaisquer direitos de outros USUÁRIOS, de terceiros,
                  inclusive direitos de propriedade intelectual, de sigilo e de personalidade),
                  que sejam causados aa Ongold, a qualquer outro USUÁRIO, ou, ainda, a
                  qualquer terceiro, inclusive em virtude do descumprimento do disposto nesta
                  Política de Privacidade ou de qualquer ato praticado a partir de seu acesso à
                  Internet, ao Site e/ou Plataforma.
                </li>
              </ul>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                6.2. Nas seguintes hipóteses, a Plataforma (site e aplicativo) Ongold não será responsável:
              </p>
              <ul className="content-list">
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Por qualquer ato ou omissão realizado e/ou dano
                  causado pelo USUÁRIO decorrente do acesso ao Site e/ou Plataforma;
                </li>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Pelo uso indevido do Site e/ou Plataforma (site e
                  aplicativo) por qualquer USUÁRIO ou terceiros e/ou pelos conteúdos carregados,
                  enviados e/ou transmitido ao Site e/ou Plataforma (site e aplicativo) pelos
                  USUÁRIOS;
                </li>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Por falhas, impossibilidades técnicas ou indisponibilidades do sistema;
                </li>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Pela instalação no equipamento do USUÁRIO ou de terceiros, de vírus, trojans,
                  malware, worm, bot, backdoor, spyware, rootkit, ou de quaisquer outros
                  dispositivos que venham a ser criados, em decorrência da navegação na Internet
                  pelo USUÁRIO.
                </li>
              </ul>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                6.3. O USUÁRIO declara
                estar ciente de que todos e quaisquer conteúdos enviados e/ou transmitidos ao Site e/ou
                Plataforma (site e aplicativo) por qualquer USUÁRIO e/ou terceiros não representam, de
                nenhuma forma, a opinião ou a visão da Ongold.
              </p>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                6.4. Caso o USUÁRIO
                descumpra qualquer disposição desta Política de Privacidade ou disposição legal, sem
                prejuízo de outras medidas, a Ongold poderá, por si ou por terceiros, a qualquer
                tempo, a seu exclusivo critério, sem necessidade de qualquer aviso ou notificação prévia
                ou posterior a qualquer USUÁRIO ou a qualquer terceiro, sob qualquer meio ou forma, sem
                prejuízo de iniciar as medidas legais cabíveis, suspender ou limitar o acesso ao Site
                e/ou Plataforma, encerrar o acesso de qualquer USUÁRIO e/ou tomar outras providências
                que entender necessárias para o cumprimento desta Política de Privacidade e para o bom
                funcionamento do Site e/ou plataforma, a qualquer tempo.
              </p>
            </div>

            <div className="blog-details-content">
              <h5 className="title2">7. Seus direitos</h5>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                Você pode exercer qualquer um dos direitos descritos nesta seção junto ao seu
                Controlador de Dados da Ongold enviando um e-mail para
               suporte@ongoldtech.com.br. Tenha em mente que poderemos pedir que você confirme
                sua identidade através do e-mailsuporte@ongoldtech.com.br antes de tomar
                qualquer medida sobre seu pedido.
              </p>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold', color: '#2E3D62', fontSize: '1.5rem' }}>    7.1. Gerenciamento das suas informações </span>
                <br /> Você pode acessar e atualizar suas informações através das configurações do seu
                acesso de USUÁRIO no site e/ou plataforma (site e aplicativo) do Compra Daqui. Você
                é responsável por manter suas informações pessoais atualizadas.
              </p>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold', color: '#2E3D62', fontSize: '1.5rem' }}>    7.2. Retificação de informações incorretas ou incompletas </span>
                <br /> Você tem o direito de nos pedir para corrigir informações pessoais incorretas
                ou incompletas referentes a você, e que você não pode alterar através do seu acesso
                de USUÁRIO no site e/ou plataforma (site e aplicativo) da Ongold.
              </p>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold', color: '#2E3D62', fontSize: '1.5rem' }}>    7.3. Portabilidade e acesso a dados </span>
                <br />  De acordo com a lei aplicável você pode permitir que você solicite cópias de
                seus dados pessoais mantidas pela Ongold. Você também pode solicitar cópias de
                dados pessoais que você nos forneceu em um formato estruturado, comumente usados e
                legível e/ou solicitar que enviemos essas informações a outro provedor de serviços
                (quando for tecnicamente viável).
              </p>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold', color: '#2E3D62', fontSize: '1.5rem' }}>   7.4. Retenção e exclusão de dados </span>
                <br /> De modo geral, mantemos seus dados pessoais pelo tempo necessário. Se você não
                quiser mais que usemos suas informações, você pode solicitar que apaguemos suas
                informações pessoais e fechemos seu acesso de USUÁRIO. Por favor, leve em conta que,
                se você solicitar a exclusão de suas informações pessoais:
              </p>
              <ul className="content-list">
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Podemos reter algumas de suas informações pessoais conforme necessário para
                  nossos interesses comerciais legítimos, como detecção e prevenção de fraudes
                  e melhoria da segurança;
                </li>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Podemos reter e usar suas informações pessoais na medida necessária para
                  cumprir nossas obrigações legais. Por exemplo, relatórios legais e
                  auditorias;
                </li>
                <li style={{ fontSize: '1.1rem', marginLeft: '4rem', marginTop: '1rem', color: '#2E3D62' }}>
                  Mesmo após que o seu acesso de USUÁRIO for removido, algumas cópias de suas
                  informações (por exemplo, registros de log) podem permanecer em nosso banco
                  de dados, mas são desassociadas do seu USUÁRIO;
                </li>
              </ul>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                Como mantemos o site e/ou plataforma (site e aplicativo) Ongold para proteger
                contra a perda e destruição acidental ou maliciosa de dados, cópias residuais de
                suas informações pessoais não podem ser removidas de nossos sistemas de backup por
                um período limitado.
              </p>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold', color: '#2E3D62', fontSize: '1.5rem' }}>   7.5. Retirada de consentimento e restrição de processamento </span>
                <br /> Se estivermos processando seus dados pessoais com base em seu consentimento,
                você poderá retirar seu consentimento a qualquer momento, alterando as configurações
                do seu USUÁRIO ou enviando um e mail para suporte@ongoldtech.com.br
                especificando qual consentimento você está retirando. Por favor, tenha em mente que
                a retirada de seu consentimento não afeta a legalidade de quaisquer atividades de
                processamento com base em tal consentimento antes de sua retirada.
              </p>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold', color: '#2E3D62', fontSize: '1.5rem' }}>   7.6. Objeção ao processamento </span>
                <br /> De acordo com a lei aplicável você pode exigir que a Ongold não processe
                seus dados pessoais para determinados fins específicos. Se você se opuser a tal
                processamento, a Ongold não processará mais seus dados pessoais, a menos que
                possamos demonstrar motivos legítimos convincentes para tal processamento ou que
                esse processamento seja necessário para o estabelecimento, o exercício ou a defesa
                de reivindicações legais. Quando suas informações pessoais são processadas para fins
                de marketing direto, você pode solicitar a qualquer momento que a Ongold pare
                de processar seus dados para esses fins enviando um e mail para
                suporte@ongoldtech.com.br.
              </p>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold', color: '#2E3D62', fontSize: '1.5rem' }}>   7.7. Apresentação de reclamações </span>
                <br /> Você tem o direito de apresentar reclamações sobre nossas atividades
                registrando uma queixa junto ao nosso Departamento de Proteção de Dados, que pode
                ser acessada pela seção "Fale conosco" abaixo ou com uma autoridade controladora da
                Lei Geral de Proteção de Dados.
              </p>
            </div>

            <div className="blog-details-content">
              <h5 className="title2">8. Outras informações importantes</h5>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold', color: '#2E3D62', fontSize: '1.5rem' }}>    8.1. Propriedade intelectual </span>
                <br /> Pertencem aa Ongold: (i) todo e qualquer software, aplicativo ou
                funcionalidade empregado pela Ongold ‘referentes ao Site e/ou Plataforma(site e
                aplicativo) de acesso dos USUÁRIOS; (ii) a identidade visual do Site e/ou
                Plataforma(incluindo o projeto de arte gráfico-visual de quaisquer de suas páginas);
                (iii) o nome empresarial, marca, nome de domínio, slogan ou expressão de propaganda
                ou qualquer sinal distintivo de sua titularidade inserido no Site; e (iv) todo e
                qualquer conteúdo criado e produzido pela Ongold, por si ou por terceiros, os
                quais não poderão ser usados, sob qualquer meio ou forma, pelos USUÁRIOS.
              </p>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold', color: '#2E3D62', fontSize: '1.5rem' }}>    8.2. Segurança </span>
                <br /> De acordo com a lei aplicável você pode permitir que você solicite cópias de
                seus dados pessoais mantidas pela Ongold. Você também pode solicitar cópias de
                dados pessoais que você nos forneceu em um formato estruturado, comumente usados e
                legível e/ou solicitar que enviemos essas informações a outro provedor de serviços
                (quando for tecnicamente viável).
              </p>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold', color: '#2E3D62', fontSize: '1.5rem' }}>    8.3. Srmazenamento e transferência internacional de dados </span>
                <br />  Os dados pessoais mantidos pela Ongold poderão ser transferidos e
                armazenados em países diferentes do Brasil, incluindo países do Espaço Econômico
                Europeu (“EEE”). Pode ocorrer que estes países não possuam as mesmas leis de
                proteção de dados vigentes no Brasil. Quando transferimos as suas informações
                pessoais para outros países, protegemos essas informações na forma descrita nesta
                Política de Privacidade ou na forma que for informada no momento em que fornece
                estes dados (por exemplo, mediante um aviso de privacidade específico).
              </p>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold', color: '#2E3D62', fontSize: '1.5rem' }}>    8.4. Tolerância </span>
                <br /> A tolerância quanto ao eventual descumprimento de quaisquer das disposições
                desta Política de Privacidade por qualquer USUÁRIO não constituirá renúncia ao
                direito de exigir o cumprimento da obrigação, nem perdão, nem alteração do que
                consta aqui previsto.
              </p>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold', color: '#2E3D62', fontSize: '1.5rem' }}>   8.5. Alterações na política de privacidade </span>
                <br /> a Ongold se reserva ao direito de modificar esta Política de Privacidade a
                qualquer momento de acordo com esta provisão. Desta forma, recomendamos visitar
                periodicamente a Política de Privacidade para que você tenha conhecimento sobre as
                modificações. Caso façamos alterações nesta Política de Privacidade, nós
                publicaremos no link
                <a href="politica-de-privacidade.html" target="_blank" style={{ color: '#2859cc', fontWeight: 'bold' }}> https://ongoldtech.com.br/politicaprivacidade </a>
                e atualizaremos a data da “Última Atualização” no início desta Política de
                Privacidade.
              </p>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold', color: '#2E3D62', fontSize: '1.5rem' }}>   8.6. Legislação e foro </span>
                <br /> Esta Política de Privacidade são regidos de acordo com a legislação brasileira.
                Quaisquer disputas ou controvérsias oriundas de quaisquer atos praticados no âmbito
                da utilização dos Sites e/ou Aplicativos pelos USUÁRIOS, inclusive com relação ao
                descumprimento desta Política de Privacidade ou pela violação dos direitos do
                Ongold de outros USUÁRIOS e/ou de terceiros, inclusive direitos de propriedade
                intelectual, de sigilo e de personalidade, serão processadas na Comarca do município
                de Limoeiro, Estado de Pernambuco.
              </p>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold', color: '#2E3D62', fontSize: '1.5rem' }}>   7.7. Apresentação de reclamações </span>
                <br /> Você tem o direito de apresentar reclamações sobre nossas atividades
                registrando uma queixa junto ao nosso Departamento de Proteção de Dados, que pode
                ser acessada pela seção "Fale conosco" abaixo ou com uma autoridade controladora da
                Lei Geral de Proteção de Dados.
              </p>
            </div>

            <div className="blog-details-content">
              <h5 className="title2">9. Fale conosco</h5>
              <p style={{ fontSize: '1.1rem', marginLeft: '2.5rem', marginTop: '1rem' }}>
                Se você tiver dúvidas ou reclamações sobre esta Política de Privacidade ou sobre as
                práticas de manipulação de informações da Ongold, pode ser esclarecida através
                do email <a href="mailto:suporte@ongoldtech.com.br" style={{ color: '#2859cc', fontWeight: 'bold' }}>suporte@ongoldtech.com.br</a>.
                A Ongold está localizada na Av.
                Severino Pinheiro, 470, Centro. CEP: 55700-000 – Limoeiro/PE.
              </p>
            </div>

          </div>

          <Hidden xs sm>
            <div style={{ position: 'fixed', top: '10px', left: '0' }}>
              <LeftOrnamentGiant width={100} />
            </div>
          </Hidden>
        </Container>



      )} />
    );
  }
}


export default withRouter(PoliticaPrivacidade);