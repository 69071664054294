import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Hidden, ScreenClassRender } from 'react-grid-system';
import { Fade } from 'react-reveal';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers'
import * as yup from "yup"
import InputMask from "react-input-mask"
import Axios from 'axios';
import Lottie from "react-lottie";
import * as errorEmailAnimation from '../../assets/lottie_files/error-animation.json'
import * as sendingEmailAnimation from '../../assets/lottie_files/sending_email.json'

import {
  Input,
  Span,
  Textarea,
  OgButtonRounded,
  OrnamentWrapper,
  SingleOrnament,
  ContactForm,
  ContactResult,
  Image
} from './styles';

const sendingMessageStates = {
  IDLE: "IDLE",
  SENDING: "SENDING",
  MESSAGE_SENDED_SUCCESSFULLY: "MESSAGE_SENDED_SUCCESSFULLY",
  SENDING_MESSAGE_FAILED: "SENDING_MESSAGE_FAILED"
}

const schema = yup.object().shape({
  nome: yup.string().required("Por favor, preencha o seu nome").min(5, "Por favor, digite ao menos 5 caracteres."),
  email: yup.string().required("Por favor, preencha o seu email.").email("Por favor, informe um email válido"),
  telefone: yup.number().min(9, "Informe um celular válido"),
  mensagem: yup.string().required("Por favor, escreva-nos sua mensagem."),
});

export default function SuggestionForm(props) {
  const { register, handleSubmit, errors } = useForm(
    { resolver: yupResolver(schema) }
  );

  const [isShowingFormContact, setShowFormContact] = useState(true);
  const [isSendingEmail, setSendingEmail] = useState(sendingMessageStates.IDLE);

  const [valueNome, setNome] = useState("")
  const [valueEmail, setEmail] = useState("")
  const [valueTelefone, setTelefone] = useState("")
  const [valueMensagem, setMensagem] = useState("")

  const handleShowingContactForm = () => {
    setSendingEmail(sendingMessageStates.IDLE)

  };

  useEffect(() => console.log(isSendingEmail), [isSendingEmail])

  const errorLottieOption = {
    loop: false,
    autoplay: true,
    animationData: errorEmailAnimation.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const sendingEmailLottieOption = {
    loop: false,
    autoplay: true,
    animationData: sendingEmailAnimation.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const handleSubmitFormContact = (evt) => {
    setSendingEmail(sendingMessageStates.SENDING);
    const data = {
      nome: valueNome,
      email: valueEmail,
      telefone: valueTelefone,
      assunto: `Contato do site`,
      mensagem: valueMensagem
    }

    Axios.post('http://ongoldtech.com/application/public/mail', data, {
      headers: {

      }
    })
      .then(({ status, data }) => {
        console.log(data)
        if (!data.success) {
          setSendingEmail(sendingMessageStates.SENDING_MESSAGE_FAILED);
          return false;
        }

        setSendingEmail(sendingMessageStates.MESSAGE_SENDED_SUCCESSFULLY);
        setNome("")
        setEmail("")
        setTelefone("")
        setMensagem("")

      }).catch(() => {
        setSendingEmail(sendingMessageStates.SENDING_MESSAGE_FAILED);
        return false;
      })

  }

  return (
    <ScreenClassRender render={screenClass => (
      <Container style={{
        fontFamily: 'Helvetica',
      }}>

        {isSendingEmail == sendingMessageStates.IDLE &&
          <ContactForm isShowing={true} onSubmit={handleSubmitFormContact}>
            <Row>
              <Col xs={12} xl={12}>
                <h1 style={{ textAlign: 'center', color: '#4D4D4D' }}>
                  {props.Title || 'Entre em contato conosco'}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col xl={12} md={12}>
                <Row style={{ marginTop: '20px' }}>
                  <Col xl={12} md={12} xs={12}>
                    <div>
                      <Span>*Seu nome</Span>
                      <Input ref={register} type="text" name="nome" onChange={({ target }) => setNome(target.value)} />
                      <p>{errors.nome?.message}</p>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Col xl={6} md={6} xs={12}>
                    <div>
                      <Span>*Seu email</Span>
                      <Input ref={register} type="email" name="email" onChange={({ target }) => setEmail(target.value)} />
                      <p>{errors.email?.message}</p>
                    </div>
                  </Col>
                  <Col xl={6} md={6} xs={12}>
                    <div>
                      <Span>Telefone</Span>
                      <InputMask ref={register} type="text" mask="(99) 9 9999-9999" name="telefone"
                        onChange={({ target }) => setTelefone(target.value.replace(/[^0-9]+/g, ""))} value={valueTelefone}

                        style={{
                          width: '100%',
                          height: 40,
                          border: '1px solid #4D4D4D',
                          fontSize: '1.2em',
                          paddingLeft: 5,
                        }} />
                      <p>{errors.telefone?.message}</p>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Col xl={12} md={12} xs={12}>
                    <div>
                      <Span>*Mensagem</Span>
                      <Textarea ref={register} rows={8} name="mensagem" onChange={({ target }) => setMensagem(target.value)} />
                      <p>{errors.mensagem?.message}</p>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Col xl={12} md={12} xs={12}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <OgButtonRounded onClick={handleSubmit(handleSubmitFormContact)}>
                        {isSendingEmail == sendingMessageStates.SENDING ? 'Enviando...' : 'Enviar'}
                      </OgButtonRounded>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ContactForm>}

        {isSendingEmail == sendingMessageStates.MESSAGE_SENDED_SUCCESSFULLY &&
          <Fade>
            <Container>
              <Row >
                <Col xl={6} xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <h1>Recebemos suas informações</h1>
                  <h3>Em breve entraremos em contato com você</h3>
                  <Image src="./assets/images/ogCheck.png" alt="" />
                  <p>
                    A On Gold está comprometida com sua privacidade. Não serão encaminhadas informações para
                    seu e-mail e telefone sem sua autorização. Atendentes podem contatar você para dar
                    continuidade à solicitação.
							</p>
                  <div>
                    <OgButtonRounded onClick={handleShowingContactForm}>Voltar</OgButtonRounded>
                  </div>
                </Col>
              </Row>
            </Container>
          </Fade>}

        {isSendingEmail == sendingMessageStates.SENDING &&
          <Fade>
            <Container>
              <Row >
                <Col xl={6} xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Lottie
                    options={sendingEmailAnimation}
                    height={200}
                    width={200}
                    isStopped={false}
                  />

                  <h3>Aguarde...</h3>
                </Col>
              </Row>
            </Container>
          </Fade>}


        {isSendingEmail == sendingMessageStates.SENDING_MESSAGE_FAILED &&
          <Container>
            <Row style={{ justifyContent: 'center' }}>
              <Col xl={6} xs={12}>
                <Fade>
                  <Lottie
                    options={errorLottieOption}
                    height={200}
                    width={200}
                    isStopped={false}
                  />
                  <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <div><p>Ops! Não foi possível enviar sua mensagem. Lamentamos o incômodo.</p></div>
                    <div><OgButtonRounded onClick={handleShowingContactForm}>Voltar</OgButtonRounded></div>
                  </div>
                </Fade>
              </Col>
            </Row>
          </Container>

        }
      </ Container>
    )} />
  );
}
