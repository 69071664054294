import styled, {keyframes} from 'styled-components';
import {Link} from 'react-router-dom';
import { device } from './devices'

export const MainNavContainer = styled.div`
  /* background-color: grey; */
  height: 75px;
  padding-top: 15px;
  z-index: 2;
  /* border-bottom: 1px white solid; */
  
  
  
`;

export const Background = styled.div`
    z-index:1;    
    overflow: hidden;
    position:absolute;
    width: 100%;
    height: 100%;
`;

export const Overlay = styled.div`

  ::before{
    content: ' ';
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: linear-gradient(to right, rgba(0,0,0,0.9), rgba(0,0,0,0.5));
    z-index: 1;
  }
`

export const Image = styled.img`
    min-width: 100%;
    position: relative;
    z-index: 0;  
 `;

export const MainNav = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    
`;

export const MainNavItem = styled.a`
  margin-left: 5px;
  padding: 15px;
  color: white;
  text-decoration: none;
  font-family: 'Helvetica' !important;
  font-weight: 500;
  background-color: ${props => props.active == true ? '#EDD47A' : 'transparent'}; 
  cursor: pointer;
  
  :hover, :active{
    background-color: #EDD47A;
    color: #4D4D4D;
  }

  
  
`;
export const MainNavItemLink = styled(Link)`
  margin-left: 5px;
  padding: 15px;
  color: white;
  text-decoration: none;
  font-family: 'Helvetica' !important;
  font-weight: 500;
  cursor: pointer;
  
  :hover, :active{
    background-color: #EDD47A;
    color: #4D4D4D;
  }

  
  
`;
export const Logo = styled.img`
  max-width: 100%;
`;
export const LogoEmp = styled.img`
  max-width: 15%;
  border-radius: 50%;
`;

export const MenuNavToogle = styled.div`
  margin-top: 7px;
  right: 0;
  position: absolute;
    
`;

export const SideMenu = styled.div`
  width: 300px;
  height: 200px;
  background: #EDD47A;
  position: absolute;
  top: -21px;
  z-index: 10;
  right: 0;
  /* border: 1px #EDD47A solid; */


`


export const SideMenuInner = styled.div`
  width: 250px;
  height: 200px;
  background: #4D4D4D;
 
`

export const SideMenuItem = styled.a`
  display: flex;
  flex-direction: row;
  padding: 10px 25px;
  font-family: 'Helvetica';
  color: #EDD47A;
  border-bottom: 1px solid #EDD47A;
  cursor: pointer;

  :hover{
    background:#EDD47A;
    color:#4D4D4D ;
  }

 
  
`

export const OgButtonRounded = styled.a`
    padding: 8px 40px;
    background: transparent;
    border: 2px solid #EDD47A;
    border-radius: 60px;
    color: white;
    font-family: 'Helvetica';
    font-weight: 600;
    text-decoration: none;
    transition: 0.2s ease;

    :hover{
        background: #EDD47A;
        color: black;
    }
`;

export const OgTitle = styled.h1`
    color: white;
    font-size: 2.8em;
    font-weight: 600;
    font-family: 'Helvetica';

    @media ${device.xs} {
      font-size: 1.3em;
    }
`


export const TWSMenu = styled.div`
  width: 300px;
  height: 367px;
  background: #EDD47A;
  position: absolute;
  z-index: 100;
  right: 0;
  top: -73px;
  display:inline-block;  

`
export const TWSInner = styled.ul`
  width: 250px;
  list-style: none;
  float: right;
  margin-top: 0;
  
`
export const TWSHead = styled.div`
  width: 250px;
  height: 80px;
  background: #4D4D4D; 
  float: right;
  display:flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Helvetica'; 
  color: #EDD47A;
`

export const TWSItem = styled.li`
  padding: 15px 20px;
  background: #4D4D4D;
  color: #EDD47A;
  font-family: 'Helvetica'; 
  margin-top: 3px;

  :hover{
    background: #EDD47A ;
    color: #4D4D4D; 
  }  
  :hover a{
    background: #EDD47A ;
    color: #4D4D4D; 
  }  

  a{
    color: #EDD47A;
    text-decoration: none;

    
  }
`