import React from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import LazyLoad from 'react-lazyload';
import CookieConsent from 'react-cookie-consent';
import { withRouter } from 'react-router-dom';

import Header from './components/Header';
import Body from './components/Body';
import Bottom from './components/Bottom';

function App(props) {
	const location = props.location.pathname;

	return (
		<LazyLoad height={200} once>
			<div>
				<Header static={location === '/'} />
				<CookieConsent
					debug={true}
					location='bottom'
					style={{ background: '#0f0f11', textAlign: 'left' }}
					buttonStyle={{
						color: '#0f0f11',
						background: '#E7CF78',
						fontSize: '16px',
						width: '100px',
						fontWeight: 'bold',
						marginRight: '10rem',
					}}
					buttonText='Aceitar'
					expires={365}
				>
					Nós armazenamos dados temporariamente para melhorar a sua experiência de navegação e recomendar conteúdo de
					seu interesse. Ao utilizar nossos serviços, você concorda com tal monitoramento.
					<a href='/politicaprivacidade#cookiesdiv' target='_blank'>
						{' '}
						Politica de Privacidade.
					</a>
				</CookieConsent>
				<Body />
			</div>
			<footer>
				<Bottom />
			</footer>
		</LazyLoad>
	);
}

export default withRouter(App);
