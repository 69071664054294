import React, { Component, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom'
import { MdCall, MdPerson, MdMessage } from 'react-icons/md';
import { Container, Row, Col, Hidden, ScreenClassRender } from 'react-grid-system';
import { ReactComponent as LeftOrnamentGiant } from '../../../../css/svg/solution_lines_left.svg'
import {
  SoftwareWrapper, LoginForm, LoginField, OgButtonRounded, Image, SoftwareCard,
  SoftwareCardHeader, SoftwareCardBottom
} from './styles'
import SuggestionForm from '../../../SuggestionForm';


import { Flip, Fade } from 'react-reveal'


// import { Container } from './styles';

function Parceiro() {

  useEffect(()=> window.location="https://indicacao.ongold.com.br/", [])


  return (
    <ScreenClassRender render={screenClass => (
      <>
        <Container fluid style={{
          background: '#FFF',
          marginTop: '5px',
          padding: 0,
          overflow: 'hidden',
          height: '670px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>

          <Container>
            <Row>
              <Col xs={12} xl={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src="./assets/svg/svg-loaders/grid.svg" width="40" />
                <h4>Redirecionando...</h4>
              </Col>
              {/* <Col xs={12} xl={6}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: 20,
                  paddingLeft: 50

                }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 35 }}>
                    <MdCall size="3.5em" color="#EDD47A" style={{ marginRight: 10 }} />
                    <h2>(81) 3628-6521</h2>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 35 }}>
                    <MdPerson size="3.5em" color="#EDD47A" style={{ marginRight: 10 }} />
                    <OgButtonRounded>Painel do Cliente</OgButtonRounded>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 35 }}>
                    <MdMessage size="3.5em" color="#EDD47A" style={{ marginRight: 10 }} />
                    <OgButtonRounded>Painel do Cliente</OgButtonRounded>
                  </div>
                </div>
              </Col> */}
            </Row>
          </Container>


          <Hidden xs sm>
            <div style={{ position: 'absolute', top: '-140px', left: '0' }}>
              <LeftOrnamentGiant width={100} />
            </div>
          </Hidden>
        </Container>
        {/* <Container fluid style={{ background: '#e0e0e0' }}>
          <Container>
            <Row style={{ flex: 1, justifyContent: 'center' }}>
              <Col xs={12} xl={6} md={6}>
                <h1 style={{ textAlign: 'center' }}>Downloads</h1>
                <p style={{ textAlign: 'center' }}>A OnGold disponibliza alguns programas e documentos para facilitar a busca do nosso cliente.</p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} xl={12} md={12}>
                <SoftwareWrapper>
                  <SoftwareCard>
                    <SoftwareCardHeader>
                      <Image src="./assets/images/teamviewer-icon.png" />
                      <h2>Team Viewer </h2>
                      <span>Administrador de computadores à distância </span>
                    </SoftwareCardHeader>

                    <SoftwareCardBottom>
                      <a href="./">Download</a>

                    </SoftwareCardBottom>

                  </SoftwareCard>
                  <SoftwareCard>
                    <SoftwareCardHeader>
                      <Image src="./assets/images/logo_anydesk.png" />
                      <h2>Any Desk </h2>
                      <span>Administrador de computadores à distância </span>
                    </SoftwareCardHeader>

                    <SoftwareCardBottom>
                      <a href="./">Download</a>

                    </SoftwareCardBottom>

                  </SoftwareCard>
                  <SoftwareCard>
                    <SoftwareCardHeader>
                      <Image src="./assets/images/aa.png" />
                      <h2>Ammy Admin</h2>
                      <span>Administrador de computadores à distância </span>
                    </SoftwareCardHeader>

                    <SoftwareCardBottom>
                      <a href="./">Download</a>

                    </SoftwareCardBottom>

                  </SoftwareCard>
                  <SoftwareCard>
                    <SoftwareCardHeader>
                      <Image src="./assets/images/pdf.png" />
                      <h2>Tabela COP </h2>
                      <span>Administrador de computadores à distância </span>
                    </SoftwareCardHeader>

                    <SoftwareCardBottom>
                      <a href="./">Download</a>

                    </SoftwareCardBottom>

                  </SoftwareCard>
                </SoftwareWrapper>
              </Col>
            </Row>
          </Container>
        </Container> */}
      </>

    )} />
  );

}


export default withRouter(Parceiro);