import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { StyledH1 } from '../Products/styles'

const NotFoundPage = () =>
  (
    <div style={{
      marginTop: '-400px',
      
      paddingTop: 200,
      paddingBottom: 200,
    }}>
      <StyledH1>404</StyledH1>
      <p>Ops! Parece que esta página não existe.</p>
    </div>
  )

export default withRouter(NotFoundPage);