import styled from 'styled-components';

export const BodyWrapper = styled.div`
    position: relative;
    z-index: 1;
    min-width: 100%;
    margin-bottom: 25px;
    /* min-height: 681px; */
    ;
`;

