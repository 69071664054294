import React from 'react';
import { GrLineChart, GrAction, GrSecure, GrTransaction, GrValidate, GrDocumentConfig, GrNotes } from 'react-icons/gr';
import { GiReceiveMoney, GiCash, GiChecklist, GiCheckedShield, GiStabbedNote } from 'react-icons/gi';
import { IoIosCalculator } from 'react-icons/io';
import { BsCheckBox } from 'react-icons/bs';
import { AiFillApple, AiFillCalculator, AiOutlineControl, AiOutlineFundProjectionScreen, AiOutlineInbox, AiOutlineStock } from 'react-icons/ai';
import { GoListOrdered, GoChecklist, GoCalendar } from "react-icons/go";
import { FaCalendarCheck, FaDollarSign, FaDollyFlatbed, FaTruckLoading } from "react-icons/fa";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import {
  ProductTitle,
  ProductSubTitle,
  ProductText
} from './styles'
import {

  SlideLink,

} from '../../ProductSlider/productSliderStyles';
import { MdAttachMoney, MdTimeline, MdPrint, MdEmail, MdPermPhoneMsg, MdUpdate, MdMoneyOff, MdContactPhone, MdContacts, MdDone } from 'react-icons/md';
import { RedirectLink, RedirectLinkPlano } from '../../../Header/styles';
import { CADASTRO_ONGOLD } from '../../../../utils/consts';

import './Components/styles.css';

export const data = [
  {
    id: 1,
    name: 'gmex',
    lightTheme: true,
    image: '../assets/images/products/products_baselaptop/gmex.png',
    title: <ProductTitle>GMEX</ProductTitle>,
    titleText: 'GMEX',
    subTitle: <ProductSubTitle>GERENCY MOBILE EXPRESS</ProductSubTitle>,
    text: <div className='cadastro-lojista-button'>
      <ProductText>Realize emissões de NFC-e diretamente do seu celular ou tablet, de uma forma simples, sem precisar gastar com outros equipamentos.</ProductText>
      <ProductText>Negócios que envolvem a venda de produtos exigem flexibilidade e maior agilidade. Com o Gmex você tem uma ferramenta para potencializar a satisfação dos seus clientes com menor tempo de espera e consequentemente aumentar suas vendas.</ProductText>
      <a href="https://play.google.com/store/apps/details?id=com.ongoldtech.gmex">
        <img alt="Disponível no Google Play" width="200" src="https://play.google.com/intl/pt-BR/badges/static/images/badges/pt_badge_web_generic.png" />
      </a>
      <RedirectLinkPlano href={CADASTRO_ONGOLD} target='_blank' style={{ background: '#00C18A', color: '#fff', borderColor: '#00C18A', width: '250px' }}>Experimente Grátis</RedirectLinkPlano>
    </div>,
    benefits: [
      {
        icon: <GoListOrdered size={48} />,
        text: 'Maior controle e facilidade à operação de vendas'
      },
      {
        icon: <GiChecklist size={48} />,
        text: 'Sistema em Cloud Computing '
      },
      {
        icon: <MdUpdate size={48} />,
        text: 'Mobilidade, como estratégia'
      },
      {
        icon: <MdContacts size={48} />,
        text: 'Potencializar o contato com os clientes'
      },
      {
        icon: <GiReceiveMoney size={48} />,
        text: 'Agilidade nas vendas'
      },
      {
        icon: <GrNotes size={48} />,
        text: 'Emissão de NFCe rápida '
      },
    ],
    secondaryTitle: 'TRANSFORMAÇÃO DIGITAL DO VAREJO',
    secondarySubtitle: ' ',
    secondaryText: <div>
      <p>Os avanços tecnológicos dos últimos anos e o cenário econômico mundial mudaram a forma
        de se comunicar, vender e consumir, sendo essa nova fase do comércio de uma proporção
        gigantesca.</p>
      <p>Nesta nova era do varejo, conhecida como Varejo 4.0, é necessário conciliar as
        tendências da internet com o mundo real para aprimorar a experiência de compra
        e o relacionamento com o cliente, implementando um modelo de negócio colaborativo,
        agregando valor à operação comercial de compra e venda de produtos.
        Acompanhar o comportamento do público da sua empresa nas redes sociais é a chave
        para reinventar suas estratégias de venda.</p>
    </div>,
    functionalitiesTitle: 'Funcionalidades',
    functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
    functionalitiesItems: [
      'Cadastro de Clientes',
      'Cadastro de Produtos',
      'Emissão e gerenciamento de pedidos',
      'Emissão e gerenciamento de NFCe',
      'Dashboard de suas vendas',
      'e muito mais!',

    ]
  },

  {
    id: 2,
    name: 'gerencyfinanceiro',
    lightTheme: true,
    image: '../assets/images/caixa-gerency.png',
    title: <ProductTitle>GERENCY ERP</ProductTitle>,
    titleText: 'GERENCY ERP',
    subTitle: <ProductSubTitle style={{ fontSize: '1.7rem'}}>TECNOLOGIA DIGITAL APLICADA À GESTÃO DE SUA EMPRESA</ProductSubTitle>,
    text: <div>
      <ul
        style={{
          listStyle: 'none',
          color: '#EDD47A',
          fontSize: '1.3rem',
          margin: 0,
          paddingLeft: 0
        }}
      >
        <li style={{ color: '#000' }} >
          {' '}
          <MdDone /> Otimização e simplificação dos processos
          de venda
        </li>
        <li style={{ color: '#000' }} >
          {' '}
          <MdDone /> Controle das atividades
          Administrativas/Financeiras
        </li>
        <li style={{ color: '#000' }} >
          {' '}
          <MdDone /> Aumento da produtividade
        </li>
        <li style={{ color: '#000' }} >
          {' '}
          <MdDone /> Redução de Custos
        </li>
        <li style={{ color: '#000' }} >
          {' '}
          <MdDone /> Gestão Integrada
        </li>
        <li style={{ color: '#000' }} >
          {' '}
          <MdDone /> Controle dos prazos
        </li>
      </ul>
    </div>
    ,
    benefits: [
      {
        icon: <AiOutlineFundProjectionScreen size={48} />,
        text: 'Previsão Orçamentária e Fluxo de Caixa.'
      },
      {
        icon: <AiOutlineStock size={48} />,
        text: 'Gerenciamento de Plano de Contas e Centro de Custo.'
      },
      {
        icon: <GoCalendar size={48} />,
        text: 'Faça o acompanhamento mês a mês.'
      },
      {
        icon: <FaDollyFlatbed size={48} />,
        text: 'Maior controle sobre a rotatividade dos produtos.'
      },
      {
        icon: <GiReceiveMoney size={48} />,
        text: 'Menos desperdício de recursos.'
      },
      {
        icon: <AiFillCalculator size={48} />,
        text: 'Gestão e monitoramento da tributação.'
      },
      {
        icon: <GoChecklist size={48} />,
        text: 'Análises e estatísticas sobre a saúde do seu negócio.'
      },
      {
        icon: <GrNotes size={48} />,
        text: 'Emissão de NFCe, NFe, DFe e etc rápido e simples.'
      },
      {
        icon: <IoIosCalculator size={48} />,
        text: 'Cálculos automáticos de tributos.'
      },
      {
        icon: <GrValidate size={48} />,
        text: 'Verificação da real necessidade da aquisição'
      },
      {
        icon: <FaTruckLoading size={48} />,
        text: 'Histórico da Movimentação do Estoque.'
      },
      {
        icon: <GrDocumentConfig size={48} />,
        text: 'Gerenciamento e recebimento automático das compras.'
      },
    ],
    secondaryTitle: 'Gerency, mais que um ERP',
    // secondarySubtitle: 'Com o GerencyFinanceiro, você terá o controle sobre seus recebíveis e contas a pagar',
    secondaryText: <div>
      <p>O <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> permite o controle de receitas e despesas, assim como gerenciar com facilidade o fluxo de caixa, permitindo que você saiba de todos os recursos que entram ou saem da empresa.</p>
      <p>No <span style={{ fontWeight: 'bold' }}>estoque o Gerency</span> funciona como uma ferramenta de monitoramento de tudo que está disponível e permite que você programe melhor as compras, tornando-se parte importante do seu planejamento.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span>  você tem análise de dados para fidelização e o aumento da lucratividade, além, da troca de informações entre as áreas da empresa como: fiscal, administrativo, financeiro, produção e logística.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> tenha um sistema eficiente para fazer a cotação de serviços e produtos, reduzir o custo de aquisição da mercadoria para, então, praticar preços mais competitivos.</p>
    </div>,
    functionalitiesTitle: 'Recursos',
    functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
    functionalitiesItems: [
      'Controle de Clientes',
      'Controle de Fornecedores',
      'Contas Correntes',
      'Controle de Supervisores',
      'Controle de Cobradores',
      'Controle das Despesas',
      'Controle das Receitas',
      'Fluxo de Caixa',
      'Previsão Orçamentária',
      'Conciliação Bancária',
      'Contas Correntes',
      'Emissão de Boletos',
      'Gerenciamento de Cobranças',
      'Gerenciamentos de Cartões',
      'Gerenciamento do Plano de Contas',
      'Gerenciamento por Centro de Custo',
      'E muito mais...',
    ]
  },

  {
    id: 3,
    name: 'nfe',
    lightTheme: true,
    image: '../assets/images/products/products_baselaptop/nfce_cover.png',
    title: <ProductTitle>GERENCY PRO</ProductTitle>,
    titleText: 'GERENCY PRO',
    subTitle: 'Software emissor e gerenciador de Notas Fiscais Eletrônicas.',
    text: `A nota fiscal eletrônica é essencial para qualquer tipo de empresa
        que queira escalar e não tem tempo de fazer esse processo manualmente.
        Com o emissor GerencyNFe, você consegue dar atenção a fatores que realmente
        fazem a diferença.`,
    benefits: [
      {
        icon: <GrLineChart size={48} />,
        text: 'Aumento da produtividade'
      },
      {
        icon: <GiReceiveMoney size={48} />,
        text: 'Liste as contas por ordem de recebimento'
      },
      {
        icon: <BsCheckBox size={48} />,
        text: 'Menor margem de erro'
      },

      {
        icon: <AiOutlineControl size={48} />,
        text: 'Melhor controle dos números do negócio'
      },
      {
        icon: <GrAction size={48} />,
        text: 'Integração simples com o seu meio de pagamento'
      }
    ],
    secondaryTitle: 'EMITA SUAS NOTAS FISCAIS COM FACILIDADE',
    secondarySubtitle: 'Não perca mais tempo incluindo os mesmos detalhes todas as vezes ao emitir uma NFe.',
    secondaryText: `O GerencyNFE entrega toda a facilidade e comodidade otimizando o processo de preenchimento de
		uma nota fiscal com os dados já cadastrados. Isso inclui dados de tributação, preços, quantidades e muito mais.
		`,
    functionalitiesTitle: 'Funcionalidades',
    functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
    functionalitiesItems: [
      'Emissão de NFe de forma simples e rápida.',
      'Cálculo automático de tributos de totais.',
      'Detalhamento de todos os itens da nota.',
      'Consulta a situação da NFe.',
      'Consulta o status de Serviço da Sefaz.',
      'Envia automaticamente por e - mail o xmls e pdf da nota.',
      'Duplicar uma nota facilitando a criação de novas Nfe.',
      'Impressão do Danfe da Nota',
      'Controla os eventos da Nf - e: Manifestação do usuário, Carta de Correção, Inutilização, impressão dos eventos.',
      'Importa pedidos, solicitação de transferências, devoluções geradas no PDV.',
      'Converte notas fiscais de compras automaticamente para notas de devolução, remessa ou retorno.',
      'Cadastro completo dos participantes, produtos e tributos.',
      'Relatórios analíticos e resumidos de notas.',
      'Consultas dos mais diversos tipos: Resumo NFe, Inutilização, Disponibilidade dos serviços, Consulta CPF e CNPJ.',
      'Auxílio em Devoluções com ST.',
      'Gerenciamento de Romaneios para Entrega.'
    ]
  },

  //SUPERMERCADO 4
  {
    id: 4,
    name: '4',
    lightTheme: false,
    image: '../assets/images/caixa-gerency.png',
    title: <ProductTitle style={{ fontWeight: 'bold' }}>GERENCY ERP</ProductTitle>,
    titleText: 'GERENCY ERP',
    subTitle: <ProductSubTitle style={{ fontSize: '1.7rem', marginTop: '30px' }}>Supermercados e Mercadinhos</ProductSubTitle>,
    text: 
    <div>
      <ProductText style={{color: '#fff', fontSize: '1.4rem'}}>Um sistema ERP para supermercados é uma ferramenta tecnológica que possibilita automatizar e integrar todas as atividades, que vão desde as administrativas, controle de estoque, gestão fiscal, atendimento PDV, CRM, entre outras padronizando os processos da empresa, reduzindo os erros e, consequentemente, aumentando a produtividade.</ProductText>
    </div>
    ,
    benefits: [
      {
        icon: <AiOutlineFundProjectionScreen size={48} />,
        text: 'Previsão Orçamentária e Fluxo de Caixa.'
      },
      {
        icon: <AiOutlineStock size={48} />,
        text: 'Gerenciamento de Plano de Contas e Centro de Custo.'
      },
      {
        icon: <GoCalendar size={48} />,
        text: 'Faça o acompanhamento mês a mês.'
      },
      {
        icon: <FaDollyFlatbed size={48} />,
        text: 'Maior controle sobre a rotatividade dos produtos.'
      },
      {
        icon: <GiReceiveMoney size={48} />,
        text: 'Menos desperdício de recursos.'
      },
      {
        icon: <AiFillCalculator size={48} />,
        text: 'Gestão e monitoramento da tributação.'
      },
      {
        icon: <GoChecklist size={48} />,
        text: 'Análises e estatísticas sobre a saúde do seu negócio.'
      },
      {
        icon: <GrNotes size={48} />,
        text: 'Emissão de NFCe, NFe, DFe e etc rápido e simples.'
      },
      {
        icon: <IoIosCalculator size={48} />,
        text: 'Cálculos automáticos de tributos.'
      },
      {
        icon: <GrValidate size={48} />,
        text: 'Verificação da real necessidade da aquisição'
      },
      {
        icon: <FaTruckLoading size={48} />,
        text: 'Histórico da Movimentação do Estoque.'
      },
      {
        icon: <GrDocumentConfig size={48} />,
        text: 'Gerenciamento e recebimento automático das compras.'
      },
    ],
    secondaryTitle: 'Gerency, mais que um ERP',
    // secondarySubtitle: 'Com o GerencyFinanceiro, você terá o controle sobre seus recebíveis e contas a pagar',
    secondaryText: <div>
      <p>O <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> permite o controle de receitas e despesas, assim como gerenciar com facilidade o fluxo de caixa, permitindo que você saiba de todos os recursos que entram ou saem da empresa.</p>
      <p>No <span style={{ fontWeight: 'bold' }}>estoque o Gerency</span> funciona como uma ferramenta de monitoramento de tudo que está disponível e permite que você programe melhor as compras, tornando-se parte importante do seu planejamento.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span>  você tem análise de dados para fidelização e o aumento da lucratividade, além, da troca de informações entre as áreas da empresa como: fiscal, administrativo, financeiro, produção e logística.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> tenha um sistema eficiente para fazer a cotação de serviços e produtos, reduzir o custo de aquisição da mercadoria para, então, praticar preços mais competitivos.</p>
    </div>,
    functionalitiesTitle: 'Recursos',
    functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
    functionalitiesItems: [
      'Controle de Clientes',
      'Controle de Fornecedores',
      'Contas Correntes',
      'Controle de Supervisores',
      'Controle de Cobradores',
      'Controle das Despesas',
      'Controle das Receitas',
      'Fluxo de Caixa',
      'Previsão Orçamentária',
      'Conciliação Bancária',
      'Contas Correntes',
      'Emissão de Boletos',
      'Gerenciamento de Cobranças',
      'Gerenciamentos de Cartões',
      'Gerenciamento do Plano de Contas',
      'Gerenciamento por Centro de Custo',
      'E muito mais...',
    ]
  },
  //AUTO PEÇA 5
  {
    id: 5,
    name: '5',
    image: '../assets/images/caixa-gerency.png',
    title: <ProductTitle style={{ fontWeight: 'bold' }}>GERENCY ERP</ProductTitle>,
    titleText: 'GERENCY ERP',
    subTitle: <ProductSubTitle style={{ fontSize: '1.7rem', marginTop: '30px' }}>Auto Peças e Serviços</ProductSubTitle>,
    text: 
    <div>
      <ProductText style={{color: '#fff', fontSize: '1.4rem'}}>O aumento da concorrência e a diferenciação para atender clientes cada vez mais exigentes e antenados com as novidades do mercado, têm impulsionado os gestores a buscar Software para autopeças para ajudar a resolve estes desafios.</ProductText>
    </div>
    ,
    benefits: [
      {
        icon: <AiOutlineFundProjectionScreen size={48} />,
        text: 'Previsão Orçamentária e Fluxo de Caixa.'
      },
      {
        icon: <AiOutlineStock size={48} />,
        text: 'Gerenciamento de Plano de Contas e Centro de Custo.'
      },
      {
        icon: <GoCalendar size={48} />,
        text: 'Faça o acompanhamento mês a mês.'
      },
      {
        icon: <FaDollyFlatbed size={48} />,
        text: 'Maior controle sobre a rotatividade dos produtos.'
      },
      {
        icon: <GiReceiveMoney size={48} />,
        text: 'Menos desperdício de recursos.'
      },
      {
        icon: <AiFillCalculator size={48} />,
        text: 'Gestão e monitoramento da tributação.'
      },
      {
        icon: <GoChecklist size={48} />,
        text: 'Análises e estatísticas sobre a saúde do seu negócio.'
      },
      {
        icon: <GrNotes size={48} />,
        text: 'Emissão de NFCe, NFe, DFe e etc rápido e simples.'
      },
      {
        icon: <IoIosCalculator size={48} />,
        text: 'Cálculos automáticos de tributos.'
      },
      {
        icon: <GrValidate size={48} />,
        text: 'Verificação da real necessidade da aquisição'
      },
      {
        icon: <FaTruckLoading size={48} />,
        text: 'Histórico da Movimentação do Estoque.'
      },
      {
        icon: <GrDocumentConfig size={48} />,
        text: 'Gerenciamento e recebimento automático das compras.'
      },
    ],
    secondaryTitle: 'Gerency, mais que um ERP',
    // secondarySubtitle: 'Com o GerencyFinanceiro, você terá o controle sobre seus recebíveis e contas a pagar',
    secondaryText: <div>
      <p>O <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> permite o controle de receitas e despesas, assim como gerenciar com facilidade o fluxo de caixa, permitindo que você saiba de todos os recursos que entram ou saem da empresa.</p>
      <p>No <span style={{ fontWeight: 'bold' }}>estoque o Gerency</span> funciona como uma ferramenta de monitoramento de tudo que está disponível e permite que você programe melhor as compras, tornando-se parte importante do seu planejamento.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span>  você tem análise de dados para fidelização e o aumento da lucratividade, além, da troca de informações entre as áreas da empresa como: fiscal, administrativo, financeiro, produção e logística.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> tenha um sistema eficiente para fazer a cotação de serviços e produtos, reduzir o custo de aquisição da mercadoria para, então, praticar preços mais competitivos.</p>
    </div>,
    functionalitiesTitle: 'Recursos',
    functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
    functionalitiesItems: [
      'Controle de Clientes',
      'Controle de Fornecedores',
      'Contas Correntes',
      'Controle de Supervisores',
      'Controle de Cobradores',
      'Controle das Despesas',
      'Controle das Receitas',
      'Fluxo de Caixa',
      'Previsão Orçamentária',
      'Conciliação Bancária',
      'Contas Correntes',
      'Emissão de Boletos',
      'Gerenciamento de Cobranças',
      'Gerenciamentos de Cartões',
      'Gerenciamento do Plano de Contas',
      'Gerenciamento por Centro de Custo',
      'E muito mais...',
    ]
  },
  //MATERIAL DE CONSTRUÇÃO 6
  {
    id: 6,
    name: '6',
    image: '../assets/images/caixa-gerency.png',
    title: <ProductTitle style={{ fontWeight: 'bold' }}>GERENCY ERP</ProductTitle>,
    titleText: 'GERENCY ERP',
    subTitle: <ProductSubTitle style={{ fontSize: '1.7rem', marginTop: '30px' }}>Material de Construção</ProductSubTitle>,
    text: 
    <div>
      <ProductText style={{color: '#fff', fontSize: '1.4rem'}}>O Planejamento de Recursos Empresariais (ERP) é uma ferramenta indispensável para empresas de todos os portes que querem melhorar suas operações diárias e ganhar mais competitividade. Entre as vantagens de um sistema ERP para uma loja de materiais de construção estão a redução de erros humanos, padronização e modernização de diversas atividades do dia a dia.</ProductText>
    </div>
    ,
    benefits: [
      {
        icon: <AiOutlineFundProjectionScreen size={48} />,
        text: 'Previsão Orçamentária e Fluxo de Caixa.'
      },
      {
        icon: <AiOutlineStock size={48} />,
        text: 'Gerenciamento de Plano de Contas e Centro de Custo.'
      },
      {
        icon: <GoCalendar size={48} />,
        text: 'Faça o acompanhamento mês a mês.'
      },
      {
        icon: <FaDollyFlatbed size={48} />,
        text: 'Maior controle sobre a rotatividade dos produtos.'
      },
      {
        icon: <GiReceiveMoney size={48} />,
        text: 'Menos desperdício de recursos.'
      },
      {
        icon: <AiFillCalculator size={48} />,
        text: 'Gestão e monitoramento da tributação.'
      },
      {
        icon: <GoChecklist size={48} />,
        text: 'Análises e estatísticas sobre a saúde do seu negócio.'
      },
      {
        icon: <GrNotes size={48} />,
        text: 'Emissão de NFCe, NFe, DFe e etc rápido e simples.'
      },
      {
        icon: <IoIosCalculator size={48} />,
        text: 'Cálculos automáticos de tributos.'
      },
      {
        icon: <GrValidate size={48} />,
        text: 'Verificação da real necessidade da aquisição'
      },
      {
        icon: <FaTruckLoading size={48} />,
        text: 'Histórico da Movimentação do Estoque.'
      },
      {
        icon: <GrDocumentConfig size={48} />,
        text: 'Gerenciamento e recebimento automático das compras.'
      },
    ],
    secondaryTitle: 'Gerency, mais que um ERP',
    // secondarySubtitle: 'Com o GerencyFinanceiro, você terá o controle sobre seus recebíveis e contas a pagar',
    secondaryText: <div>
      <p>O <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> permite o controle de receitas e despesas, assim como gerenciar com facilidade o fluxo de caixa, permitindo que você saiba de todos os recursos que entram ou saem da empresa.</p>
      <p>No <span style={{ fontWeight: 'bold' }}>estoque o Gerency</span> funciona como uma ferramenta de monitoramento de tudo que está disponível e permite que você programe melhor as compras, tornando-se parte importante do seu planejamento.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span>  você tem análise de dados para fidelização e o aumento da lucratividade, além, da troca de informações entre as áreas da empresa como: fiscal, administrativo, financeiro, produção e logística.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> tenha um sistema eficiente para fazer a cotação de serviços e produtos, reduzir o custo de aquisição da mercadoria para, então, praticar preços mais competitivos.</p>
    </div>,
    functionalitiesTitle: 'Recursos',
    functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
    functionalitiesItems: [
      'Controle de Clientes',
      'Controle de Fornecedores',
      'Contas Correntes',
      'Controle de Supervisores',
      'Controle de Cobradores',
      'Controle das Despesas',
      'Controle das Receitas',
      'Fluxo de Caixa',
      'Previsão Orçamentária',
      'Conciliação Bancária',
      'Contas Correntes',
      'Emissão de Boletos',
      'Gerenciamento de Cobranças',
      'Gerenciamentos de Cartões',
      'Gerenciamento do Plano de Contas',
      'Gerenciamento por Centro de Custo',
      'E muito mais...',
    ]
  },
  //PADARIAS 7
  {
    id: 7,
    name: '7',
    image: '../assets/images/caixa-gerency.png',
    title: <ProductTitle style={{ fontWeight: 'bold' }}>GERENCY ERP</ProductTitle>,
    titleText: 'GERENCY ERP',
    subTitle: <ProductSubTitle style={{ fontSize: '1.7rem', marginTop: '30px' }}>Padarias e Confeitarias</ProductSubTitle>,
    text: 
    <div>
      <ProductText style={{color: '#fff', fontSize: '1.4rem'}}>Realize emissões de NFC-e diretamente do seu celular ou tablet, de uma forma simples, sem precisar gastar com outros equipamentos.</ProductText>
    </div>
    ,
    benefits: [
      {
        icon: <AiOutlineFundProjectionScreen size={48} />,
        text: 'Previsão Orçamentária e Fluxo de Caixa.'
      },
      {
        icon: <AiOutlineStock size={48} />,
        text: 'Gerenciamento de Plano de Contas e Centro de Custo.'
      },
      {
        icon: <GoCalendar size={48} />,
        text: 'Faça o acompanhamento mês a mês.'
      },
      {
        icon: <FaDollyFlatbed size={48} />,
        text: 'Maior controle sobre a rotatividade dos produtos.'
      },
      {
        icon: <GiReceiveMoney size={48} />,
        text: 'Menos desperdício de recursos.'
      },
      {
        icon: <AiFillCalculator size={48} />,
        text: 'Gestão e monitoramento da tributação.'
      },
      {
        icon: <GoChecklist size={48} />,
        text: 'Análises e estatísticas sobre a saúde do seu negócio.'
      },
      {
        icon: <GrNotes size={48} />,
        text: 'Emissão de NFCe, NFe, DFe e etc rápido e simples.'
      },
      {
        icon: <IoIosCalculator size={48} />,
        text: 'Cálculos automáticos de tributos.'
      },
      {
        icon: <GrValidate size={48} />,
        text: 'Verificação da real necessidade da aquisição'
      },
      {
        icon: <FaTruckLoading size={48} />,
        text: 'Histórico da Movimentação do Estoque.'
      },
      {
        icon: <GrDocumentConfig size={48} />,
        text: 'Gerenciamento e recebimento automático das compras.'
      },
    ],
    secondaryTitle: 'Gerency, mais que um ERP',
    // secondarySubtitle: 'Com o GerencyFinanceiro, você terá o controle sobre seus recebíveis e contas a pagar',
    secondaryText: <div>
      <p>O <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> permite o controle de receitas e despesas, assim como gerenciar com facilidade o fluxo de caixa, permitindo que você saiba de todos os recursos que entram ou saem da empresa.</p>
      <p>No <span style={{ fontWeight: 'bold' }}>estoque o Gerency</span> funciona como uma ferramenta de monitoramento de tudo que está disponível e permite que você programe melhor as compras, tornando-se parte importante do seu planejamento.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span>  você tem análise de dados para fidelização e o aumento da lucratividade, além, da troca de informações entre as áreas da empresa como: fiscal, administrativo, financeiro, produção e logística.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> tenha um sistema eficiente para fazer a cotação de serviços e produtos, reduzir o custo de aquisição da mercadoria para, então, praticar preços mais competitivos.</p>
    </div>,
    functionalitiesTitle: 'Recursos',
    functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
    functionalitiesItems: [
      'Controle de Clientes',
      'Controle de Fornecedores',
      'Contas Correntes',
      'Controle de Supervisores',
      'Controle de Cobradores',
      'Controle das Despesas',
      'Controle das Receitas',
      'Fluxo de Caixa',
      'Previsão Orçamentária',
      'Conciliação Bancária',
      'Contas Correntes',
      'Emissão de Boletos',
      'Gerenciamento de Cobranças',
      'Gerenciamentos de Cartões',
      'Gerenciamento do Plano de Contas',
      'Gerenciamento por Centro de Custo',
      'E muito mais...',
    ]
  },
  //SAPATARIAS 8
  {
    id: 8,
    name: '8',
    image: '../assets/images/caixa-gerency.png',
    title: <ProductTitle style={{ fontWeight: 'bold' }}>GERENCY ERP</ProductTitle>,
    titleText: 'GERENCY ERP',
    subTitle: <ProductSubTitle style={{ fontSize: '1.7rem', marginTop: '30px' }}>Sapatarias e Bolsas</ProductSubTitle>,
    text: 
    <div>
      <ProductText style={{color: '#fff', fontSize: '1.4rem'}}>Você precisa de uma ferramenta flexível e completa para lidar com o estoque, vendas, finanças, e a adequação fiscal?</ProductText>
    </div>
    ,
    benefits: [
      {
        icon: <AiOutlineFundProjectionScreen size={48} />,
        text: 'Previsão Orçamentária e Fluxo de Caixa.'
      },
      {
        icon: <AiOutlineStock size={48} />,
        text: 'Gerenciamento de Plano de Contas e Centro de Custo.'
      },
      {
        icon: <GoCalendar size={48} />,
        text: 'Faça o acompanhamento mês a mês.'
      },
      {
        icon: <FaDollyFlatbed size={48} />,
        text: 'Maior controle sobre a rotatividade dos produtos.'
      },
      {
        icon: <GiReceiveMoney size={48} />,
        text: 'Menos desperdício de recursos.'
      },
      {
        icon: <AiFillCalculator size={48} />,
        text: 'Gestão e monitoramento da tributação.'
      },
      {
        icon: <GoChecklist size={48} />,
        text: 'Análises e estatísticas sobre a saúde do seu negócio.'
      },
      {
        icon: <GrNotes size={48} />,
        text: 'Emissão de NFCe, NFe, DFe e etc rápido e simples.'
      },
      {
        icon: <IoIosCalculator size={48} />,
        text: 'Cálculos automáticos de tributos.'
      },
      {
        icon: <GrValidate size={48} />,
        text: 'Verificação da real necessidade da aquisição'
      },
      {
        icon: <FaTruckLoading size={48} />,
        text: 'Histórico da Movimentação do Estoque.'
      },
      {
        icon: <GrDocumentConfig size={48} />,
        text: 'Gerenciamento e recebimento automático das compras.'
      },
    ],
    secondaryTitle: 'Gerency, mais que um ERP',
    // secondarySubtitle: 'Com o GerencyFinanceiro, você terá o controle sobre seus recebíveis e contas a pagar',
    secondaryText: <div>
      <p>O <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> permite o controle de receitas e despesas, assim como gerenciar com facilidade o fluxo de caixa, permitindo que você saiba de todos os recursos que entram ou saem da empresa.</p>
      <p>No <span style={{ fontWeight: 'bold' }}>estoque o Gerency</span> funciona como uma ferramenta de monitoramento de tudo que está disponível e permite que você programe melhor as compras, tornando-se parte importante do seu planejamento.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span>  você tem análise de dados para fidelização e o aumento da lucratividade, além, da troca de informações entre as áreas da empresa como: fiscal, administrativo, financeiro, produção e logística.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> tenha um sistema eficiente para fazer a cotação de serviços e produtos, reduzir o custo de aquisição da mercadoria para, então, praticar preços mais competitivos.</p>
    </div>,
    functionalitiesTitle: 'Recursos',
    functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
    functionalitiesItems: [
      'Controle de Clientes',
      'Controle de Fornecedores',
      'Contas Correntes',
      'Controle de Supervisores',
      'Controle de Cobradores',
      'Controle das Despesas',
      'Controle das Receitas',
      'Fluxo de Caixa',
      'Previsão Orçamentária',
      'Conciliação Bancária',
      'Contas Correntes',
      'Emissão de Boletos',
      'Gerenciamento de Cobranças',
      'Gerenciamentos de Cartões',
      'Gerenciamento do Plano de Contas',
      'Gerenciamento por Centro de Custo',
      'E muito mais...',
    ]
  },
  //RESTAURANTES 9 
  {
    id: 9,
    name: '9',
    image: '../assets/images/caixa-gerency.png',
    title: <ProductTitle style={{ fontWeight: 'bold' }}>GERENCY ERP</ProductTitle>,
    titleText: 'GERENCY ERP',
    subTitle: <ProductSubTitle style={{ fontSize: '1.7rem', marginTop: '30px' }}>Restaurantes e Churrascarias</ProductSubTitle>,
    text: 
    <div>
      <ProductText style={{color: '#fff', fontSize: '1.4rem'}}>Nosso sistema é ideal para profissionalizar a gestão do estabelecimento. Com ele, é possível organizar melhor as finanças e otimizar a parte mais burocrática do trabalho, dando tempo para que os gerentes se ocupem com tarefas críticas da gestão.</ProductText>
    </div>
    ,
    benefits: [
      {
        icon: <AiOutlineFundProjectionScreen size={48} />,
        text: 'Previsão Orçamentária e Fluxo de Caixa.'
      },
      {
        icon: <AiOutlineStock size={48} />,
        text: 'Gerenciamento de Plano de Contas e Centro de Custo.'
      },
      {
        icon: <GoCalendar size={48} />,
        text: 'Faça o acompanhamento mês a mês.'
      },
      {
        icon: <FaDollyFlatbed size={48} />,
        text: 'Maior controle sobre a rotatividade dos produtos.'
      },
      {
        icon: <GiReceiveMoney size={48} />,
        text: 'Menos desperdício de recursos.'
      },
      {
        icon: <AiFillCalculator size={48} />,
        text: 'Gestão e monitoramento da tributação.'
      },
      {
        icon: <GoChecklist size={48} />,
        text: 'Análises e estatísticas sobre a saúde do seu negócio.'
      },
      {
        icon: <GrNotes size={48} />,
        text: 'Emissão de NFCe, NFe, DFe e etc rápido e simples.'
      },
      {
        icon: <IoIosCalculator size={48} />,
        text: 'Cálculos automáticos de tributos.'
      },
      {
        icon: <GrValidate size={48} />,
        text: 'Verificação da real necessidade da aquisição'
      },
      {
        icon: <FaTruckLoading size={48} />,
        text: 'Histórico da Movimentação do Estoque.'
      },
      {
        icon: <GrDocumentConfig size={48} />,
        text: 'Gerenciamento e recebimento automático das compras.'
      },
    ],
    secondaryTitle: 'Gerency, mais que um ERP',
    // secondarySubtitle: 'Com o GerencyFinanceiro, você terá o controle sobre seus recebíveis e contas a pagar',
    secondaryText: <div>
      <p>O <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> permite o controle de receitas e despesas, assim como gerenciar com facilidade o fluxo de caixa, permitindo que você saiba de todos os recursos que entram ou saem da empresa.</p>
      <p>No <span style={{ fontWeight: 'bold' }}>estoque o Gerency</span> funciona como uma ferramenta de monitoramento de tudo que está disponível e permite que você programe melhor as compras, tornando-se parte importante do seu planejamento.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span>  você tem análise de dados para fidelização e o aumento da lucratividade, além, da troca de informações entre as áreas da empresa como: fiscal, administrativo, financeiro, produção e logística.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> tenha um sistema eficiente para fazer a cotação de serviços e produtos, reduzir o custo de aquisição da mercadoria para, então, praticar preços mais competitivos.</p>
    </div>,
    functionalitiesTitle: 'Recursos',
    functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
    functionalitiesItems: [
      'Controle de Clientes',
      'Controle de Fornecedores',
      'Contas Correntes',
      'Controle de Supervisores',
      'Controle de Cobradores',
      'Controle das Despesas',
      'Controle das Receitas',
      'Fluxo de Caixa',
      'Previsão Orçamentária',
      'Conciliação Bancária',
      'Contas Correntes',
      'Emissão de Boletos',
      'Gerenciamento de Cobranças',
      'Gerenciamentos de Cartões',
      'Gerenciamento do Plano de Contas',
      'Gerenciamento por Centro de Custo',
      'E muito mais...',
    ]
  },
  //MÓVEIS 10 
  {
    id: 10,
    name: '10',
    image: '../assets/images/caixa-gerency.png',
    title: <ProductTitle style={{ fontWeight: 'bold' }}>GERENCY ERP</ProductTitle>,
    titleText: 'GERENCY ERP',
    subTitle: <ProductSubTitle style={{ fontSize: '1.7rem', marginTop: '30px' }}>Móveis e Eletrodomésticos</ProductSubTitle>,
    text: 
    <div>
      <ProductText style={{color: '#fff', fontSize: '1.4rem'}}>Você precisa de uma ferramenta flexível e completa para lidar com o estoque, vendas, finanças, e a adequação fiscal?</ProductText>
    </div>
    ,
    benefits: [
      {
        icon: <AiOutlineFundProjectionScreen size={48} />,
        text: 'Previsão Orçamentária e Fluxo de Caixa.'
      },
      {
        icon: <AiOutlineStock size={48} />,
        text: 'Gerenciamento de Plano de Contas e Centro de Custo.'
      },
      {
        icon: <GoCalendar size={48} />,
        text: 'Faça o acompanhamento mês a mês.'
      },
      {
        icon: <FaDollyFlatbed size={48} />,
        text: 'Maior controle sobre a rotatividade dos produtos.'
      },
      {
        icon: <GiReceiveMoney size={48} />,
        text: 'Menos desperdício de recursos.'
      },
      {
        icon: <AiFillCalculator size={48} />,
        text: 'Gestão e monitoramento da tributação.'
      },
      {
        icon: <GoChecklist size={48} />,
        text: 'Análises e estatísticas sobre a saúde do seu negócio.'
      },
      {
        icon: <GrNotes size={48} />,
        text: 'Emissão de NFCe, NFe, DFe e etc rápido e simples.'
      },
      {
        icon: <IoIosCalculator size={48} />,
        text: 'Cálculos automáticos de tributos.'
      },
      {
        icon: <GrValidate size={48} />,
        text: 'Verificação da real necessidade da aquisição'
      },
      {
        icon: <FaTruckLoading size={48} />,
        text: 'Histórico da Movimentação do Estoque.'
      },
      {
        icon: <GrDocumentConfig size={48} />,
        text: 'Gerenciamento e recebimento automático das compras.'
      },
    ],
    secondaryTitle: 'Gerency, mais que um ERP',
    // secondarySubtitle: 'Com o GerencyFinanceiro, você terá o controle sobre seus recebíveis e contas a pagar',
    secondaryText: <div>
      <p>O <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> permite o controle de receitas e despesas, assim como gerenciar com facilidade o fluxo de caixa, permitindo que você saiba de todos os recursos que entram ou saem da empresa.</p>
      <p>No <span style={{ fontWeight: 'bold' }}>estoque o Gerency</span> funciona como uma ferramenta de monitoramento de tudo que está disponível e permite que você programe melhor as compras, tornando-se parte importante do seu planejamento.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span>  você tem análise de dados para fidelização e o aumento da lucratividade, além, da troca de informações entre as áreas da empresa como: fiscal, administrativo, financeiro, produção e logística.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> tenha um sistema eficiente para fazer a cotação de serviços e produtos, reduzir o custo de aquisição da mercadoria para, então, praticar preços mais competitivos.</p>
    </div>,
    functionalitiesTitle: 'Recursos',
    functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
    functionalitiesItems: [
      'Controle de Clientes',
      'Controle de Fornecedores',
      'Contas Correntes',
      'Controle de Supervisores',
      'Controle de Cobradores',
      'Controle das Despesas',
      'Controle das Receitas',
      'Fluxo de Caixa',
      'Previsão Orçamentária',
      'Conciliação Bancária',
      'Contas Correntes',
      'Emissão de Boletos',
      'Gerenciamento de Cobranças',
      'Gerenciamentos de Cartões',
      'Gerenciamento do Plano de Contas',
      'Gerenciamento por Centro de Custo',
      'E muito mais...',
    ]
  },
  //VESTUÁRIO 11
  {
    id: 11,
    name: '11',
    image: '../assets/images/caixa-gerency.png',
    title: <ProductTitle style={{ fontWeight: 'bold' }}>GERENCY ERP</ProductTitle>,
    titleText: 'GERENCY ERP',
    subTitle: <ProductSubTitle style={{ fontSize: '1.7rem', marginTop: '30px' }}>Vestuário e Acessórios</ProductSubTitle>,
    text: 
    <div>
      <ProductText style={{color: '#fff', fontSize: '1.4rem'}}>O ERP é ideal para lojas de roupa, acessórios e calçados pois agilizam o atendimento e o processamento de dados e consequentemente aumenta a rentabilidade e a melhora a experiência de satisfação do cliente no atendimento.</ProductText>
    </div>
    ,
    benefits: [
      {
        icon: <AiOutlineFundProjectionScreen size={48} />,
        text: 'Previsão Orçamentária e Fluxo de Caixa.'
      },
      {
        icon: <AiOutlineStock size={48} />,
        text: 'Gerenciamento de Plano de Contas e Centro de Custo.'
      },
      {
        icon: <GoCalendar size={48} />,
        text: 'Faça o acompanhamento mês a mês.'
      },
      {
        icon: <FaDollyFlatbed size={48} />,
        text: 'Maior controle sobre a rotatividade dos produtos.'
      },
      {
        icon: <GiReceiveMoney size={48} />,
        text: 'Menos desperdício de recursos.'
      },
      {
        icon: <AiFillCalculator size={48} />,
        text: 'Gestão e monitoramento da tributação.'
      },
      {
        icon: <GoChecklist size={48} />,
        text: 'Análises e estatísticas sobre a saúde do seu negócio.'
      },
      {
        icon: <GrNotes size={48} />,
        text: 'Emissão de NFCe, NFe, DFe e etc rápido e simples.'
      },
      {
        icon: <IoIosCalculator size={48} />,
        text: 'Cálculos automáticos de tributos.'
      },
      {
        icon: <GrValidate size={48} />,
        text: 'Verificação da real necessidade da aquisição'
      },
      {
        icon: <FaTruckLoading size={48} />,
        text: 'Histórico da Movimentação do Estoque.'
      },
      {
        icon: <GrDocumentConfig size={48} />,
        text: 'Gerenciamento e recebimento automático das compras.'
      },
    ],
    secondaryTitle: 'Gerency, mais que um ERP',
    // secondarySubtitle: 'Com o GerencyFinanceiro, você terá o controle sobre seus recebíveis e contas a pagar',
    secondaryText: <div>
      <p>O <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> permite o controle de receitas e despesas, assim como gerenciar com facilidade o fluxo de caixa, permitindo que você saiba de todos os recursos que entram ou saem da empresa.</p>
      <p>No <span style={{ fontWeight: 'bold' }}>estoque o Gerency</span> funciona como uma ferramenta de monitoramento de tudo que está disponível e permite que você programe melhor as compras, tornando-se parte importante do seu planejamento.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span>  você tem análise de dados para fidelização e o aumento da lucratividade, além, da troca de informações entre as áreas da empresa como: fiscal, administrativo, financeiro, produção e logística.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> tenha um sistema eficiente para fazer a cotação de serviços e produtos, reduzir o custo de aquisição da mercadoria para, então, praticar preços mais competitivos.</p>
    </div>,
    functionalitiesTitle: 'Recursos',
    functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
    functionalitiesItems: [
      'Controle de Clientes',
      'Controle de Fornecedores',
      'Contas Correntes',
      'Controle de Supervisores',
      'Controle de Cobradores',
      'Controle das Despesas',
      'Controle das Receitas',
      'Fluxo de Caixa',
      'Previsão Orçamentária',
      'Conciliação Bancária',
      'Contas Correntes',
      'Emissão de Boletos',
      'Gerenciamento de Cobranças',
      'Gerenciamentos de Cartões',
      'Gerenciamento do Plano de Contas',
      'Gerenciamento por Centro de Custo',
      'E muito mais...',
    ]
  },
  //COVENIÊNCIA 12
  {
    id: 12,
    name: '12',
    image: '../assets/images/caixa-gerency.png',
    title: <ProductTitle style={{ fontWeight: 'bold' }}>GERENCY ERP</ProductTitle>,
    titleText: 'GERENCY ERP',
    subTitle: <ProductSubTitle style={{ fontSize: '1.7rem', marginTop: '30px' }}>Conveniência e Drugstore</ProductSubTitle>,
    text: 
    <div>
      <ProductText style={{color: '#fff', fontSize: '1.4rem'}}>Você precisa de uma ferramenta flexível e completa para lidar com o estoque, vendas, finanças, e a adequação fiscal?</ProductText>
    </div>
    ,
    benefits: [
      {
        icon: <AiOutlineFundProjectionScreen size={48} />,
        text: 'Previsão Orçamentária e Fluxo de Caixa.'
      },
      {
        icon: <AiOutlineStock size={48} />,
        text: 'Gerenciamento de Plano de Contas e Centro de Custo.'
      },
      {
        icon: <GoCalendar size={48} />,
        text: 'Faça o acompanhamento mês a mês.'
      },
      {
        icon: <FaDollyFlatbed size={48} />,
        text: 'Maior controle sobre a rotatividade dos produtos.'
      },
      {
        icon: <GiReceiveMoney size={48} />,
        text: 'Menos desperdício de recursos.'
      },
      {
        icon: <AiFillCalculator size={48} />,
        text: 'Gestão e monitoramento da tributação.'
      },
      {
        icon: <GoChecklist size={48} />,
        text: 'Análises e estatísticas sobre a saúde do seu negócio.'
      },
      {
        icon: <GrNotes size={48} />,
        text: 'Emissão de NFCe, NFe, DFe e etc rápido e simples.'
      },
      {
        icon: <IoIosCalculator size={48} />,
        text: 'Cálculos automáticos de tributos.'
      },
      {
        icon: <GrValidate size={48} />,
        text: 'Verificação da real necessidade da aquisição'
      },
      {
        icon: <FaTruckLoading size={48} />,
        text: 'Histórico da Movimentação do Estoque.'
      },
      {
        icon: <GrDocumentConfig size={48} />,
        text: 'Gerenciamento e recebimento automático das compras.'
      },
    ],
    secondaryTitle: 'Gerency, mais que um ERP',
    // secondarySubtitle: 'Com o GerencyFinanceiro, você terá o controle sobre seus recebíveis e contas a pagar',
    secondaryText: <div>
      <p>O <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> permite o controle de receitas e despesas, assim como gerenciar com facilidade o fluxo de caixa, permitindo que você saiba de todos os recursos que entram ou saem da empresa.</p>
      <p>No <span style={{ fontWeight: 'bold' }}>estoque o Gerency</span> funciona como uma ferramenta de monitoramento de tudo que está disponível e permite que você programe melhor as compras, tornando-se parte importante do seu planejamento.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span>  você tem análise de dados para fidelização e o aumento da lucratividade, além, da troca de informações entre as áreas da empresa como: fiscal, administrativo, financeiro, produção e logística.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> tenha um sistema eficiente para fazer a cotação de serviços e produtos, reduzir o custo de aquisição da mercadoria para, então, praticar preços mais competitivos.</p>
    </div>,
    functionalitiesTitle: 'Recursos',
    functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
    functionalitiesItems: [
      'Controle de Clientes',
      'Controle de Fornecedores',
      'Contas Correntes',
      'Controle de Supervisores',
      'Controle de Cobradores',
      'Controle das Despesas',
      'Controle das Receitas',
      'Fluxo de Caixa',
      'Previsão Orçamentária',
      'Conciliação Bancária',
      'Contas Correntes',
      'Emissão de Boletos',
      'Gerenciamento de Cobranças',
      'Gerenciamentos de Cartões',
      'Gerenciamento do Plano de Contas',
      'Gerenciamento por Centro de Custo',
      'E muito mais...',
    ]
  },
  //CAMA, MESA E BANHO 13
  {
    id: 13,
    name: '13',
    image: '../assets/images/caixa-gerency.png',
    title: <ProductTitle style={{ fontWeight: 'bold' }}>GERENCY ERP</ProductTitle>,
    titleText: 'GERENCY ERP',
    subTitle: <ProductSubTitle style={{ fontSize: '1.7rem', marginTop: '30px' }}>Cama, Mesa e Banho</ProductSubTitle>,
    text: 
    <div>
      <ProductText style={{color: '#fff', fontSize: '1.4rem'}}>Você precisa de uma ferramenta flexível e completa para lidar com o estoque, vendas, finanças, e a adequação fiscal?</ProductText>
    </div>
    ,
    benefits: [
      {
        icon: <AiOutlineFundProjectionScreen size={48} />,
        text: 'Previsão Orçamentária e Fluxo de Caixa.'
      },
      {
        icon: <AiOutlineStock size={48} />,
        text: 'Gerenciamento de Plano de Contas e Centro de Custo.'
      },
      {
        icon: <GoCalendar size={48} />,
        text: 'Faça o acompanhamento mês a mês.'
      },
      {
        icon: <FaDollyFlatbed size={48} />,
        text: 'Maior controle sobre a rotatividade dos produtos.'
      },
      {
        icon: <GiReceiveMoney size={48} />,
        text: 'Menos desperdício de recursos.'
      },
      {
        icon: <AiFillCalculator size={48} />,
        text: 'Gestão e monitoramento da tributação.'
      },
      {
        icon: <GoChecklist size={48} />,
        text: 'Análises e estatísticas sobre a saúde do seu negócio.'
      },
      {
        icon: <GrNotes size={48} />,
        text: 'Emissão de NFCe, NFe, DFe e etc rápido e simples.'
      },
      {
        icon: <IoIosCalculator size={48} />,
        text: 'Cálculos automáticos de tributos.'
      },
      {
        icon: <GrValidate size={48} />,
        text: 'Verificação da real necessidade da aquisição'
      },
      {
        icon: <FaTruckLoading size={48} />,
        text: 'Histórico da Movimentação do Estoque.'
      },
      {
        icon: <GrDocumentConfig size={48} />,
        text: 'Gerenciamento e recebimento automático das compras.'
      },
    ],
    secondaryTitle: 'Gerency, mais que um ERP',
    // secondarySubtitle: 'Com o GerencyFinanceiro, você terá o controle sobre seus recebíveis e contas a pagar',
    secondaryText: <div>
      <p>O <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> permite o controle de receitas e despesas, assim como gerenciar com facilidade o fluxo de caixa, permitindo que você saiba de todos os recursos que entram ou saem da empresa.</p>
      <p>No <span style={{ fontWeight: 'bold' }}>estoque o Gerency</span> funciona como uma ferramenta de monitoramento de tudo que está disponível e permite que você programe melhor as compras, tornando-se parte importante do seu planejamento.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span>  você tem análise de dados para fidelização e o aumento da lucratividade, além, da troca de informações entre as áreas da empresa como: fiscal, administrativo, financeiro, produção e logística.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> tenha um sistema eficiente para fazer a cotação de serviços e produtos, reduzir o custo de aquisição da mercadoria para, então, praticar preços mais competitivos.</p>
    </div>,
    functionalitiesTitle: 'Recursos',
    functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
    functionalitiesItems: [
      'Controle de Clientes',
      'Controle de Fornecedores',
      'Contas Correntes',
      'Controle de Supervisores',
      'Controle de Cobradores',
      'Controle das Despesas',
      'Controle das Receitas',
      'Fluxo de Caixa',
      'Previsão Orçamentária',
      'Conciliação Bancária',
      'Contas Correntes',
      'Emissão de Boletos',
      'Gerenciamento de Cobranças',
      'Gerenciamentos de Cartões',
      'Gerenciamento do Plano de Contas',
      'Gerenciamento por Centro de Custo',
      'E muito mais...',
    ]
  },
  //FOODS 14
  {
    id: 14,
    name: '14',
    image: '../assets/images/caixa-gerency.png',
    title: <ProductTitle style={{ fontWeight: 'bold' }}>GERENCY ERP</ProductTitle>,
    titleText: 'GERENCY ERP',
    subTitle: <ProductSubTitle style={{ fontSize: '1.7rem', marginTop: '30px' }}>Food Service e Fast Food</ProductSubTitle>,
    text: 
    <div>
      <ProductText style={{color: '#fff', fontSize: '1.4rem'}}>A importância de um bom ERP está ligado principalmente aos fatos de automatizar processos, registrar informações e gerar meios de mensurar resultados de maneira mais fácil. Em outras palavras, eles ajudam você a padronizar processos do dia a dia, tornando-os mais simples com economia de tempo e recursos.</ProductText>
    </div>
    ,
    benefits: [
      {
        icon: <AiOutlineFundProjectionScreen size={48} />,
        text: 'Previsão Orçamentária e Fluxo de Caixa.'
      },
      {
        icon: <AiOutlineStock size={48} />,
        text: 'Gerenciamento de Plano de Contas e Centro de Custo.'
      },
      {
        icon: <GoCalendar size={48} />,
        text: 'Faça o acompanhamento mês a mês.'
      },
      {
        icon: <FaDollyFlatbed size={48} />,
        text: 'Maior controle sobre a rotatividade dos produtos.'
      },
      {
        icon: <GiReceiveMoney size={48} />,
        text: 'Menos desperdício de recursos.'
      },
      {
        icon: <AiFillCalculator size={48} />,
        text: 'Gestão e monitoramento da tributação.'
      },
      {
        icon: <GoChecklist size={48} />,
        text: 'Análises e estatísticas sobre a saúde do seu negócio.'
      },
      {
        icon: <GrNotes size={48} />,
        text: 'Emissão de NFCe, NFe, DFe e etc rápido e simples.'
      },
      {
        icon: <IoIosCalculator size={48} />,
        text: 'Cálculos automáticos de tributos.'
      },
      {
        icon: <GrValidate size={48} />,
        text: 'Verificação da real necessidade da aquisição'
      },
      {
        icon: <FaTruckLoading size={48} />,
        text: 'Histórico da Movimentação do Estoque.'
      },
      {
        icon: <GrDocumentConfig size={48} />,
        text: 'Gerenciamento e recebimento automático das compras.'
      },
    ],
    secondaryTitle: 'Gerency, mais que um ERP',
    // secondarySubtitle: 'Com o GerencyFinanceiro, você terá o controle sobre seus recebíveis e contas a pagar',
    secondaryText: <div>
      <p>O <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> permite o controle de receitas e despesas, assim como gerenciar com facilidade o fluxo de caixa, permitindo que você saiba de todos os recursos que entram ou saem da empresa.</p>
      <p>No <span style={{ fontWeight: 'bold' }}>estoque o Gerency</span> funciona como uma ferramenta de monitoramento de tudo que está disponível e permite que você programe melhor as compras, tornando-se parte importante do seu planejamento.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span>  você tem análise de dados para fidelização e o aumento da lucratividade, além, da troca de informações entre as áreas da empresa como: fiscal, administrativo, financeiro, produção e logística.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> tenha um sistema eficiente para fazer a cotação de serviços e produtos, reduzir o custo de aquisição da mercadoria para, então, praticar preços mais competitivos.</p>
    </div>,
    functionalitiesTitle: 'Recursos',
    functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
    functionalitiesItems: [
      'Controle de Clientes',
      'Controle de Fornecedores',
      'Contas Correntes',
      'Controle de Supervisores',
      'Controle de Cobradores',
      'Controle das Despesas',
      'Controle das Receitas',
      'Fluxo de Caixa',
      'Previsão Orçamentária',
      'Conciliação Bancária',
      'Contas Correntes',
      'Emissão de Boletos',
      'Gerenciamento de Cobranças',
      'Gerenciamentos de Cartões',
      'Gerenciamento do Plano de Contas',
      'Gerenciamento por Centro de Custo',
      'E muito mais...',
    ]
  },
  //MAGAZINES 15
  {
    id: 15,
    name: '15',
    image: '../assets/images/caixa-gerency.png',
    title: <ProductTitle style={{ fontWeight: 'bold' }}>GERENCY ERP</ProductTitle>,
    titleText: 'GERENCY ERP',
    subTitle: <ProductSubTitle style={{ fontSize: '1.7rem', marginTop: '30px' }}>Magazines e Utilidades</ProductSubTitle>,
    text: 
    <div>
      <ProductText style={{color: '#fff', fontSize: '1.4rem'}}>Você precisa de uma ferramenta flexível e completa para lidar com o estoque, vendas, finanças, e a adequação fiscal?</ProductText>
    </div>
    ,
    benefits: [
      {
        icon: <AiOutlineFundProjectionScreen size={48} />,
        text: 'Previsão Orçamentária e Fluxo de Caixa.'
      },
      {
        icon: <AiOutlineStock size={48} />,
        text: 'Gerenciamento de Plano de Contas e Centro de Custo.'
      },
      {
        icon: <GoCalendar size={48} />,
        text: 'Faça o acompanhamento mês a mês.'
      },
      {
        icon: <FaDollyFlatbed size={48} />,
        text: 'Maior controle sobre a rotatividade dos produtos.'
      },
      {
        icon: <GiReceiveMoney size={48} />,
        text: 'Menos desperdício de recursos.'
      },
      {
        icon: <AiFillCalculator size={48} />,
        text: 'Gestão e monitoramento da tributação.'
      },
      {
        icon: <GoChecklist size={48} />,
        text: 'Análises e estatísticas sobre a saúde do seu negócio.'
      },
      {
        icon: <GrNotes size={48} />,
        text: 'Emissão de NFCe, NFe, DFe e etc rápido e simples.'
      },
      {
        icon: <IoIosCalculator size={48} />,
        text: 'Cálculos automáticos de tributos.'
      },
      {
        icon: <GrValidate size={48} />,
        text: 'Verificação da real necessidade da aquisição'
      },
      {
        icon: <FaTruckLoading size={48} />,
        text: 'Histórico da Movimentação do Estoque.'
      },
      {
        icon: <GrDocumentConfig size={48} />,
        text: 'Gerenciamento e recebimento automático das compras.'
      },
    ],
    secondaryTitle: 'Gerency, mais que um ERP',
    // secondarySubtitle: 'Com o GerencyFinanceiro, você terá o controle sobre seus recebíveis e contas a pagar',
    secondaryText: <div>
      <p>O <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> permite o controle de receitas e despesas, assim como gerenciar com facilidade o fluxo de caixa, permitindo que você saiba de todos os recursos que entram ou saem da empresa.</p>
      <p>No <span style={{ fontWeight: 'bold' }}>estoque o Gerency</span> funciona como uma ferramenta de monitoramento de tudo que está disponível e permite que você programe melhor as compras, tornando-se parte importante do seu planejamento.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span>  você tem análise de dados para fidelização e o aumento da lucratividade, além, da troca de informações entre as áreas da empresa como: fiscal, administrativo, financeiro, produção e logística.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> tenha um sistema eficiente para fazer a cotação de serviços e produtos, reduzir o custo de aquisição da mercadoria para, então, praticar preços mais competitivos.</p>
    </div>,
    functionalitiesTitle: 'Recursos',
    functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
    functionalitiesItems: [
      'Controle de Clientes',
      'Controle de Fornecedores',
      'Contas Correntes',
      'Controle de Supervisores',
      'Controle de Cobradores',
      'Controle das Despesas',
      'Controle das Receitas',
      'Fluxo de Caixa',
      'Previsão Orçamentária',
      'Conciliação Bancária',
      'Contas Correntes',
      'Emissão de Boletos',
      'Gerenciamento de Cobranças',
      'Gerenciamentos de Cartões',
      'Gerenciamento do Plano de Contas',
      'Gerenciamento por Centro de Custo',
      'E muito mais...',
    ]
  },
  //PERFUMARIA 16
  {
    id: 16,
    name: '16',
    image: '../assets/images/caixa-gerency.png',
    title: <ProductTitle style={{ fontWeight: 'bold' }}>GERENCY ERP</ProductTitle>,
    titleText: 'GERENCY ERP',
    subTitle: <ProductSubTitle style={{ fontSize: '1.7rem', marginTop: '30px' }}>Perfumaria e Cosméticos</ProductSubTitle>,
    text: 
    <div>
      <ProductText style={{color: '#fff', fontSize: '1.4rem'}}>Você precisa de uma ferramenta flexível e completa para lidar com o estoque, vendas, finanças, e a adequação fiscal?</ProductText>
    </div>
    ,
    benefits: [
      {
        icon: <AiOutlineFundProjectionScreen size={48} />,
        text: 'Previsão Orçamentária e Fluxo de Caixa.'
      },
      {
        icon: <AiOutlineStock size={48} />,
        text: 'Gerenciamento de Plano de Contas e Centro de Custo.'
      },
      {
        icon: <GoCalendar size={48} />,
        text: 'Faça o acompanhamento mês a mês.'
      },
      {
        icon: <FaDollyFlatbed size={48} />,
        text: 'Maior controle sobre a rotatividade dos produtos.'
      },
      {
        icon: <GiReceiveMoney size={48} />,
        text: 'Menos desperdício de recursos.'
      },
      {
        icon: <AiFillCalculator size={48} />,
        text: 'Gestão e monitoramento da tributação.'
      },
      {
        icon: <GoChecklist size={48} />,
        text: 'Análises e estatísticas sobre a saúde do seu negócio.'
      },
      {
        icon: <GrNotes size={48} />,
        text: 'Emissão de NFCe, NFe, DFe e etc rápido e simples.'
      },
      {
        icon: <IoIosCalculator size={48} />,
        text: 'Cálculos automáticos de tributos.'
      },
      {
        icon: <GrValidate size={48} />,
        text: 'Verificação da real necessidade da aquisição'
      },
      {
        icon: <FaTruckLoading size={48} />,
        text: 'Histórico da Movimentação do Estoque.'
      },
      {
        icon: <GrDocumentConfig size={48} />,
        text: 'Gerenciamento e recebimento automático das compras.'
      },
    ],
    secondaryTitle: 'Gerency, mais que um ERP',
    // secondarySubtitle: 'Com o GerencyFinanceiro, você terá o controle sobre seus recebíveis e contas a pagar',
    secondaryText: <div>
      <p>O <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> permite o controle de receitas e despesas, assim como gerenciar com facilidade o fluxo de caixa, permitindo que você saiba de todos os recursos que entram ou saem da empresa.</p>
      <p>No <span style={{ fontWeight: 'bold' }}>estoque o Gerency</span> funciona como uma ferramenta de monitoramento de tudo que está disponível e permite que você programe melhor as compras, tornando-se parte importante do seu planejamento.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span>  você tem análise de dados para fidelização e o aumento da lucratividade, além, da troca de informações entre as áreas da empresa como: fiscal, administrativo, financeiro, produção e logística.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> tenha um sistema eficiente para fazer a cotação de serviços e produtos, reduzir o custo de aquisição da mercadoria para, então, praticar preços mais competitivos.</p>
    </div>,
    functionalitiesTitle: 'Recursos',
    functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
    functionalitiesItems: [
      'Controle de Clientes',
      'Controle de Fornecedores',
      'Contas Correntes',
      'Controle de Supervisores',
      'Controle de Cobradores',
      'Controle das Despesas',
      'Controle das Receitas',
      'Fluxo de Caixa',
      'Previsão Orçamentária',
      'Conciliação Bancária',
      'Contas Correntes',
      'Emissão de Boletos',
      'Gerenciamento de Cobranças',
      'Gerenciamentos de Cartões',
      'Gerenciamento do Plano de Contas',
      'Gerenciamento por Centro de Custo',
      'E muito mais...',
    ]
  },
  //LIVRARIA 17
  {
    id: 17,
    name: '17',
    image: '../assets/images/caixa-gerency.png',
    title: <ProductTitle style={{ fontWeight: 'bold' }}>GERENCY ERP</ProductTitle>,
    titleText: 'GERENCY ERP',
    subTitle: <ProductSubTitle style={{ fontSize: '1.7rem', marginTop: '30px' }}>Livrarias e Papelarias</ProductSubTitle>,
    text: 
    <div>
      <ProductText style={{color: '#fff', fontSize: '1.4rem'}}>Você precisa de uma ferramenta flexível e completa para lidar com o estoque, vendas, finanças, e a adequação fiscal?</ProductText>
    </div>
    ,
    benefits: [
      {
        icon: <AiOutlineFundProjectionScreen size={48} />,
        text: 'Previsão Orçamentária e Fluxo de Caixa.'
      },
      {
        icon: <AiOutlineStock size={48} />,
        text: 'Gerenciamento de Plano de Contas e Centro de Custo.'
      },
      {
        icon: <GoCalendar size={48} />,
        text: 'Faça o acompanhamento mês a mês.'
      },
      {
        icon: <FaDollyFlatbed size={48} />,
        text: 'Maior controle sobre a rotatividade dos produtos.'
      },
      {
        icon: <GiReceiveMoney size={48} />,
        text: 'Menos desperdício de recursos.'
      },
      {
        icon: <AiFillCalculator size={48} />,
        text: 'Gestão e monitoramento da tributação.'
      },
      {
        icon: <GoChecklist size={48} />,
        text: 'Análises e estatísticas sobre a saúde do seu negócio.'
      },
      {
        icon: <GrNotes size={48} />,
        text: 'Emissão de NFCe, NFe, DFe e etc rápido e simples.'
      },
      {
        icon: <IoIosCalculator size={48} />,
        text: 'Cálculos automáticos de tributos.'
      },
      {
        icon: <GrValidate size={48} />,
        text: 'Verificação da real necessidade da aquisição'
      },
      {
        icon: <FaTruckLoading size={48} />,
        text: 'Histórico da Movimentação do Estoque.'
      },
      {
        icon: <GrDocumentConfig size={48} />,
        text: 'Gerenciamento e recebimento automático das compras.'
      },
    ],
    secondaryTitle: 'Gerency, mais que um ERP',
    // secondarySubtitle: 'Com o GerencyFinanceiro, você terá o controle sobre seus recebíveis e contas a pagar',
    secondaryText: <div>
      <p>O <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> permite o controle de receitas e despesas, assim como gerenciar com facilidade o fluxo de caixa, permitindo que você saiba de todos os recursos que entram ou saem da empresa.</p>
      <p>No <span style={{ fontWeight: 'bold' }}>estoque o Gerency</span> funciona como uma ferramenta de monitoramento de tudo que está disponível e permite que você programe melhor as compras, tornando-se parte importante do seu planejamento.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span>  você tem análise de dados para fidelização e o aumento da lucratividade, além, da troca de informações entre as áreas da empresa como: fiscal, administrativo, financeiro, produção e logística.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> tenha um sistema eficiente para fazer a cotação de serviços e produtos, reduzir o custo de aquisição da mercadoria para, então, praticar preços mais competitivos.</p>
    </div>,
    functionalitiesTitle: 'Recursos',
    functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
    functionalitiesItems: [
      'Controle de Clientes',
      'Controle de Fornecedores',
      'Contas Correntes',
      'Controle de Supervisores',
      'Controle de Cobradores',
      'Controle das Despesas',
      'Controle das Receitas',
      'Fluxo de Caixa',
      'Previsão Orçamentária',
      'Conciliação Bancária',
      'Contas Correntes',
      'Emissão de Boletos',
      'Gerenciamento de Cobranças',
      'Gerenciamentos de Cartões',
      'Gerenciamento do Plano de Contas',
      'Gerenciamento por Centro de Custo',
      'E muito mais...',
    ]
  },
  //ATACADO 18
  {
    id: 18,
    name: '18',
    image: '../assets/images/caixa-gerency.png',
    title: <ProductTitle style={{ fontWeight: 'bold' }}>GERENCY ERP</ProductTitle>,
    titleText: 'GERENCY ERP',
    subTitle: <ProductSubTitle style={{ fontSize: '1.7rem', marginTop: '30px' }}>Atacado</ProductSubTitle>,
    text: 
    <div>
      <ProductText style={{color: '#fff', fontSize: '1.4rem'}}>Atendendo as necessidades inerentes ao negócio, um sistema ERP leva as informações gerenciais para a palma da sua mão, proporcionando mais agilidade na tomada de decisão, com informações claras e precisas, aumentando a sua competitividade e identificado as melhores oportunidades de mercado.</ProductText>
    </div>
    ,
    benefits: [
      {
        icon: <AiOutlineFundProjectionScreen size={48} />,
        text: 'Previsão Orçamentária e Fluxo de Caixa.'
      },
      {
        icon: <AiOutlineStock size={48} />,
        text: 'Gerenciamento de Plano de Contas e Centro de Custo.'
      },
      {
        icon: <GoCalendar size={48} />,
        text: 'Faça o acompanhamento mês a mês.'
      },
      {
        icon: <FaDollyFlatbed size={48} />,
        text: 'Maior controle sobre a rotatividade dos produtos.'
      },
      {
        icon: <GiReceiveMoney size={48} />,
        text: 'Menos desperdício de recursos.'
      },
      {
        icon: <AiFillCalculator size={48} />,
        text: 'Gestão e monitoramento da tributação.'
      },
      {
        icon: <GoChecklist size={48} />,
        text: 'Análises e estatísticas sobre a saúde do seu negócio.'
      },
      {
        icon: <GrNotes size={48} />,
        text: 'Emissão de NFCe, NFe, DFe e etc rápido e simples.'
      },
      {
        icon: <IoIosCalculator size={48} />,
        text: 'Cálculos automáticos de tributos.'
      },
      {
        icon: <GrValidate size={48} />,
        text: 'Verificação da real necessidade da aquisição'
      },
      {
        icon: <FaTruckLoading size={48} />,
        text: 'Histórico da Movimentação do Estoque.'
      },
      {
        icon: <GrDocumentConfig size={48} />,
        text: 'Gerenciamento e recebimento automático das compras.'
      },
    ],
    secondaryTitle: 'Gerency, mais que um ERP',
    // secondarySubtitle: 'Com o GerencyFinanceiro, você terá o controle sobre seus recebíveis e contas a pagar',
    secondaryText: <div>
      <p>O <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> permite o controle de receitas e despesas, assim como gerenciar com facilidade o fluxo de caixa, permitindo que você saiba de todos os recursos que entram ou saem da empresa.</p>
      <p>No <span style={{ fontWeight: 'bold' }}>estoque o Gerency</span> funciona como uma ferramenta de monitoramento de tudo que está disponível e permite que você programe melhor as compras, tornando-se parte importante do seu planejamento.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span>  você tem análise de dados para fidelização e o aumento da lucratividade, além, da troca de informações entre as áreas da empresa como: fiscal, administrativo, financeiro, produção e logística.</p>
      <p>Com o <span style={{ fontWeight: 'bold' }}>ERP Gerency</span> tenha um sistema eficiente para fazer a cotação de serviços e produtos, reduzir o custo de aquisição da mercadoria para, então, praticar preços mais competitivos.</p>
    </div>,
    functionalitiesTitle: 'Recursos',
    functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
    functionalitiesItems: [
      'Controle de Clientes',
      'Controle de Fornecedores',
      'Contas Correntes',
      'Controle de Supervisores',
      'Controle de Cobradores',
      'Controle das Despesas',
      'Controle das Receitas',
      'Fluxo de Caixa',
      'Previsão Orçamentária',
      'Conciliação Bancária',
      'Contas Correntes',
      'Emissão de Boletos',
      'Gerenciamento de Cobranças',
      'Gerenciamentos de Cartões',
      'Gerenciamento do Plano de Contas',
      'Gerenciamento por Centro de Custo',
      'E muito mais...',
    ]
  }

  // {
  //   id: 4,
  //   name: 'nfcepdv',
  //   image: '../assets/images/products/products_baselaptop/nfcepdv.png',
  //   title: <ProductTitle>GERENCY <br/>PDV NFC-e</ProductTitle>,
  //   titleText: 'GERENCY PDV NFC-e',
  //   subTitle: 'Software Emissor de Notas Fiscais Eletrônicas ao Consumidor.',
  //   text: `Com o Gerency PDV NFC-e você emite notas fiscais eletrônicas ao consumidor no seu
  // 	computador de maneira rápida e simples. Ideal para empresas e que tem
  // 	uma alto fluxo de atendimento a clientes no estabelecimento e necessitam
  // 	de muitas estações funcionando ao mesmo tempo.`,
  //   benefits: [
  //     {
  //       icon: <GrLineChart size={48} />,
  //       text: 'Menos burocracia e maior facilidade para manter o negócio legalizado'
  //     },
  //     {
  //       icon: <MdAttachMoney size={48} />,
  //       text: 'Economia, pois dispensa o uso de Impressoras Fiscais Térmicas'
  //     },
  //     {
  //       icon: <BsCheckBox size={48} />,
  //       text: 'Não necessita de fiscalização nas máquinas'
  //     },

  //     {
  //       icon: <MdTimeline size={48} />,
  //       text: 'Acompanhamento e envio das emissões em tempo real'
  //     },
  //     {
  //       icon: <AiOutlineControl size={48} />,
  //       text: 'Maior controle e organização das notas geradas'
  //     },
  //     {
  //       icon: <MdPrint size={48} />,
  //       text: 'Impressão dos documentos em impressoras comuns'
  //     },
  //     {
  //       icon: <MdEmail size={48} />,
  //       text: 'Envia a NFCe por email para o cliente caso haja necessidade'
  //     },

  //     {
  //       icon: <MdPermPhoneMsg size={48} />,
  //       text: 'Agilidade no atendimento nos estabelecimentos comerciais'
  //     },
  //   ],
  //   secondaryTitle: 'GERENCIAMENTO E EMISSÃO DE NFCE AVANÇADOS',
  //   secondarySubtitle: 'Tenha controle total sobre suas notas fiscais.',
  //   secondaryText: ` O GerencyPDV disponibiliza um grande leque de funcionalidades
  // 	 e configurações para um controle completo na emissão de notas fiscais ao consumidor.
  // 		`,
  //   functionalitiesTitle: 'Funcionalidades',
  //   functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
  //   functionalitiesItems: [
  //     'Abertura e Fechamento de Caixa com totalizadores e emissão de uma leitura de resumo do movimento do dia',
  //     'Emissão de um relatório com a Leitura Diária do movimento',
  //     'Emissão de relatório do Movimento de Vendas Mensal',
  //     'Pesquisa produtos por: Código, Código EAN, Referência, Descrição, Marca e Modelo',
  //     'Pesquisa a lista de produtos vendidos por grade ou lista',
  //     'Cadastra Clientes direto do PDV',
  //     'Insere cliente avulso para as Emissões de NFCe',
  //     'Localiza os clientes e controla o saldo de credito para compras parceladas',
  //     'Emissão e Cancelamento de NFC-e ',
  //     'Emissão de e NFCe em Contigência',
  //     'Emissão, Devolução e Gerenciamento de pedidos em Consignação',
  //     'Emissão de devolução de trocas dos clientes',
  //     'Emissão de Pedidos, Orçamentos e Ordem de Serviços',
  //     'Emissão de Troca de Vendas de NFCe',
  //     'Mescla Pedidos',
  //     'Inutilização das numerações de NFCe',
  //     'Gerenciador de NFCe',
  //     'Gerenciamento de Pedidos e Orçamentos',
  //     'Movimentações avulsas no caixa com: Suprimentos e Sangrias',
  //     'Recuperação e Gerenciamento de NFCe em Contigência',
  //     'Configuração de Balanças conectadas ao PDV',
  //     'Sistema de Senhas com acessos por usuários',
  //     'Backup dos Xmls na nuvem com gerenciador exclusivo',
  //     'Impressão de Pedidos em vários Modelos do tamanho A4,A5 ou papel 80 mm',
  //     'Integração com o TEF',
  //     'Pagamento TEF com cartão avulso',
  //     'Pedidos, Gerenciamento e Relatório do tipo Conta de Cliente',
  //     'Atualização dos preços automática com o servidor',
  //     'Recuperação de vendas com o servidor',
  //     'Três Tabelas de preço por produto',
  //     'Pedidos, Gerenciamento e Relatório do tipo Conta de Cliente incluindo serviços',
  //   ]
  // },
  // {
  //   id: 5,
  //   name: 'nfcepro',
  //   image: '../assets/images/products/products_baselaptop/nfcepro.png',
  //   title: <ProductTitle>GERENCY <br/>NFC-e PRO</ProductTitle>,
  //   titleText: 'GERENCY NFC-e PRO',
  //   subTitle: 'Software Emissor Simplificado de Notas Fiscais Eletrônicas ao Consumidor.',
  //   text: `Com o NFC-e PRO você emite Notas fiscais eletrônicas no seu micro
  // 	computador de maneira Rápida e Simples. Ideal para microempresas e microempreendedor Individual.`,
  //   benefits: [
  //     {
  //       icon: <GrLineChart size={48} />,
  //       text: 'Menos burocracia e maior facilidade para manter o negócio legalizado'
  //     },
  //     {
  //       icon: <MdAttachMoney size={48} />,
  //       text: 'Economia, pois dispensa o uso de Impressoras Fiscais Térmicas'
  //     },
  //     {
  //       icon: <BsCheckBox size={48} />,
  //       text: 'Não necessita de fiscalização nas máquinas'
  //     },

  //     {
  //       icon: <MdTimeline size={48} />,
  //       text: 'Acompanhamento e envio das emissões em tempo real'
  //     },
  //     {
  //       icon: <AiOutlineControl size={48} />,
  //       text: 'Maior controle e organização das notas geradas'
  //     },
  //     {
  //       icon: <MdPrint size={48} />,
  //       text: 'Impressão dos documentos em impressoras comuns'
  //     },
  //     {
  //       icon: <MdEmail size={48} />,
  //       text: 'Envia a NFCe por email para o cliente caso haja necessidade'
  //     },

  //     {
  //       icon: <MdPermPhoneMsg size={48} />,
  //       text: 'Agilidade no atendimento nos estabelecimentos comerciais'
  //     },
  //   ],
  //   secondaryTitle: 'GERENCIAMENTO E EMISSÃO DE NFCE SIMPLES',
  //   secondarySubtitle: 'Tenha controle total sobre suas notas fiscais.',
  //   secondaryText: ` O GerencyPDV disponibiliza funcionalidades
  // 	 e configurações para um controle simplificado na emissão de notas fiscais ao consumidor.
  // 		`,
  //   functionalitiesTitle: 'Funcionalidades',
  //   functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
  //   functionalitiesItems: [
  //     'Abertura e Fechamento de Caixa com totalizadores e emissão de uma leitura de resumo do movimento do dia',
  //     'Emissão de um relatório com a Leitura Diária do movimento',
  //     'Emissão de relatório do Movimento de Vendas Mensal',
  //     'Pesquisa produtos por: Código, Código EAN, Referência, Descrição, Marca e Modelo',
  //     'Pesquisa a lista de produtos vendidos por grade ou lista',
  //     'Cadastra clientes e produtos direto do PDV',
  //     'Insere cliente avulso para as Emissões de NFCe',
  //     'Emissão e Cancelamento de NFC ',
  //     'Emissão de e NFCe em Contigência',
  //     'Emissão de Pedidos, Orçamentos e Ordem de Serviços',
  //     'Inutilização das numerações de NFCe',
  //     'Gerenciador de NFCe',
  //     'Gerenciamento de Pedidos e Orçamentos',
  //     'Movimentações avulsas no caixa com: Suprimentos e Sangrias',
  //     'Recuperação e Gerenciamento de NFCe em Contigência',
  //     'Configuração de Balanças conectadas ao PDV',
  //     'Sistema de Senhas com acessos por usuários',
  //     'Impressão de Pedidos em vários Modelos do tamanho A4,A5 ou papel 80 mm',
  //     'Integração com o TEF',
  //     'Pagamento TEF com cartão avulso',
  //   ]
  // },
  // {
  //   id: 6,
  //   name: 'gerencyestoque',
  //   image: '../assets/images/products/products_baselaptop/estoque.png',
  //   title: <ProductTitle>GERENCY <br/>ESTOQUE</ProductTitle>,
  //   titleText: 'GERENCY ESTOQUE',
  //   subTitle: 'Software para Gerenciamento de Estoque',
  //   text: `
  // 	O controle de estoque é algo fundamental para que a empresa ofereça os produtos certos na
  // 	hora certa. Ele funciona como uma ferramenta de monitoramento de tudo que está disponível
  // 	no depósito e permite que você programe melhor as compras que devem ser feitas, tornando-se
  // 	parte importante do seu planejamento.
  // 	`,
  //   benefits: [
  //     {
  //       icon: <AiOutlineInbox size={48} />,
  //       text: 'Nenhum desperdício de recursos'
  //     },
  //     {
  //       icon: <GiChecklist size={48} />,
  //       text: 'Produção em dia'
  //     },
  //     {
  //       icon: <FaCalendarCheck size={48} />,
  //       text: 'Entregas na data correta'
  //     },
  //     {
  //       icon: <MdAttachMoney size={48} />,
  //       text: 'Lançar promoções no momento correto'
  //     },
  //     {
  //       icon: <RiMoneyDollarCircleLine size={48} />,
  //       text: 'Gestão e monitoramento da tributação'
  //     },
  //     {
  //       icon: <GrTransaction size={48} />,
  //       text: 'Controle de qualidade de validade e o zelo pela condição física ao qual o produto se encontra'
  //     },
  //     {
  //       icon: <AiOutlineControl size={48} />,
  //       text: 'Maior controle sobre a rotatividade dos produtos'
  //     },
  //     {
  //       icon: <GoChecklist size={48} />,
  //       text: 'Melhora no fluxo de caixa'
  //     },
  //   ],
  //   secondaryTitle: 'EVITE SURPRESAS DESAGRADÁVEIS AO CONTROLAR CORRETAMENTE O SEU ESTOQUE',
  //   secondarySubtitle: 'Com o GerencyEstoque, você acompanha minuciosamente o seu estoque',
  //   secondaryText: <div>
  //     <p>Buscar maneiras de atender as necessidades dos seus clientes para que eles estejam sempre satisfeitos com a sua empresa para que com ele, você sabe exatamente o que e quando comprar para não deixar os seus clientes na mão.</p>
  //     <p>Se deixar produtos faltando é um problema que pode afetar a satisfação do cliente. Ter produtos demais pode causar sérios prejuízos às finanças da empresa. Quanto melhor é a gestão de estoque, menor é a probabilidade de que itens desnecessários sejam comprados e haja desperdícios.</p>
  //   </div>,
  //   functionalitiesTitle: 'Funcionalidades',
  //   functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
  //   functionalitiesItems: [
  //     'Tributos (ICMS, CFOP, CEST, PIS, COFINS e IPI)',
  //     'Entrada de Notas Automática XML ou Manual',
  //     'Histórico movimentação do Produto',
  //     'Gerenciamento de Armazenamento',
  //     'Inventário manual ou Inventário por Coletor',
  //     'Controle da Produção',
  //     'Transferência de produtos ou de peças',
  //     'Controle de Grade de Tamanho',
  //     'Curva ABC, Auxílio em Tributações etc',
  //     'Controle de Compras',
  //     'Impressão de Etiquetas Código de Barras',
  //     'Integração com Busca Preço',
  //     'Produtos por Lucratividade',
  //     'Controle de Custo Simples e Regime Normal etc',
  //     'Produto Acabado, Produtos Semiacabados, Insumos e Embalagens',
  //     'Até 3 preços diferentes para venda',
  //     'Promoção com Preços por período ou período semanal',
  //     'Composição para Fabricação (Receita)',
  //     'Rentabilidade',
  //     'Informações Técnicas e Nutricionais',
  //     'Unidade de Venda e Unidade de Compra',
  //     'Imagem do produto',
  //     'Controle Qtd Máxima, Controle Qtd Mínima, Controle Quantidade Disponível e Controle Estoque',
  //     'Dias para Ressuprimento',
  //     'Exporta produto Balança',
  //     'Custo Compra, Custo de Aquisição e Custo Comercial',
  //     'Dezenas de relatório',
  //   ]
  // },
  // {
  //   id: 7,
  //   name: 'gerencyfoods',
  //   image: '../assets/images/products/products_baselaptop/foods.png',
  //   title: <ProductTitle>GERENCY <br/>FOODS</ProductTitle>,
  //   titleText: 'GERENCY FOODS',
  //   subTitle: 'Software para Restaurantes e FastFoods',
  //   text: `
  //     O Gerency Foods é a ferramenta que torna a gestão do restaurante
  //     bem mais ágil e fácil de acompanhar possibilitando a centralização
  //     das informações. Como os gastos e receitas, sobre o seu estabelecimento
  //     estão visíveis e no mesmo local, você consegue ter controle das
  //     finanças e pode reduzir custos.
  // 	`,
  //   benefits: [
  //     {
  //       icon: <AiOutlineInbox size={48} />,
  //       text: 'Controle do fluxo de caixa'
  //     },
  //     {
  //       icon: <GiChecklist size={48} />,
  //       text: 'Gerenciamento de pedidos, inclusive delivery'
  //     },
  //     {
  //       icon: <MdAttachMoney size={48} />,
  //       text: 'Integração entre setores'
  //     },
  //     {
  //       icon: <GrTransaction size={48} />,
  //       text: 'Gestão do estoque'
  //     },
  //     {
  //       icon: <GoChecklist size={48} />,
  //       text: 'Emissão de notas e cupons fiscais'
  //     },
  //   ],
  //   secondaryTitle: 'GERENCIAMENTO COMPLETO PARA RESTAURANTE',
  //   secondarySubtitle: 'Com o Gerency Foods, todos os estágios do pedido são atendidos',
  //   secondaryText: <div>
  //     <p>Gerencie seu restaurante, bistrô, cadeia de fast food ou bar com todas as funcionalidades essenciais que
  //     permitem o aumento da eficiência nas operações. Personalize diversas opções oferecidas no Gerency Foods.
  //     </p>

  //   </div>,
  //   functionalitiesTitle: 'Funcionalidades',
  //   functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
  //   functionalitiesItems: [
  //     'Controle de Pedidos por Mesa',
  //     'Reserva e Transferência de mesas',
  //     'Composição de Pizza',
  //     'Taxa de Serviços',
  //     'Impressão do pedido na Cozinha',
  //     'Gerenciamento dos pedidos por Mesa',
  //     'Gerenciamento de pedidos Delivery',
  //     'Abertura e Fechamento de Caixa com totalizadores e emissão de uma leitura de resumo do movimento do dia',
  //     'Emissão de um relatório com a Leitura Diária do movimento',
  //     'Emissão de relatório do Movimento de Vendas Mensal',
  //     'Pesquisa produtos por: Código, Código EAN, Referência, Descrição, Marca e Modelo',
  //     'Pesquisa a lista de produtos vendidos por grade ou lista',
  //     'Cadastra Clientes direto do PDV',
  //     'Insere cliente avulso para as Emissões de NFCe',
  //     'Localiza os clientes e controla o saldo de credito para compras parceladas',
  //     'Emissão e Cancelamento de NFC-e ',
  //     'Emissão de e NFCe em Contigência',
  //     'Emissão, Devolução e Gerenciamento de pedidos em Consignação',
  //     'Emissão de devolução de trocas dos clientes',
  //     'Emissão de Pedidos, Orçamentos e Ordem de Serviços',
  //     'Emissão de Troca de Vendas de NFCe',
  //     'Mescla Pedidos',
  //     'Inutilização das numerações de NFCe',
  //     'Gerenciador de NFCe',
  //     'Gerenciamento de Pedidos e Orçamentos',
  //     'Movimentações avulsas no caixa com: Suprimentos e Sangrias',
  //     'Recuperação e Gerenciamento de NFCe em Contigência',
  //     'Configuração de Balanças conectadas ao PDV',
  //     'Sistema de Senhas com acessos por usuários',
  //     'Backup dos Xmls na nuvem com gerenciador exclusivo',
  //     'Impressão de Pedidos em vários Modelos do tamanho A4,A5 ou papel 80 mm',
  //     'Integração com o TEF',
  //     'Pagamento TEF com cartão avulso',
  //     'Pedidos, Gerenciamento e Relatório do tipo Conta de Cliente',
  //     'Atualização dos preços automática com o servidor',
  //     'Recuperação de vendas com o servidor',
  //     'Três Tabelas de preço por produto',
  //     'Pedidos, Gerenciamento e Relatório do tipo Conta de Cliente incluindo serviços',
  //   ]
  // },
  // {
  //   id: 8,
  //   name: 'gerencygad',
  //   image: '../assets/images/products/products_baselaptop/gad.png',
  //   title: <ProductTitle>GERENCY <br/>GAD</ProductTitle>,
  //   titleText: 'GERENCY GAD',
  //   subTitle: <p>Software para Geração de Arquivos Fiscais Digitais<br /> (SEF II, SPED PIS/COFINS , SPED FISCAL)</p>,
  //   text: `
  //     O software foi desenvolvido para os contribuintes que necessitam
  //     gerar e entregar por lei, para prestação de contas aos órgãos estadual
  //     e federal, os arquivos digitais de suas movimentações de compra e vendas.
  // 	`,
  //   benefits: [
  //     {
  //       icon: <MdAttachMoney size={48} />,
  //       text: 'Controle e uniformização das informações'
  //     },
  //     {
  //       icon: <GiCheckedShield size={48} />,
  //       text: 'Diminuição dos erros involuntários'
  //     },
  //     {
  //       icon: <GrTransaction size={48} />,
  //       text: 'Agilidade dos processos burocráticos.'
  //     },
  //   ],
  //   secondaryTitle: 'GERAÇÃO DE ARQUIVOS SEF II, SPED E SPED FISCAL COM FACILIDADE',
  //   secondarySubtitle: 'Todas as informações tributárias e burocráticas simplificadas.',
  //   secondaryText: <div>
  //     <p>Se preocupe com o que realmente importa. O GerencyGAD sintetiza todas as informações
  //     tributárias e realiza o trabalho pesado, gerando os arquivos essenciais para a empresa
  //     com facilidade.
  //     </p>

  //   </div>,
  //   functionalitiesTitle: 'Funcionalidades',
  //   functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
  //   functionalitiesItems: [
  //     'Geração de arquivos SEF II',
  //     'Geração de arquivos EDOC',
  //     'Geração de arquivos SPED CONTRIBUIÇÕES (PIS/COFINS)',
  //     'Geração de arquivos SPED FISCAL',
  //     'Geração de arquivos unificados Matriz e filiais',
  //     'Auxilia na correção de erros nos arquivos',
  //     'Geração de registro de inventários BLOCO H',
  //     'Calculadora para auxílio de cálculos PIS/COFINS',
  //     'Consolidação de itens (PIS/COFIS)',
  //     'Consolidação de itens (ICMS)',
  //     'Consolidação de valores por ICMS',
  //     'Detalhamento de NFC-e',
  //   ]
  // },
  // {
  //   id: 9,
  //   name: 'gervemmobile',
  //   image: '../assets/images/products/products_baselaptop/gervem_mobile.png',
  //   title: <ProductTitle>GERENCY <br/>MOBILE</ProductTitle>,
  //   titleText: 'GERENCY MOBILE',
  //   subTitle: <p> Aplicativo para força de vendas com gerenciador Desktop</p>,
  //   text: `
  //     O software foi desenvolvido para empresas que necessitam de maior
  //     agilidade em vendas externas, que não tinham tanta eficiência com
  //     o papel e a caneta.
  // 	`,
  //   benefits: [
  //     {
  //       icon: <GiChecklist size={48} />,
  //       text: 'Controle e uniformização das informações'
  //     },
  //     {
  //       icon: <GiCheckedShield size={48} />,
  //       text: 'Diminuição dos erros involuntários'
  //     },
  //     {
  //       icon: <GrTransaction size={48} />,
  //       text: 'Agilidade dos processos burocráticos.'
  //     },
  //   ],
  //   secondaryTitle: 'GERAÇÃO DE ARQUIVOS SEF II, SPED E SPED FISCAL COM FACILIDADE',
  //   secondarySubtitle: 'Todas as informações tributárias e burocráticas simplificadas.',
  //   secondaryText: <div>
  //     <p>Se preocupe com o que realmente importa. O GerencyGAD sintetiza todas as informações
  //     tributárias e realiza o trabalho pesado, gerando os arquivos essenciais para a empresa
  //     com facilidade.
  //     </p>

  //   </div>,
  //   functionalitiesTitle: 'Funcionalidades',
  //   functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
  //   functionalitiesItems: [
  //     'Geração de arquivos SEF II',
  //     'Geração de arquivos EDOC',
  //     'Geração de arquivos SPED CONTRIBUIÇÕES (PIS/COFINS)',
  //     'Geração de arquivos SPED FISCAL',
  //     'Geração de arquivos unificados Matriz e filiais',
  //     'Auxilia na correção de erros nos arquivos',
  //     'Geração de registro de inventários BLOCO H',
  //     'Calculadora para auxílio de cálculos PIS/COFINS',
  //     'Consolidação de itens (PIS/COFIS)',
  //     'Consolidação de itens (ICMS)',
  //     'Consolidação de valores por ICMS',
  //     'Detalhamento de NFC-e',
  //   ]
  // },
  // {
  //   id: 10,
  //   name: 'gerencymdfe',
  //   image: '../assets/images/products/products_baselaptop/mdfe.png',
  //   title: <ProductTitle>GERENCY <br/>MDF-e</ProductTitle>,
  //   titleText: 'GERENCY MDF-e',
  //   subTitle: <p>Software para Emissão de Manifestos Eletrônicos</p>,
  //   text: `
  //     O Gerency MDF-e é um produto desenvolvido que auxilia na emissão
  //     e gerenciamento dos manifestos eletrônicos para o transporte de
  //     bens ou mercadorias acobertadas por uma ou várias NFe,
  //     realizado em veículos próprios ou contratados.

  // 	`,
  //   benefits: [
  //     {
  //       icon: <GiChecklist size={48} />,
  //       text: 'Redução de custos de impressão do documento fiscal, uma vez que o documento é emitido eletronicamente'
  //     },
  //     {
  //       icon: <AiOutlineControl size={48} />,
  //       text: 'Redução de custos de aquisição de papel, pelos mesmos motivos expostos acima'
  //     },
  //     {
  //       icon: <GoChecklist size={48} />,
  //       text: 'Redução de custos de armazenagem de documentos fiscais'
  //     },
  //   ],
  //   secondaryTitle: 'SISTEMA ROBUSTO PARA GERENCIAR MANIFESTOS ELETRÔNICOS',
  //   secondarySubtitle: 'Aproveite a facilidade e a conveniência de gerenciar MDF-e',
  //   secondaryText: <div>
  //     <p>
  //       O Gerency MDF-e vêm para tornar a emissão e o gerenciamento de manifestos
  //       eletrônicos simples, mas não abrindo mão de ser um sistema completo e robusto
  //       no qual a eficiência e rapidez são essenciais.
  //     </p>

  //   </div>,
  //   functionalitiesTitle: 'Funcionalidades',
  //   functionalitiesSubtitle: 'Confira algumas funcionalidades principais do sistema.',
  //   functionalitiesItems: [
  //     'Emissão de DFe',
  //     'Transmissão de DFe',
  //     'Duplicação de DFe',
  //     'Cancelamento DFe',
  //     'Encerramento DFe',
  //   ]
  // },
];
