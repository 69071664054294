import React, { Component } from 'react';
import { ScreenClassRender } from 'react-grid-system';
import { Switch, Route, withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { BodyWrapper } from './styles';
import Home from './Home'
import WhoWeAre from './Pages/WhoWeAre';
import Solution from './Pages/Solution';
import ClientLogin from './Pages/ClientLogin';
import PoliticaPrivacidade from './Pages/PoliticaPrivacidade';
import Contact from './Pages/Contact';
import Support from './Pages/Support';
import Parceiro from './Pages/Parceiro';
import Products from './Pages/Products';
import Product from './Pages/Products/single';
import ScrollToTop from './ScrollToTop'
import DigitalManagement from './Pages/DigitalManagement';
import NotFoundPage from './Pages/NotFoundPage'


class Body extends Component {

  handleMarginTop = (screenClass) => {
    if (['xs', 'sm'].includes(screenClass)) {
      return '-431px'
    } else {
      return '-671px'
    }
  }
  render() {

    return (
      <ScreenClassRender render={screenClass => (
        <BodyWrapper style={{
          marginTop: this.props.location.pathname === '/' ? '0' : this.handleMarginTop(screenClass)

        }}>
          <Switch>
            <Route exact path="/">
              <ScrollToTop>
                <Home />
              </ScrollToTop>
            </Route>
            <Route path="/about">
              <ScrollToTop>
                <WhoWeAre />
              </ScrollToTop>
            </Route>
            <Route path="/solutions/:IdSolution">
              <ScrollToTop>
                <Solution timestamp={new Date().toString()} />
              </ScrollToTop>
            </Route>
            <Route exact path="/products">
              <ScrollToTop>
                <Products timestamp={new Date().toString()} />
              </ScrollToTop>
            </Route>
            <Route exact path="/plans">
              <ScrollToTop>
                <Products timestamp={new Date().toString()} />
              </ScrollToTop>
            </Route>
            <Route exact path="/management">
              <ScrollToTop>
                <DigitalManagement timestamp={new Date().toString()} />
              </ScrollToTop>
            </Route>
            <Route exact path="/products/:produto">
              <ScrollToTop>
                <Product timestamp={new Date().toString()} />
              </ScrollToTop>
            </Route>
            <Route exact path="/login">
              <ScrollToTop>
                <ClientLogin timestamp={new Date().toString()} />
              </ScrollToTop>
            </Route>
            <Route exact path="/politicaprivacidade">
              <ScrollToTop>
                <PoliticaPrivacidade timestamp={new Date().toString()} />
              </ScrollToTop>
            </Route>
            <Route exact path="/contact">
              <ScrollToTop>
                <Contact timestamp={new Date().toString()} />
              </ScrollToTop>
            </Route>
            <Route exact path="/support">
              <ScrollToTop>
                <Support timestamp={new Date().toString()} />
              </ScrollToTop>
            </Route>
            <Route exact path="/suporte">
              <ScrollToTop>
                <Support timestamp={new Date().toString()} />
              </ScrollToTop>
            </Route>
            <Route exact path="/parceiro">
              <ScrollToTop>
                <Parceiro timestamp={new Date().toString()} />
              </ScrollToTop>
            </Route>
            <Route>
              <NotFoundPage timestamp={new Date().toString()} />
            </Route>
          </Switch>
        </BodyWrapper>

      )} />
    );
  }
}
export default withRouter(Body);



