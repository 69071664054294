import styled from 'styled-components';
import { Link } from 'react-router-dom'
import { Container as ContainerGridSystem } from 'react-grid-system'
import { device } from '../../../../css/devices'


export const Container = styled(ContainerGridSystem)`
    display: flex; 
    flex-direction: row; 
    flex-flow: 'wrap';

    @media ${device.xs} {
        flex-flow: column;

    }

`

export const Image = styled.img`
    width: 80%; 
    height: 80%; 
    object-fit: contain; 

    @media ${device.xs} {
        width: 100%; 
        height: 100%; 
        object-fit: contain; 
    }
`;

export const Card = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Helvetica';
    background: #FFF;
    padding: 15px;
    box-shadow: 8px 11px 31px -15px rgba(0,0,0,0.75);
    margin-top: 15px;

    @media ${device.xs}{
        flex-direction: row;
        width: 100%;
        height: 150px;
        margin-top: 15px;
        
    }

`

export const CardPlans = styled.div`
    max-width: 100%;
    height: 630px;
    display: flex;
    flex-direction: column;
    font-family: 'Helvetica';
    background: #FFF;
    box-shadow: 3px 5px 20px -18px rgba(0,0,0,0.75);
    margin-top: 15px;
    padding: 20px;

    @media ${device.xs}{        
       width: 100%;
       padding: 0px;
    }

`


export const CardHeader = styled.div`
    width: 100%;
    height: 200px;

    @media ${device.xs} {
        max-width: 100px;
        margin-left: 10px;
    }
`
export const CardImage = styled.div`
    max-width:100%;
    height:100%;
    background: url(${props => props.image}) no-repeat;
    background-size: contain;
    background-position: center;

    @media ${device.xs} {
        max-width: 100%;
        max-height: 150px;
        background-position: left;
    }

    div{
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0;
        transition: opacity 0.4s ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    :hover div{
        opacity: 1;
    }
`
export const CardBody = styled.div`
    padding: 15px;

    @media ${device.xs} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }
`
export const CardTitle = styled.h1`
    color: black;
    font-size: 2em;
    font-weight: bold;
    
    @media ${device.xs} {
       font-size: 24px;
        
    }
`
export const CardSubTitle = styled.h4`
    color: #CAB56D;
    font-weight: 300;
    margin-top: 5px;
    margin-ri: 5px;
    font-family: 'Helvetica';
`
export const CardText = styled.span`
    width: 100%;
    color: #999999;
`

export const ProductTitle = styled.h1`
    font-size: 3rem;
    margin-bottom: 5px;
`
export const ProductSubTitle = styled.h3`
    margin-top: 0;
    font-size: 1.3rem;
    color: #CAB56D;
    font-weight: 400;
    font-family: 'Helvetica';

`
export const ProductText = styled.p`
    font-size: 1.2rem;
    font-family: 'Helvetica';
    color: #000;
    

    @media ${device.xs || device.sm} {
        padding-bottom: 20px;
    }

`
export const ProductDemoButton = styled(Link)`
    padding: 8px 40px;
    background: transparent;
    border: 2px solid #EDD47A;
    border-radius: 60px;
    color: #4D4D4D;
    font-family: 'Helvetica';
    font-weight: 600;
    text-decoration: none;
    transition: 0.2s ease;
    width: 100%;

    :hover{
        background: #EDD47A;
        color: black;
    }

    @media ${device.xs} {
        
    }
`

export const ProductBenefitTitle = styled.h1`
   font-size: 7vh; 
   color: #4D4D4D;
   text-align: center;
`;

export const BenefitsWrapper = styled.div`
    
    /* flex:1; */
    /* margin-left: calc(10% - 10px); */

`
export const BenefitItem = styled.div`
    display: flex;
    align-items: center;
    margin-top: 30px;
    span{
        font-size: 1.0rem;
        margin-left: 10px;
    }
`

export const LineGradient = styled.div`
    width: 100%;
    min-height: 2px;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(204,204,204,1) 50%, rgba(255,255,255,0) 100%);
    margin-top: 20;
`;

export const PlanSelect = styled.select`
    
    background: white;
    padding: 10px 15px;
    border: solid #DFDFDF 2px;
    font-family: 'Helvetica';
    color: #929090;
    font-size: 16px;
    font-weight: 400; 
    appearance: none;
    text-align: center;
    text-align-last:center;

    option{
        padding: 10px !important;
    }

`
export const ButtonContratar = styled(Link)`
    padding: 10px 60px;
    border-radius: 20px;
    text-decoration: none;
    border: solid #D5BF71 2px;
    color: #D5BF71; 
`

export const PlanoEfase = styled.div`
    width: 150px;
    padding: 10px 20px;
    background: #F9F5E7;
    border-radius: 20px;
    font-size: 0.8em;
    text-align: center;
`

export const StyledH1 = styled.h1`
    color: ${props => props.color};
    font-size: ${props => props.size}px;
    max-width: ${props => props.width};
    width: ${props => props.width};
`
export const StyledH2 = styled.h1`
    color: ${props => props.color};
    font-size: ${props => props.size}vh;
    max-width: ${props => props.width};
`
export const StyledH3 = styled.h1`
    color: ${props => props.color};
    font-size: ${props => props.size}vh;
    max-width: ${props => props.width};
`
export const StyledP = styled.p`
    color: ${props => props.color};
    font-size: ${props => props.size}px;
    max-width: ${props => props.width};
`

export const FunctionalityItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: 0.2s ease-in-out;
    padding: 5px ;

    &:hover{
        background: #212121;
        color: #D5BF71; 
        cursor: pointer;
    }
`