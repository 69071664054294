import styled, {keyframes} from 'styled-components';
import { fadeIn } from 'react-animations'

/* const fadeIn = keyframes`
  from {
    transform: scale(.75);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;*/

const fadeOut = keyframes`
  from {    
    opacity: 1;
  }

  to {   
    opacity: 0;
  }
`; 

const fadeInDownAnimation = keyframes`${fadeIn}`;
//const fadeOutUpAnimation = keyframes`${fadeOut}`;


export const SolutionWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    
`;

export const SolutionContainer = styled.div`
    background: #EDD47A;  
    
    position: absolute;
    z-index: 7;    
    flex-direction: col;
    align-items: stretch;
`;

export const SolutionItem = styled.div`
   font-family: 'Helvetica';
   font-size: 15px;
   text-align: center;   
   color: #4D4D4D;
   padding-top:15px;
   height: 90px;
   cursor: pointer;
   transition: 0.3s ease-in-out;
   font-weight: bold;
      
   :hover {
     color: #EDD47A;
     background: #4D4D4D;
     
   }

   svg{
     color: #4D4D4D !important;
     fill: #4D4D4D !important;
     transition: 0.3s ease-in-out;
   }

   :hover svg{
     color: #EDD47A !important;
     fill: #EDD47A !important;
     
   }

   :hover a{
     color: #EDD47A !important;
   }
   
`;

export const Container = styled.div`
    position: absolute;    

`;

export const Bread = styled.svg`
    color: #4D4D4D; 
    background-image: url(../../css/svg/bread.svg);    

`;