import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Container, Row, Col, Hidden, ScreenClassRender } from 'react-grid-system';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdAdd } from 'react-icons/md';
import Truncate from 'react-truncate';
import { ReactComponent as LeftOrnamentGiant } from '../../../../css/svg/solution_lines_left.svg';
import {
	Card,
	CardPlans,
	CardHeader,
	CardImage,
	CardBody,
	CardTitle,
	CardSubTitle,
	CardText,
	Overlay,
	LineGradient,
	NavbarDropdown,
	NavbarDropdownContent,
	PlanSelect,
	ButtonContratar,
	PlanoEfase
} from './styles';

import { Flip, Fade } from 'react-reveal';
import {
	Link as ScrollLink,
	DirectLink,
	Element,
	Events,
	animateScroll as scroll,
	scrollSpy,
	scroller
} from 'react-scroll';
import { data as produtos } from './productsInfo.js';
import TablePlans from './Components/TablePlans';

// import { Container } from './styles';

class Solution extends Component {
	constructor(props) {
		super(props);
	}
	goToProduct() {
		const path = this.props.match.path;
		if (path == '/products') {
			scroller.scrollTo('products', {
				duration: 800,
				smooth: 'easeInOutQuart'
			});
		}
	}
	componentDidMount() {
		this.goToProduct();
	}
	componentDidUpdate() {
		this.goToProduct();
	}

	render() {
		return (
			<ScreenClassRender
				render={(screenClass) => (
					<Container
						fluid
						style={{
							background: '#eaeaea',
							marginTop: [ 'xs' ].includes(screenClass) ? '70px' : '5px'
						}}
					>
						<Row
							style={{
								background: '#FFF',
								height: [ 'xs' ].includes(screenClass) ? 100 : 100,
								overflow: 'hidden'
							}}
						>
							<Col xl={12} xs={12}>
								<Fade top>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center'
										}}
									>
										<h1 style={{ fontSize: '2.6em', fontFamily: 'Helvetica', color: '#4D4D4D' }}>
											Planos
										</h1>
									</div>
								</Fade>
							</Col>
						</Row>

						{/* Planos */}
						<Container>
							<Element name="products" />
							<Row style={{ background: '#eaeaea', paddingTop: 20 }}>
								<Col xl={12} xs={12}>
									<h2 style={{ marginBottom: 2, color: '#707070' }}>Suite Gerency</h2>
									<span style={{ color: '#707070' }}>
										Conheça a suíte de programas Gerency para atender os mais variados tipos de
										negócios.
									</span>
								</Col>
							</Row>
							<Row style={{ marginTop: 25 }}>
								<Col xl={12} xs={12}>
									<TablePlans />
								</Col>
							</Row>

							<Element name="products" />
							<Row style={{ background: '#eaeaea', paddingTop: 20 }}>
								<Col xl={12} xs={12}>
									<h2 style={{ marginBottom: 2, color: '#707070' }}>Conheça nossos produtos</h2>
									<span style={{ color: '#707070' }}>
										Estes poderão ser adquiridos de forma individual
									</span>
								</Col>
							</Row>

							<Row >
								{produtos.map((produto) => (
									<Col xs={12} xl={4}>
										<Fade left>
											<div style={{
												display: 'flex',
												flexGrow: 1,
											}}>
												<Card key={produto.id}>
													<CardHeader>
														<CardImage image={produto.image}>
															<Link to={'/products/' + produto.name}>
																<div>
																	<MdAdd size="4em" color="white" />
																</div>
															</Link>
														</CardImage>
													</CardHeader>
													<CardBody>
														<div
															style={{
																display: 'flex',
																justifyContent: [ 'xs' ].includes(screenClass)
																	? 'center'
																	: 'flex-start',
																alignItems: 'center'
															}}
														>
														{produto.title}
														</div>
														

														<Hidden xs>
															<CardText>
																<Truncate lines={3} ellipsis={<span>...</span>}>
																	{produto.text}
																</Truncate>
															</CardText>
														</Hidden>
														{/* <Row
														style={{
															margin: '30px 0',
															display: 'flex',
															flexDirection: 'row',
															alignItems: 'center'
														}}
													>
														<Col>
															<ButtonContratar>Contratar</ButtonContratar>
														</Col>
													</Row> */}
													</CardBody>
												</Card>
											</div>
										</Fade>
									</Col>
								))}
							</Row>
						</Container>
					</Container>
				)}
			/>
		);
	}
}

export default withRouter(Solution);
