import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {device} from '../../../css/devices';

export const SliderImage = styled.img`
    width: 500px;
    @media ${device.xs || device.sm}{
        width: 300px;
        display:flex;
        justify-content: center;
    }
`


export const SlideTitle = styled.h1`
   font-size: 2.5em;
   font-family: 'Helvetica';
   color: #4D4D4D;


`
export const SlideSubTitle = styled.h2`
    font-size: 2.0em;
   font-weight: 400;
   font-family: 'Helvetica';
   color: #CAB56D;
   margin-top: -15px;
`
export const SlideText = styled.p`
    max-width: 400px;
    font-family: 'Helvetica';
    margin-top: 15px;

`

export const SlideLink= styled(Link)`
    padding: 8px 40px;
    border: 2px solid #EDD47A;
    border-radius: 60px;
    color: #4D4D4D;
    font-family: 'Helvetica';
    font-weight: 600;   
    position: relative;
    text-decoration:none;
    top: 70px;
    cursor: pointer;
    transition: 0.2s ease;

    :hover{
        background: #EDD47A;
        color: black;
    }
`

export const SlideContent = styled.div`
    
    margin-left: 100px;

    @media ${device.xs || device.sm}{
        margin-top: 0px;
        margin-left: 0px;
        text-align: 'center';
    }

`



export const SliderButtonsWrapper = styled.div`
  display: flex;
  justify-content: center; 
  align-items: flex-end;
    
`

