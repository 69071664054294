import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route} from 'react-router-dom';
import {GlobalStyles} from './css/fonts/fonts'
import "./main.css";
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
 
ReactDOM.render(
    <BrowserRouter>
      <GlobalStyles />
      <Route path="/" component={App}/>
    </BrowserRouter>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

