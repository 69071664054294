import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col,
} from "reactstrap";
import { OgTitle } from '../../../css/styles'
import '../../../../src/main.css';

// core components
import "../../../css/paper-kit.css";

import './styles.css'

function SectionDados() {
    return (
        <>
            <div className="section text-center" id='sobreOngold'>
                <Container>
                    <Row>
                        <Col md="2">
                            <Card className="card-profile card-plain" style={{ border: 'none' }}>
                                <CardBody>
                                <img style={{ marginTop: '1rem' }}
                                  className="icon25"  
                                  src="./assets/images/25.png"
                                />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="card-profile card-plain" style={{ border: 'none', textAlign: 'left' }}>
                                <CardBody>
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <div className="author">
                                            <OgTitle tag="h4" style={{ color: '#EDD47A' }}>ANOS DE EXPERIÊNCIA</OgTitle>
                                            {/* <h6 className="card-category">Designer</h6> */}
                                        </div>
                                    </a>
                                    {/* <ProductText className="description" style={{ textAlign: 'left', color: '#4D4D4D', fontSize: '1.5rem' }}>
                                        Criamos e desenvolvemos soluções inovadoras,
                                        simples e eficientes utilizando Tecnologia Digital
                                        para promover a transformação, o crescimento e ganho
                                        aos nossos clientes, proporcionando uma experiência
                                        memorável nos processos operacionais e de gestão da empresa.
                                    </ProductText> */}
                                    <p className="lead  mt-3" style={{ textAlign: 'left', color: '#4D4D4D', fontSize: '1.2rem' }}>
                                    Criamos e desenvolvemos soluções inovadoras,
                                        simples e eficientes utilizando Tecnologia Digital
                                        para promover a transformação, o crescimento e ganho
                                        aos nossos clientes, proporcionando uma experiência
                                        memorável nos processos operacionais e de gestão da empresa.
                      </p>
                      <div style={{ background: '#EDD47A'}} id='segmentos'></div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="1" style= {{ textAlign: 'center' }}>
                        {/* <hr style= {{ height: '350px', width: '1px', marginTop: '1rem', marginLeft: '3rem' }}/> */}
                        </Col>
                        <Col md="5">
                            <Card className="card-profile card-plain" style={{ border: 'none' }}>
                            
                                <CardBody>
                                    <Row>
                                        <Col md="4">
                                            <div className="info">
                                                <div className="icon icon-info">
                                                    <i class="fa fa-cubes" style={{ color: '#EDD47A', marginLeft: '-2.5rem' }}></i>
                                                </div>
                                                <div className="description">
                                                    <h4 className="info-title" style={{ fontWeight: 'bold' }}>20</h4>
                                                    <p className="lead  mt-3" className="description" style={{ color: '#4D4D4D', fontSize: '1rem', fontWeight: 'bold' }}> 
                                                        Temos mais de 20 produtos e serviços
                                                    </p>
                                                    {/* <Button className="btn-link" color="info" href="#pablo">
                                                        See more
                                                    </Button> */}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="info">
                                                <div className="icon icon-info">
                                                    <i class="fa fa-map" style={{ color: '#EDD47A', marginLeft: '-2.5rem' }}></i>
                                                </div>
                                                <div className="description">
                                                    <h4 className="info-title" style={{ fontWeight: 'bold' }}>50</h4>
                                                    <p className="lead  mt-3" className="description" style={{ color: '#4D4D4D', fontSize: '1rem', fontWeight: 'bold' }}> 
                                                        Estamos em mais de 50 cidades no estado de PE e PB
                                                    </p>
                                                    {/* <Button className="btn-link" color="info" href="#pablo">
                                                        See more
                                                    </Button> */}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="info">
                                                <div className="icon icon-info">
                                                    <i class="fa fa-handshake-o" style={{ color: '#EDD47A', marginLeft: '-2.5rem' }}></i>
                                                </div>
                                                <div className="description">
                                                    <h4 className="info-title" style={{ fontWeight: 'bold' }}>+2.100</h4>
                                                    <p className="lead  mt-3" className="description" style={{ color: '#4D4D4D', fontSize: '1rem', fontWeight: 'bold' }}> 
                                                        São 2.100 licenças de sistemas instaladas em mais de 800 empresas
                                                    </p>
                                                    {/* <Button className="btn-link" color="info" href="#pablo">
                                                        See more
                                                    </Button> */}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            
                            <Card className="card-profile card-plain" style={{ border: 'none' }}>
                                <CardBody>
                                    <Row>
                                        <Col md="12">
                                            <div className="info">
                                                <div className="description learn-more-button">
                                                    <Button
                                                        block
                                                        className="btn-white"
                                                        color="default"
                                                        href="/about"
                                                        size="lg"
                                                        style={{ background: '#E7CF78', borderColor: '#E7CF78', color: '#000', marginTop: '5px' }}
                                                        >
                                                        Saiba mais
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>  

                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default SectionDados;
