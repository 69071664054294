import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Container, Row, Col, Hidden, ScreenClassRender } from 'react-grid-system';
import { ReactComponent as LeftOrnament } from '../../../css/svg/left_ornament.svg'

import {
  OgButtonRounded, OgTitle, Background, Overlay, Image, WhoWeAreH1,
  WhoWeAreSubtitle, Paragraph, SolutionsDescription, SolutionsTitle
} from './homeStyles'
import { ReactComponent as LeftOrnament2 } from '../../../css/svg/left_ornament_2.svg'

import ProductSlider from '../ProductSlider'
import Solutions from '../Solutions/'
import Segmentos from '../../../components/Solutions/'
import '../../../../src/main.css';

import SectionDados from "../Section/SectionDados.js";
import SectionParceiro from "../Section/SectionParceiro.js"
import SectionComentCli from "../Section/SectionComentCli.js";
import SectionContato from "../Section/SectionContato.js";
import SectionFaleConosco from "../Section/SectionFaleConosco.js";
import SectionQuemSomos from "../Section/SectionQuemSomos.js";
import { Card } from 'reactstrap';


export default function Home(props) {
  /* const [a, b] = useState("");

  useEffect(() => console.log(a), [a]) */

  return (

    <ScreenClassRender render={screenClass => {
      console.log(screenClass);
      return (
        <Container fluid style={{ paddingLeft: 0, zIndex: -9999, position: 'relative', background: 'white', }}>

          <Container fluid style={{
            //marginTop: ['xs', 'sm'].includes(screenClass) ? '30px' : '0', 
            //height: '690px', 

            minHeight: '100%',
            paddingRight: 0
          }}>
            <Row>
              <Col md={12} xl={12} xs={12}>
                <SectionDados />
                
              </Col>
            </Row>
            <div style={{ background: '#EDD47A', width: '100%', height: '30rem' }} className='oculto2'>
              <Hidden xs sm>
                <Container style={{ marginTop: ['xs', 'sm'].includes(screenClass) ? '200px' : '0', marginBottom: '3rem' }}>
                  <Row>
                    <Col md={12} xl={12} style={{ marginTop: '2rem' }}>
                      <SolutionsTitle>Segmentos</SolutionsTitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xl={12} xs={12} style={{ marginTop: '2rem', marginBottom: '4rem' }}>
                      <Segmentos show={true} position="relative" />
                    </Col>
                  </Row>
                </Container>
              </Hidden>
            </div>
            {/* <Row>
              <Col md={12} xl={12} xs={12}>
                <SectionParceiro />
              </Col>
            </Row>  */}
            {/* <Row>
              <Col md={12} xl={12} xs={12}>               
                <SectionComentCli />
              </Col>
            </Row> */}
            <Row>
              <Col md={12} xl={12} xs={12}>
                <SectionContato />
              </Col>
            </Row>
            <div style={{ width: '100%' }}>
              <Row>
                <Col md={12} xl={12} style={{ marginTop: '2rem' }}>
                <h2 className="text-center">Fale Conosco</h2>
                </Col>
              </Row>
              <Row>
                <Col md={12} xl={12} xs={12}>
                  <SectionFaleConosco />
                </Col>
              </Row>
            </div>
          </Container>




          {/* <Container fluid style={{ height: '400px', width: '100%', marginTop: '0px', position: 'static', paddingLeft: 0 }}>
            <Hidden xs sm md>
              <div style={{ position: "absolute", left: 0, zIndex: 10, bottom: 925 }}>
                <LeftOrnament2 />
              </div>
            </Hidden>
          </Container> */}



          {/* <div style={{ height: '400px', width: '100%', marginTop: '300px', paddingLeft: 0, paddingRight: 0 }}>
            <SectionComentCli />
          </div> */}
          <Container className="tim-container">
            <h2 className="text-center">Encontre-nos</h2>
            <Row>
              <Col md={4} sm={12}>
                <div style={{ background: '#fff' }}>
                  <br />
                  <Card className="bg-gradient-warning shadow-lg border-0" >
                    <img style={{ borderRadius: '0', height: '500' }} src="./assets/images/background2.jpg" />
                  </Card>
                </div>
              </Col>
              <Col md={8} sm={12}>
                <div style={styleMaps}>
                  <br />
                  <iframe className="bg-gradient-warning shadow-lg border-0" frameBorder={0} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6646.73360598565!2d-35.44643407302684!3d-7.8771225920232455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7abbba8c0993771%3A0xc120fe8844ebe861!2sOngold%20Tech!5e0!3m2!1spt-BR!2sbr!4v1594846369797!5m2!1spt-BR!2sbr" style={{ width: '100%', height: 340 }} ></iframe>
                </div>
              </Col>
            </Row>
            <br />
          </Container>

        </Container>

      )
    }} />
  )

}

const styleMaps = {

}


