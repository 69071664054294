import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Hidden } from 'react-grid-system';
import { SolutionItem, SolutionContainer, SolutionWrapper } from './styles';
import {
  MdShoppingCart,
  MdDirectionsCar,
  MdFormatPaint,
  MdRestaurant,
  MdPersonalVideo,
  MdLocalOffer,
  MdLocalHotel
} from 'react-icons/md';
import { FaBoxes } from 'react-icons/fa';
import { useTransition, animated, useTrail } from 'react-spring';
import '../../css/Transitions.css';
/* Import Svg's */
import { ReactComponent as Bread } from '../../css/svg/bread.svg';
import { ReactComponent as ShoeFormal } from '../../css/svg/shoe-formal.svg';
import { ReactComponent as Book } from '../../css/svg/book.svg';
import { ReactComponent as Convenience } from '../../css/svg/convenience.svg';
import { ReactComponent as TowelOnHanger } from '../../css/svg/towel-on-hanger.svg';
import { ReactComponent as SharpFastFood } from '../../css/svg/sharp-fastfood.svg';
import { ReactComponent as Spray } from '../../css/svg/spray.svg';
import { ReactComponent as Diamond } from '../../css/svg/diamond.svg';



export default function Solutions(props) {
  
  //const [ isShowing, setSolutionShow ] = useState(props.visible)
  
  const rows = [
    {
      key: 0,
      child: (
        <Row nogutter>
          <Col style={{ borderRight: '1px #4D4D4D solid' }}>
            <SolutionItem>
              <Link
                to="/products/4"
							  style={{
                  textDecoration: 'none',
                  color: '#4D4D4D'
                }}
              >
                <MdShoppingCart size="2em" color="#4D4D4D" />
                <br />Supermercado e Mercadinhos
							</Link>
            </SolutionItem>
          </Col>
          <Col style={{ borderRight: '1px #4D4D4D solid' }}>
            <SolutionItem>
              <Link
               to="/products/5"
							  style={{
                  textDecoration: 'none',
                  color: '#4D4D4D',
                  // fontWeight: 'bold',
                }}
              >
                <MdDirectionsCar size="2em" color="#4D4D4D" />
                <br />Auto Peças e <br />Serviços
							</Link>
            </SolutionItem>
          </Col>
          <Col style={{ borderRight: '1px #4D4D4D solid' }}>
            <SolutionItem>
              <Link
               to="/products/6"
							  style={{
                  textDecoration: 'none',
                  color: '#4D4D4D'
                }}
              >
                <MdFormatPaint size="2em" color="#4D4D4D" />
                <br />Material de <br />Construção
							</Link>
            </SolutionItem>
          </Col>
          <Col style={{ borderRight: '1px #4D4D4D solid' }}>
            <SolutionItem>
              <Link
               to="/products/7"
							  style={{
                  textDecoration: 'none',
                  color: '#4D4D4D'
                }}
              >
                <Bread size="2em" color="#4D4D4D" />
                <br />Padarias e Confeitarias
							</Link>
            </SolutionItem>
          </Col>
          <Col style={{ borderRight: '1px #4D4D4D solid' }}>
            <SolutionItem>
              <Link
               to="/products/8"
							  style={{
                  textDecoration: 'none',
                  color: '#4D4D4D'
                }}
              >
                <ShoeFormal size="2em" color="#4D4D4D" />
                <br />Sapatarias e <br /> Bolsas
							</Link>
            </SolutionItem>
          </Col>
        </Row>
      )
    },
    {
      key: 1,
      child: (
        <Row nogutter>
          <Col style={{ borderRight: '1px #4D4D4D solid'}}>
            <SolutionItem>
              <Link 
               to="/products/9"
							  style={{
                  textDecoration: 'none',
                  color: '#4D4D4D'
                }}
              >
                <MdRestaurant size="2em" color="#4D4D4D" />
                <br />Restaurantes e Churrascarias
							</Link>
            </SolutionItem>
          </Col>
          <Col style={{ borderRight: '1px #4D4D4D solid' }}>
            <SolutionItem>
              <Link
               to="/products/10"
							  style={{
                  textDecoration: 'none',
                  color: '#4D4D4D'
                }}
              >
                <MdPersonalVideo size="2em" color="#4D4D4D" />
                <br />Móveis e Eletrodomésticos
							</Link>
            </SolutionItem>
          </Col>
          <Col style={{ borderRight: '1px #4D4D4D solid' }}>
            <SolutionItem>
              <Link
               to="/products/11"
							  style={{
                  textDecoration: 'none',
                  color: '#4D4D4D'
                }}
              >
                <ShoeFormal size="2em" color="#4D4D4D" />
                <br />Vestuário e<br /> Acessórios
							</Link>
            </SolutionItem>
          </Col>
          <Col style={{ borderRight: '1px #4D4D4D solid' }}>
            <SolutionItem>
              <Link
               to="/products/12"
							  style={{
                  textDecoration: 'none',
                  color: '#4D4D4D'
                }}
              >
                <Convenience style={{ width: '2em' }} />
                <br />Conveniência e Drugstore
							</Link>
            </SolutionItem>
          </Col>
          <Col style={{ borderRight: '1px #4D4D4D solid' }}>
            <SolutionItem>
              <Link
               to="/products/13"
							  style={{
                  textDecoration: 'none',
                  color: '#4D4D4D'
                }}
              >
                <TowelOnHanger style={{ width: '2em', paddingBottom: '3px' }} />
                <br />Cama, Mesa <br />e Banho
							</Link>
            </SolutionItem>
          </Col>
        </Row>
      )
    },
    {
      key: 2,
      child: (
        <Row nogutter>
          <Col style={{ borderRight: '1px #4D4D4D solid' }}>
            <SolutionItem>
              <Link
               to="/products/14"
							  style={{
                  textDecoration: 'none',
                  color: '#4D4D4D'
                }}
              >
                <SharpFastFood style={{ width: '2em' }} />
                <br />Food Service <br /> e Fast Food
							</Link>
            </SolutionItem>
          </Col>
          <Col style={{ borderRight: '1px #4D4D4D solid' }}>
            <SolutionItem>
              <Link
               to="/products/15"
							  style={{
                  textDecoration: 'none',
                  color: '#4D4D4D'
                }}
              >
                <MdLocalOffer size="2em" color="#4D4D4D" />
                <br />Magazines e <br />Utilidades
							</Link>
            </SolutionItem>
          </Col>
          <Col style={{ borderRight: '1px #4D4D4D solid' }}>
            <SolutionItem>
              <Link
               to="/products/16"
							  style={{
                  textDecoration: 'none',
                  color: '#4D4D4D'
                }}
              >
                <Spray style={{ width: '1.1em', marginTop: '-3px' }} />
                <br />Perfumaria e<br />Cosméticos
							</Link>
            </SolutionItem>
          </Col>
          <Col style={{ borderRight: '1px #4D4D4D solid' }}>
            <SolutionItem>
              <Link
               to="/products/17"
							  style={{
                  textDecoration: 'none',
                  color: '#4D4D4D'
                }}
              >
                <Book style={{ width: '2em', paddingTop: '3px' }} />
                <br />Livrarias e <br /> Papelarias
							</Link>
            </SolutionItem>
          </Col>
          <Col style={{ borderRight: '1px #4D4D4D solid' }}>
            <SolutionItem>
              <Link
               to="/products/18"
							  style={{
                  textDecoration: 'none',
                  color: '#4D4D4D'
                }}
              >
                <FaBoxes size="2em" color="#4D4D4D" />
                <br />Atacado
							</Link>
            </SolutionItem>
          </Col>
        </Row>
      )
    }
  ];

  
  const transitions = useTransition(rows, (item) => item.key, {
    from: { opacity: 0, transform: 'translateY(-50px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(-50px)' }
  });


  return (
    <Hidden xs sm  style={{background: '#EDD47A' }}>
      <SolutionWrapper /* style={{opacity: this.state.show}} */> 
        <SolutionContainer>
          {transitions.map(({ item, props, key }) => {
            return (
              <animated.div key={key} style={props} >
                {item.child}
              </animated.div>
            );
          })}
        </SolutionContainer>
      </SolutionWrapper>
    </Hidden>
  );
}
