import styled from 'styled-components';
import {device} from '../../../css/devices';
export const OgButtonRounded = styled.a`
    padding: 8px 40px;
    background: transparent;
    border: 2px solid #EDD47A;
    border-radius: 60px;
    color: white;
    font-family: 'Helvetica';
    font-weight: 600;
    text-decoration: none;
    transition: 0.2s ease;

    :hover{
        background: #EDD47A;
        color: black;
    }
`;

export const OgTitle = styled.h1`
    color: white;
    font-size: 3em;
    font-weight: 600;
    font-family: 'Helvetica';
`

export const Background = styled.div`
    z-index:1;    
    overflow: hidden;
    position:absolute;
    width: 100%;
    height: 400px;

    @media ${device.xs} {
            height: 600px;
        }

    ::before{
    content: ' ';
    width: 100%;
    height: 400px;
    position: absolute;
    background-image: linear-gradient(to right, rgba(0,0,0,0.9), rgba(0,0,0,0.9));
    z-index: 1;

        @media ${device.xs} {
            height: 600px;
        }
    }

  

`;

/* export const Overlay = styled.div`

::before{
    content: ' ';
    width: 100%;
    height: 650px;
    position: absolute;
    background-image: linear-gradient(to right, rgba(0,0,0,0.9), rgba(0,0,0,0.9));
    z-index: 1;
}
` */

export const Image = styled.img`
    min-width: 100%;
    position: relative;
    z-index: 0;  
`;


export const WhoWeAreH1 = styled.h1`
    color: white;
    font-size: 3.1em;
    width: 100px;
    font-family: 'Helvetica';
`

export const WhoWeAreSubtitle = styled.h2`
    color: #EDD47A;
    font-size: 1.8em;
    font-family: 'Helvetica';
    max-width: 500px;
`

export const Paragraph = styled.p`
    font-family: 'Helvetica';
    max-width: 400px;
    color: white;

    @media ${device.xs} {
        margin-bottom: 40px;
    }
`


export const SolutionsDescription = styled.p`
    font-family: 'Helvetica';
    color: #4D4D4D;
    line-height: 23px;
    
    text-align: center;

`

export const SolutionsTitle = styled.h1`
    color: #4D4D4D;
    font-size: 3.1em;    
    font-family: 'Helvetica';
    text-align: center;
`

