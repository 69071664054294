export const data = [
    {
        id: 1,
        header: {
            title: 'Supermercados e Mercadinhos',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        body: {
            solutionBenefit: '',
            feature1: '',
            feature2: '',
            feature3: '',
            feature4: '',
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }               
            ]
        }
    },
    {
        id: 2,
        header: {
            title: 'Auto Peças e Serviços',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        body: {
            solutionBenefit: '',
            feature1: '',
            feature2: '',
            feature3: '',
            feature4: '',
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }               
            ]
        }
    },
    {
        id: 3,
        header: {
            title: 'Material de Construção',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        body: {
            solutionBenefit: '',
            feature1: '',
            feature2: '',
            feature3: '',
            feature4: '',
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }               
            ]
        }
    },
    {
        id: 4,
        header: {
            title: 'Padarias e Confeitarias',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        body: {
            solutionBenefit: '',
            feature1: '',
            feature2: '',
            feature3: '',
            feature4: '',
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }               
            ]
        }
    },
    {
        id: 5,
        header: {
            title: 'Sapatarias e Bolsas',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        body: {
            solutionBenefit: '',
            feature1: '',
            feature2: '',
            feature3: '',
            feature4: '',
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }               
            ]
        }
    },
    {
        id: 6,
        header: {
            title: 'Livrarias e Papelarias',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        body: {
            solutionBenefit: '',
            feature1: '',
            feature2: '',
            feature3: '',
            feature4: '',
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }               
            ]
        }
    },
    {
        id: 7,
        header: {
            title: 'Restaurantes e Churrascarias',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        body: {
            solutionBenefit: '',
            feature1: '',
            feature2: '',
            feature3: '',
            feature4: '',
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }               
            ]
        }
    },
    {
        id: 8,
        header: {
            title: 'Móveis e Eletrodomésticos',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        body: {
            solutionBenefit: '',
            feature1: '',
            feature2: '',
            feature3: '',
            feature4: '',
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }               
            ]
        }
    },
    {
        id: 9,
        header: {
            title: 'Vestuário e Acessórios',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        body: {
            solutionBenefit: '',
            feature1: '',
            feature2: '',
            feature3: '',
            feature4: '',
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }               
            ]
        }
    },
    {
        id: 10,
        header: {
            title: 'Conveniência e Drugstore',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        body: {
            solutionBenefit: '',
            feature1: '',
            feature2: '',
            feature3: '',
            feature4: '',
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }               
            ]
        }
    },
    {
        id: 11,
        header: {
            title: 'Cama, Mesa e Banho',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        body: {
            solutionBenefit: '',
            feature1: '',
            feature2: '',
            feature3: '',
            feature4: '',
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }               
            ]
        }
    },
    {
        id: 12,
        header: {
            title: 'Atacado',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        body: {
            solutionBenefit: '',
            feature1: '',
            feature2: '',
            feature3: '',
            feature4: '',
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }               
            ]
        }
    },
    {
        id: 13,
        header: {
            title: 'Food Service e Fast Food',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        body: {
            solutionBenefit: '',
            feature1: '',
            feature2: '',
            feature3: '',
            feature4: '',
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }               
            ]
        }
    },
    {
        id: 14,
        header: {
            title: 'Magazines e Utilidades',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        body: {
            solutionBenefit: '',
            feature1: '',
            feature2: '',
            feature3: '',
            feature4: '',
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }               
            ]
        }
    },
    {
        id: 15,
        header: {
            title: 'Perfumaria e Cosméticos',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        body: {
            solutionBenefit: '',
            feature1: '',
            feature2: '',
            feature3: '',
            feature4: '',
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }               
            ]
        }
    },
    {
        id: 16,
        header: {
            title: 'Hotéis e Pousadas',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        body: {
            solutionBenefit: '',
            feature1: '',
            feature2: '',
            feature3: '',
            feature4: '',
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }               
            ]
        }
    },
    {
        id: 17,
        header: {
            title: 'Joalheria e Bijuteria',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
        },
        body: {
            solutionBenefit: '',
            feature1: '',
            feature2: '',
            feature3: '',
            feature4: '',
            video: '',
            slides: [
                {
                    id: 1,
                    title: 'DFE',
                    img: '../assets/images/products/dfe_cover.png'
                },
                {
                    id: 2,
                    title: 'GERENCY VENDAS MOBILE',
                    img: '../assets/images/products/gerency_mobile.png'
                },
                {
                    id: 3,
                    title: 'GERENCY NFCe',
                    img: '../assets/images/products/nfce_cover.png'
                }               
            ]
        }
    },
]