import styled from 'styled-components';
import {Link} from 'react-router-dom'
import {Container as ContainerGridSystem} from 'react-grid-system'
import {device} from '../../../../css/devices'


export const Container = styled(ContainerGridSystem)`
    display: flex; 
    flex-direction: row; 
    flex-flow: 'wrap';

    @media ${device.xs} {
        flex-flow: column;

    }

`

export const SoftwareWrapper = styled.div`
    display: flex;
    flex-direction: row;
`
export const LoginForm = styled.div`
   
   
`
export const LoginField = styled.div`
   display:flex;
   flex-direction: column;
   margin-bottom: 20px;

   span {
       margin-bottom: 5px;
   }
   
   input {
       height: 35px;       
       font-size: 20px;
       -webkit-appearance: none;
       background: white;
       border: none;
       outline: none;
   }
`
export const Background = styled.div`
    position: absolute;
    top:0;
    width: 100%;
    height: 200px;
    z-index: -10000;
    background: #e0e0e0;
`



export const OgButtonRounded = styled(Link)`
    padding: 8px 40px;
    background: transparent;
    border: 3px solid #EDD47A;
    border-radius: 60px;
    color: #4D4D4D;
    font-family: 'Helvetica';
    font-size: 14px;
    text-decoration: none;
    transition: 0.2s ease;
    cursor: pointer;

    :hover{
        background: #EDD47A;
        color: #4D4D4D;
    }
`;

export const SoftwareCard = styled.div`
 width: 330px;
 height: 280px;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 text-align: center;
 
 a {
    padding: 8px 40px;
    background: transparent;
    border: 3px solid #EDD47A;
    border-radius: 60px;
    color: #4D4D4D;
    font-family: 'Helvetica';
    font-size: 2.3vh;
    text-decoration: none;
    transition: 0.2s ease;
    cursor: pointer;

    :hover{
        background: #EDD47A;
        color: #4D4D4D;
    }
 }

`
export const SoftwareCardHeader = styled.div`
 width: 100%;

`
export const SoftwareCardBottom = styled.div`
    height: 50px;
`
export const Image = styled.img`
  height: 64px;
`;


