import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom'
import { Container, Row, Col, Hidden, ScreenClassRender } from 'react-grid-system';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdAdd, MdDone } from 'react-icons/md'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { ReactComponent as LeftOrnamentGiant } from '../../../../css/svg/solution_lines_left.svg'
import { data as productsInfo } from "../Products/productsInfo"
import Truncate from 'react-truncate';
import {
  Card,
  CardPlans,
  CardHeader,
  CardImage,
  CardBody,
  CardTitle,
  CardSubTitle,
  CardText,
} from "../Products/styles"
import {
  SolutionBenefitsWrapper, SolutionsFeaturesWrapper, OgTitle, Subtitle, BusinessImage,
  ButtonSlideBack, ButtonSlideNext, SlideContent, SlideItem
} from './styles'
import { Flip, Fade } from 'react-reveal'

import SuggestionForm from '../../../SuggestionForm'
import { data } from './solutionsList.js'

// import { Container } from './styles';

class Solution extends Component {


  componentDidMount() {
    console.log(this.props.match.params.IdSolution)

    const test = data.filter(solucao => solucao.id == this.props.match.params.IdSolution)
    console.log(test)

  }

  render() {
    return (
      <ScreenClassRender render={screenClass => (
        data.filter(solucao => solucao.id == this.props.match.params.IdSolution).map(solucao => (
          <>


            <Container fluid >
              <Row style={{ height: ['xs', 'sm'].includes(screenClass) ? '350px' : '671px' }}>
                <Hidden xs sm>
                  <Col md={1} style={{ marginRight: '45px', paddingLeft: 0 }}>
                    <LeftOrnamentGiant style={{
                      position: 'absolute',
                      marginTop: '15px'
                    }} />
                  </Col>
                </Hidden>
                <Col style={{ marginTop: ['xs', 'sm'].includes(screenClass) ? '20px' : '100px', paddingLeft: '20px' }}>
                  <Row >
                    <Col md={4} sm={4} xl={4}>
                      <Flip top>
                        <OgTitle>
                          {solucao.header.title}
                        </OgTitle>
                      </Flip>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '-50px' }}>
                    <Col md={5}>
                      <Subtitle>
                        <Fade delay={1000}>
                          {solucao.header.subtitle}
                        </Fade>
                      </Subtitle>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Container style={{ padding: '70px 0px' }}>
                <SolutionBenefitsWrapper>
                  <Row>
                    <Col md={12} xl={12} xs={12}>
                      <Fade top>
                        <h2 style={{
                          textAlign: 'center',
                          fontSize: '2.3em',
                          color: '#4D4D4D'
                        }}>  Benefícios da Solução </h2>
                      </Fade>
                    </Col>
                  </Row>
                  <Row style={{ justifyContent: 'center' }}>
                    <Col md={10} xl={10} xs={12}>
                      <Fade delay={700}>
                        <p style={{
                          textAlign: 'center',
                          fontSize: '1em',
                          color: '#4D4D4D'
                        }}>
                          {solucao.body.solutionBenefit}
                        </p>
                      </Fade>
                    </Col>
                  </Row>
                </SolutionBenefitsWrapper>
              </Container>

              <Container fluid style={{ paddingTop: '00px' }}>
                <SolutionsFeaturesWrapper>
                  <Row style={{ alignItems: ['xs', 'sm'].includes(screenClass) ? 'flex-start' : 'center' }}>

                    <Col md={6} xl={6} xs={12}>
                      <Fade left>
                        <BusinessImage src='../assets/images/business3.jpg' />
                      </Fade>
                    </Col>

                    <Col md={6} xl={6} style={{ paddingRight: ['xs', 'sm'].includes(screenClass) ? '0' : '120px' }}>
                      {solucao.body.features.map((item, i) => {
                        if (i <= 2) {
                          if (item.list) {
                            return (
                              <ul style={{ listStyle: 'none', fontSize: '1rem' }}>
                                {item.list.map((item, i) => (
                                  <li style={{ marginBottom: 10 }}><MdDone size={24} />{item}</li>
                                )
                                )}
                              </ul>
                            )
                          }
                          return (
                            <Flip top>
                              <Row style={{ justifyContent: 'center', textAlign: 'justify' }}>
                                <Col xl={9} xs={12}>
                                  <h2 style={{ color: '#4D4D4D' }}>{item.title || ''}</h2>
                                  <p style={{ color: '#4D4D4D', fontSize: '1rem' }}>{item.text || ''}</p>
                                </Col>
                              </Row>
                            </Flip>
                          )
                        }
                      })}

                    </Col>
                  </Row>
                </SolutionsFeaturesWrapper>
              </Container>

              <Container fluid style={{ paddingTop: '40px' }}>

                {solucao.body.features.length > 3 && <SolutionsFeaturesWrapper>
                  <Row style={{ alignItems: 'center' }}>
                    <Col md={6} xl={6} >
                      {solucao.body.features.map((item, i) => {
                        if (i > 2) {
                          return (

                            <Flip top>
                              <Row style={{ justifyContent: 'center', textAlign: 'justify' }}>
                                <Col xl={7} xs={12}>
                                  <h2 style={{ color: '#4D4D4D' }}>{item.title || ""}</h2>
                                  <p style={{ color: '#4D4D4D', fontSize: '1rem' }}>{item.text || ""}</p>
                                </Col>
                              </Row>
                            </Flip>
                          )
                        }
                      })}
                    </Col>

                    <Col md={6} xl={6} xs={12}>
                      <Fade right>
                        <BusinessImage style={{ backgroundPositionX: 'right' }} src='../assets/images/business4.jpg' />
                      </Fade>
                    </Col>

                  </Row>
                </SolutionsFeaturesWrapper>}

              </Container>

              <Container style={{ paddingTop: '40px' }}>
                <Row style={{ justifyContent: 'center' }}>
                  <Col xs={12} xl={10}>
                    <p style={{ color: '#4D4D4D', textAlign: 'center', fontSize: '1.3rem' }}>{solucao.body.finishing || ""}</p>
                  </Col>
                </Row>
              </Container>
            </Container>

            <Container fluid style={{ padding: '50px 0 ', marginTop: '50px', backgroundColor: '#F2F2F2' }}>
              <Container fluid style={{ padding: '0 50px' }}>
                <Row>

                  <Col xl={12} xs={12}>
                    <h1 style={{ textAlign: 'center', fontFamily: 'Helvetica', color: '#4D4D4D' }}>Produtos</h1>
                  </Col>
                  <Col xl={12} xs={12}>
                    <Container>
                      <CarouselProvider
                        naturalSlideWidth={250}
                        naturalSlideHeight={200}
                        totalSlides={productsInfo.length}                        
                        visibleSlides={['xs', 'sm'].includes(screenClass) ? 1 : 3}
                      >
                        <Slider>
                          {productsInfo.map((slide, i) => (
                            <Link to={`/products/${slide.name}`} style={{ textDecoration: 'none' }}>
                              <Slide index={slide.id} key={slide.id}>
                                <SlideContent>
                                  <SlideItem image={slide.image} />
                                  <div>
                                    <h4 style={{color: 'black'}}>{slide.title}</h4>
                                  </div>
                                </SlideContent>
                              </Slide>
                            </Link>
                          ))}

                        </Slider>

                        <ButtonSlideBack >
                          <MdKeyboardArrowLeft size="2.2em" color="#4D4D4D" />
                        </ButtonSlideBack>
                        <ButtonSlideNext>
                          <MdKeyboardArrowRight size="2.2em" color="#4D4D4D" />
                        </ButtonSlideNext>
                      </CarouselProvider>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Container>
            <Container fluid>
              <SuggestionForm />
            </Container>
          </>
        ))

      )} />
    );
  }
}


export default withRouter(Solution);