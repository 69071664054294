import styled from 'styled-components';

export const Input = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid #4D4D4D;
  font-size: 1.2em;
  padding-left: 5px;

`;

export const Textarea = styled.textarea`
  width: 100%;
  max-width: 100%;
  border: 1px solid #4D4D4D;
  font-size: 1.2em;
  font-family: 'Helvetica';

`;

export const Span = styled.span`
    font-size: 0.9em;
    color: #4D4D4D
`;


export const OgButtonRounded = styled.button`
    padding: 8px 40px;
    background: transparent;
    border: 3px solid #EDD47A;
    border-radius: 60px;
    color: black;
    font-family: 'Helvetica';
    font-weight: 600;
    font-size: 1.1em;
    text-decoration: none;
    transition: 0.2s ease;
    cursor: pointer;

    :hover{
        background: #EDD47A;
        color: black;
    }
`;

export const OrnamentWrapper = styled.div`
    position: absolute;
    left: -80px;
    height: 457px;
    display:flex;

`
export const SingleOrnament = styled.div`
    width: 10px;
    height: 100%;
    background: #EDD47A;
    margin-right: 30px;
`

export const ContactResult = styled.div`
    display: ${props=>!props.isShowing ? 'block' : 'none'};
    text-align: center;
    h1 {
        font-size: 7vh;
        color: #4D4D4D;
        text-align: center;
    }

    h3 {
        font-size: 4vh;
        color: #4D4D4D;
    }

    p{
        font-size: 2vh;
    }
`

export const ContactForm = styled.form`
    display: ${props=>props.isShowing ? 'block' : 'none'};
`

export const Image = styled.img`
  width: 20%;
  height: auto;
`