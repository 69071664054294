import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Hidden, ScreenClassRender } from 'react-grid-system';
import { MdCallEnd, MdMailOutline, MdPlace } from 'react-icons/md'
import { ReactComponent as Facebook } from '../../css/svg/facebook.svg'
import { ReactComponent as Instagram } from '../../css/svg/instagram.svg'
import { ReactComponent as Twitter } from '../../css/svg/twitter.svg'
import { ReactComponent as RightBottomOrnament } from '../../css/svg/bottom-right-ornament.svg'
import { ReactComponent as LogoBlack } from '../../css/svg/ongold_black.svg'
import { Logo, SocialIcons, BottomMenu, BottomMenuItem, ContactUl, ContactLi } from './styles';
import { FaWhatsapp } from "react-icons/fa"
import { NavLink } from "reactstrap";

export default class Bottom extends Component {
  render() {
    return (
      <ScreenClassRender render={screenClass => (

        <Container fluid style={{
          backgroundImage: 'linear-gradient(to right, #EDD47A, #EDD47A)',
          marginTop: '15px',
          fontFamily: 'Helvetica',
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            marginLeft: '10rem'
          }}>
            <Row style={{ alignItems: 'center', justifySelf: 'center' }}>
              <Col md={3} xl={3} xs={12} style={{justifyContent: 'center'}}>
                {/* <Logo src={} style={{width: '300px'}}/> */}
                <LogoBlack />
              </Col>
              <Col>
                <h4 style={{fontFamily: 'Helvetica', fontWeight: 600}}></h4>
                <ContactUl style={{
                  listStyle: 'none',
                  paddingLeft: 0,  
                }}> 
                  <ContactLi>
                    {/* https://wa.me/558136286521 */}
                    <SocialIcons>

                      <a href="https://wa.me/558195664008 " target="_blank">
                        <FaWhatsapp
                          size={24}
                          style={{
                            marginRight: '10px',
                            color: 'rgb(77, 77, 77)'

                          }} />
                      </a>
                      <a href="https://www.facebook.com/OnGoldTecnologia/" target="_blank"><Facebook style={{ marginRight: '10px' }} /></a>
                      <a href="https://twitter.com/ongold_tech" target="_blank"><Twitter style={{ marginRight: '10px', }} /></a>
                      <a href="https://www.instagram.com/ongoldtech" target="_blank"><Instagram style={{ marginRight: '10px' }} /></a>



                    </SocialIcons>
                  </ContactLi>
                  <ContactLi>
                    <MdCallEnd size="1.9em" style={{ marginRight: '10px' }} /> <span>+55 81 3628 - 6521</span>
                  </ContactLi>
                  <ContactLi>
                    <MdMailOutline size="1.9em" style={{ marginRight: '10px' }} /> <span>contato@ongoldtech.com</span>
                  </ContactLi>
                  <ContactLi>
                    <MdPlace size="1.9em" style={{ marginRight: '10px' }} /> <span>Avenida Severino Pinheiro, Centro<br /> Limoeiro-PE, Nº 470</span>
                  </ContactLi>
                </ContactUl>
              </Col>
              <Hidden xs sm>
                <Col>
                  {/* <h4>Navegação</h4> */}
                  <BottomMenu>
                    <NavLink href="/" style={{ textDecoration: 'none' }}><BottomMenuItem>Início</BottomMenuItem></NavLink>
                    <NavLink href="/#sobreOngold" style={{ textDecoration: 'none' }}><BottomMenuItem>Sobre a Ongold</BottomMenuItem></NavLink>
                    <NavLink href="/#segmentos" style={{ textDecoration: 'none' }}><BottomMenuItem>Segmentos</BottomMenuItem></NavLink>
                    <NavLink href="/#faleconosco" style={{ textDecoration: 'none' }}><BottomMenuItem>Fale Conosco</BottomMenuItem></NavLink>
                    <NavLink href="/login" style={{ textDecoration: 'none' }}><BottomMenuItem>Área do Lojista</BottomMenuItem></NavLink>
                    <NavLink href="/parceiro" style={{ textDecoration: 'none' }}><BottomMenuItem>Área do Parceiro</BottomMenuItem></NavLink>
                    {/* <Link to="/" style={{ textDecoration: 'none' }}><BottomMenuItem>Início</BottomMenuItem></Link>
                    <Link to="/#segmentos" style={{ textDecoration: 'none' }}><BottomMenuItem>Segmentos</BottomMenuItem></Link>
                    <Link to="/#faleconsoco" style={{ textDecoration: 'none' }}><BottomMenuItem>Fale Conosco</BottomMenuItem></Link>
                    <Link to="/login" style={{ textDecoration: 'none' }}><BottomMenuItem>Área do Lojista</BottomMenuItem></Link>
                    <Link to="/parceiro" style={{ textDecoration: 'none' }}><BottomMenuItem>Área do Parceiro</BottomMenuItem></Link> */}
                  </BottomMenu>
                </Col>
                <Col style={{
                  paddingRight: 0,
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}>
                  <RightBottomOrnament />
                </Col>
              </Hidden>
            </Row>
          </div>
        </Container>
      )} />
    );
  }
}
