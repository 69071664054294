import styled from 'styled-components';

export const TablePlan = styled.table`
	width: 100%;
	border-collapse: collapse;
	overflow-x: auto;
	tr {
	}

	td {
		&:nth-child(2) {
			background: #f0f0f0;
			background: linear-gradient(90deg, #f0f0f0 0%, #fff 100%);
		}
		&:nth-child(3) {
			background: #e3984d;
			background: linear-gradient(90deg, #e3984d 0%, #be7f42 100%);
		}
		&:nth-child(4) {
			background: rgb(218, 158, 16);
			background: linear-gradient(90deg, #cacaca 0%, #929292 100%);
		}
		&:nth-child(5) {
			background: rgb(218, 158, 16);
			background: linear-gradient(90deg, rgba(218, 158, 16, 1) 0%, rgba(255, 199, 53, 1) 100%);
		}   
	}
`;
export const TableHeader = styled.thead`
	tr {
		th {
			width: 150px;
			height: 30px;
			padding: 15px;
			&:nth-child(1) {
				width: 200px;
				padding-left: 0;
			}

			h2 {
				margin: 0px;
			}

			&:nth-child(2) {
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;

				/*  background: rgb(28,26,26);
            background: radial-gradient(circle, rgba(28,26,26,1) 0%, rgba(9,9,9,1) 100%); */
				background: #f0f0f0;
				background: linear-gradient(90deg, #f0f0f0 0%, #fff 100%);

				h2 {
					color: rgb(28, 26, 26);
					margin: 0px;
				}
				span {
					color: #f0f0f0;
				}
			}
			&:nth-child(3) {
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;

				/*  background: rgb(28,26,26);
            background: radial-gradient(circle, rgba(28,26,26,1) 0%, rgba(9,9,9,1) 100%); */
				background: #e3984d;
				background: linear-gradient(90deg, #e3984d 0%, #be7f42 100%);

				h2 {
					color: rgb(28, 26, 26);
					margin: 0px;
				}
				span {
					color: #a46628;
				}
			}
			&:nth-child(4) {
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;

				/*  background: rgb(28,26,26);
            background: radial-gradient(circle, #929292 0%, #CACACA 100%); */
				background: rgb(218, 158, 16);
				background: linear-gradient(90deg, #cacaca 0%, #929292 100%);

				h2 {
					color: rgb(28, 26, 26);
					margin: 0px;
				}
				span {
					color: #956f0e;
				}        
			}
			&:nth-child(5) {
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
				background: rgb(218, 158, 16);
				background: linear-gradient(90deg, rgba(218, 158, 16, 1) 0%, rgba(255, 199, 53, 1) 100%);

				h2 {
					color: rgb(28, 26, 26);
					margin: 0px;
				}
				span {
					color: #956f0e;
				}
			}
		}
	}
	text-align: left;
	h2 {
		margin-bottom: 0;
	}
	span {
		color: #b3b3b3;
		font-size: 0.9rem;
	}
`;
export const TableBody = styled.tbody`
	tr {
		td {
			padding-top: 4px;
			padding-bottom: 4px;
			padding-left: 10px;
			padding-right: 10px;
			text-align: center;
      font-size: 1.1rem;

			&:nth-child(1) {
				text-align: left;
			}
		}

		&:nth-child(odd) {
			background: #e3e3e3;
		}
	}
`;
export const TableFooter = styled.tfoot`
	/* td{
    &:nth-child(2,4){
      height: 20px;
      background: rgb(218,158,16);
      background: linear-gradient(90deg, rgba(218,158,16,1) 0%, rgba(255,199,53,1) 100%);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  } */
`;
