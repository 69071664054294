import React, { Component } from 'react';
import { withRouter, Link, useParams } from 'react-router-dom';
import { Container, Row, Col, Hidden, ScreenClassRender } from 'react-grid-system';
import DemoForm from '../../../DemoForm';
import TablePlans from './Components/TablePlans';
import { ReactComponent as LeftOrnamentGiant } from '../../../../css/svg/solution_lines_left.svg';
import { ReactComponent as LeftOrnament } from '../../../../css/svg/left_ornament.svg';
import { ReactComponent as IcBarcode } from '../../../../css/svg/mdBarcode.svg';
import { ReactComponent as IcCertified } from '../../../../css/svg/mdCertified.svg';
import { ReactComponent as IcSupport } from '../../../../css/svg/mdSupport.svg';
import { ReactComponent as IcAddUser } from '../../../../css/svg/mdAddUser.svg';
import {
  Image,
  ProductTitle,
  ProductSubTitle,
  ProductText,
  ProductBenefitTitle,
  BenefitItem,
  FunctionalityItem,
  StyledH1,
  StyledH2,
  StyledH3,
  StyledP
} from './styles';

import { Flip, Fade } from 'react-reveal';

import { data as produtos } from './productsInfo.js';
import { IoMdCheckboxOutline } from 'react-icons/io';
import { Button } from 'reactstrap';
import '../../../../../src/main.css';

// import { Container } from './styles';

function Product(props) {
  const { produto: product = {} } = useParams();

  return (
    <ScreenClassRender
      render={(screenClass) =>
        produtos.filter((produto) => produto.name == props.match.params.produto).map((produto) => (
          <React.Fragment>
            <Container
              fluid
              style={{
                marginTop: ['xs', 'sm'].includes(screenClass) ? '70px' : 0,
                fontFamily: 'Helvetica',
                paddingRight: 0,
                paddingLeft: 0
              }}
            >
              <Hidden xs sm>
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 100
                  }}
                >
                  <Fade delay={700} cascade left>
                    <LeftOrnamentGiant style={{ position: 'fixed' }}  className='oculto4'/>
                  </Fade>
                </div>
              </Hidden>
              <Fade>
                <Container
                  fluid
                  style={{
                    width: ['xs', 'sm'].includes(screenClass) ? '100%' : 'calc(100% - 86px)',
                    float: ['xs', 'sm'].includes(screenClass) ? 'left' : 'right',
                    overflow: 'hidden',
                    background: produto.lightTheme ? '#FFF' : 'none',
                    padding: ['xs', 'sm'].includes(screenClass) ? 5 : 63,
                    marginTop: '4rem'
                  }}
                >
                  <Container fluid={['xl'].includes(screenClass)} >
                    <Row>
                      <Col xl={6} md={6} xs={12}>
                        <div>
                          <Fade delay={1000} top>
                            <div style={{ fontSize: '2.6em', color: produto.lightTheme ? '#000' : '#EDD47A' }}>{produto.title}</div>
                          </Fade>
                        </div>
                        <div>
                          <Fade delay={500} top style={{color: produto.lightTheme ? '#000' : '#FFF' }}>
                            {produto.subTitle}
                          </Fade>
                        </div>
                        <div>
                          <Fade delay={500} top style={{ color: '#000', fontWeight: 'bold', color: produto.lightTheme ? '#000' : '#FFF' }}>
                            {produto.text}
                          </Fade>
                        </div>
                      </Col>
                      <Col xl={6} md={6} xs={12}>
                        <Fade cascade delay={1500} right>
                          <Image
                            src={produto.image}
                            style={{
                              width: ['xs', 'sm'].includes(screenClass) ? '100%' : 800
                            }}
                          />
                        </Fade>
                      </Col>
                    </Row>
                  </Container>

                  <Container style={{ marginTop: ['xs', 'sm'].includes(screenClass) ? 0 : '50px', marginBottom: '50px' }}>
                    <Row style={{ marginBottom: 10 }}>
                      <Col xs={12}>
                        <h1 style={{ marginBottom: 5 }}>{produto.functionalitiesTitle}</h1>
                        <span style={{ fontSize: '1.5rem' }} >{produto.functionalitiesSubtitle}</span>
                      </Col>
                    </Row>
                    <Row>
                      {produto.functionalitiesItems.map((item) => (
                        <Col xs={12} md={4} xl={4}>
                          <FunctionalityItem>
                            <div
                              style={{
                                marginRight: 10
                              }}
                            >
                              <IoMdCheckboxOutline size={30} />
                            </div>
                            <h4>{item}</h4>
                          </FunctionalityItem>
                        </Col>
                      ))}
                    </Row>
                  </Container>
                  {product != 'gmex' && (
                    <Container style={{ marginTop: 90 }}>
                      <Row>
                        <Col xs={12} xl={12}>
                          <ProductBenefitTitle style={{fontSize: '3rem', }}>Fique por dentro de tudo que acontece na sua empresa</ProductBenefitTitle>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          display: 'flex',
                          flexDirection: 'row'
                          //justifyContent: 'center',
                        }}
                      >
                        {produto.benefits &&
                          produto.benefits.map((benefit) => (
                            <Col sm={6} xs={12} md={4} xl={4}>
                              <BenefitItem>
                                <div>{benefit.icon}</div>
                                <span>{benefit.text}</span>
                              </BenefitItem>
                            </Col>
                          ))}
                      </Row>
                    </Container>
                  )}
                </Container>
              </Fade>
            </Container>
            <Container
              fluid
              style={{
                paddingLeft: ['xs', 'sm'].includes(screenClass) ? 0 : 100,
                paddingRight: ['xs', 'sm'].includes(screenClass) ? 0 : 100,
                backgroundImage: 'url(../../assets/images/product_benefit_background.png)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                overflowX: 'hidden'
              }}
            >
              <Container
                style={{
                  width: ['xs', 'sm'].includes(screenClass) ? '100%' : 'calc(100% - 86px)',

                  padding: ['xs', 'sm'].includes(screenClass) ? '70px 20px' : '200px 10px'
                }}
              >
                <Row
                  style={{
                    display: 'flex',
                    justifyContent: ['xs', 'sm'].includes(screenClass) ? "flex-start" : 'space-between',
                    alignItems: ['xs', 'sm'].includes(screenClass) ? 'flex-start' : 'center'
                  }}
                >
                  <Col xs={12} xl={5} md={5}>
                    <Fade top>
                      <StyledH1 width="100%" size={45} color="white">
                        {produto.secondaryTitle.toUpperCase() || ''}
                      </StyledH1>
                    </Fade>
                    <StyledH2 size={3} color="#EDD47A">
                      <Fade top delay={300}>{produto.secondarySubtitle || ''}</Fade>
                    </StyledH2>
                  </Col>
                  <Col xs={12} xl={5} md={5}>
                    <StyledP size={19} color="white">
                      <Fade delay={700}>{produto.secondaryText || ''}</Fade>
                    </StyledP>
                  </Col>
                </Row>
              </Container>
            </Container>

            {/* {product == 'gerencyfinanceiro' && (
              <Container style={{ marginTop: ['xs', 'sm'].includes(screenClass) ? 0 : '50px', marginBottom: '50px' }}>
                <Row style={{ marginBottom: 10 }}>
                  <Col xs={12}>
                    <h1 style={{ marginBottom: 5 }}>{produto.functionalitiesTitle}</h1>
                    <span>{produto.functionalitiesSubtitle}</span>
                  </Col>
                </Row>
                <Row>
                  {produto.functionalitiesItems.map((item) => (
                    <Col xs={12} md={4} xl={4}>
                      <FunctionalityItem>
                        <div
                          style={{
                            marginRight: 10
                          }}
                        >
                          <IoMdCheckboxOutline size={30} />
                        </div>
                        <h4>{item}</h4>
                      </FunctionalityItem>
                    </Col>
                  ))}
                </Row>
              </Container>
            )} */}

                  {product == 'gmex' && (
                    <Container style={{ marginTop: 50 }}>
                      <Row>
                        <Col xs={12} xl={12}>
                          <ProductBenefitTitle>Benefícios do Produto</ProductBenefitTitle>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          display: 'flex',
                          flexDirection: 'row'
                        }}
                      >
                        {produto.benefits &&
                          produto.benefits.map((benefit) => (
                            <Col sm={6} xs={12} md={4} xl={4}>
                              <BenefitItem>
                                <div>{benefit.icon}</div>
                                <span>{benefit.text}</span>
                              </BenefitItem>
                            </Col>
                          ))}
                      </Row>
                    </Container>
                  )}
                  
            <Container style={{ marginTop: 50 }}>
                    <Row style={{ marginTop: 25 }}>
                      <Col xl={12} xs={12}>
                        {product == 'gmex' && (
                          <TablePlans />
                        )}
                      </Col>
                    </Row>
                  </Container>
            <Container style={{ marginTop: '50px', marginBottom: '50px' }}>
              <Hidden xs sm>
                <div
                  style={{
                    position: 'absolute',
                    bottom: -54,
                    left: '-220px',
                    zIndex: 10
                  }}
                >
                  {/* <LeftOrnament /> */}
                </div>
              </Hidden>
              <DemoForm product={produto.title} />
            </Container>
          </React.Fragment>
        ))}
    />
  );
}
/* class Product extends Component {
  render() {
    console.log(this.props.match.params.produto);
    return (
    	
    );
  }
} */

export default withRouter(Product);
