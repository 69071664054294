import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Container, Row, Col, Hidden, ScreenClassRender } from 'react-grid-system';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdAdd } from 'react-icons/md'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { ReactComponent as LeftOrnamentGiant } from '../../../../css/svg/solution_lines_left.svg'
import {
    SolutionBenefitsWrapper, SolutionsFeaturesWrapper, OgTitle, Subtitle, BusinessImage,
    ButtonSlideBack, ButtonSlideNext, SlideContent, SlideItem, CardBlack, CardGold
} from './styles'
import { Flip, Fade } from 'react-reveal'

import SuggestionForm from '../../../SuggestionForm'
import { data } from './solutionsList.js'

// import { Container } from './styles';

class DigitalManagement extends Component {


    componentDidMount() {
        console.log(this.props.match.params.IdSolution)

        const test = data.filter(solucao => solucao.id == this.props.match.params.IdSolution)
        console.log(test)

    }

    render() {
        return (
            <ScreenClassRender render={screenClass => (
                <>
                    <Container fluid style={{ background: 'white', marginTop: ['xs', 'sm'].includes(screenClass) ? '70px' : 0 }}>
                        {/* <Row style={{ height: ['xs', 'sm'].includes(screenClass) ? '350px' : '671px' }}>
                            <Hidden xs sm>
                                <Col md={1} style={{ marginRight: '45px', paddingLeft: 0 }}>
                                    <LeftOrnamentGiant style={{
                                        position: 'absolute',
                                        marginTop: '15px'
                                    }} />
                                </Col>
                            </Hidden>
                            <Col style={{ marginTop: ['xs', 'sm'].includes(screenClass) ? '20px' : '100px', paddingLeft: '20px' }}>
                                <Row >
                                    <Col md={4} sm={4} xl={4}>
                                        <Flip top>
                                            <OgTitle>
                                                TEST
                                            </OgTitle>
                                        </Flip>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '-50px' }}>
                                    <Col md={5}>
                                        <Subtitle>
                                            <Fade delay={1000}>
                                                TEST
                                            </Fade>
                                        </Subtitle>
                                    </Col>
                                </Row>
                            </Col>
                        </Row> */}
                        <Container style={{ padding: '20px 0px' }}>
                            <SolutionBenefitsWrapper>
                                <Row>
                                    <Col md={12} xl={12} xs={12}>
                                        <Fade top>
                                            <h2 style={{
                                                textAlign: 'center',
                                                fontSize: '2.3em',
                                                color: '#4D4D4D'
                                            }}>  Gestão Empresarial </h2>
                                        </Fade>
                                    </Col>
                                </Row>
                                <Row style={{ justifyContent: 'center' }}>
                                    <Col md={10} xl={10} xs={12}>
                                        <Fade delay={700}>
                                            <p style={{
                                                textAlign: 'center',
                                                fontSize: '1em',
                                                color: '#4D4D4D'
                                            }}>
                                                ERP (Sistema de Gestão Empresarial) é a base da transformação digital do seu negócio.
                                                A tecnologia esta mudando rápidamente a forma como as pessoas comuns conduzem
                                                suas vidas no dia a dia e o modo como as empresas são gerenciadas.
                                                Essas mudanças estão basedas em dois importantes temas: ERP (Sistema de Gestão Empresarial)
                                                e Transformação Digital</p>
                                        </Fade>
                                    </Col>
                                </Row>
                            </SolutionBenefitsWrapper>
                        </Container>

                        <Container fluid >
                            <SolutionsFeaturesWrapper>
                                <Row style={{ alignItems: ['xs', 'sm'].includes(screenClass) ? 'flex-start' : 'center' }}>

                                    <Col md={6} xl={6} xs={12}>
                                        <Fade left>
                                            <BusinessImage src='../assets/images/business3.jpg' />
                                        </Fade>
                                    </Col>

                                    <Col md={6} xl={6} style={{ paddingRight: ['xs', 'sm'].includes(screenClass) ? '0' : '110px' }}>
                                        <Row style={{ justifyContent: 'center' }}>
                                            <Col xl={10} xs={12}>
                                                <Flip top>
                                                    <h2 style={{ color: '#4D4D4D' }}>O qué é Transformação Digital nos negócios?</h2>
                                                    <p style={{ color: '#4D4D4D', fontSize: '1em', textAlign: 'justify' }}>
                                                    &emsp;&emsp; A transformação digital é o processo de integrar tecnologia digital a todos os aspectos da empresa,
                                                        o que exige mudanças fundamentais de tecnologia, cultura, operações e entrega de valor.
                                                        
                                                    </p>
                                                    <p style={{ color: '#4D4D4D', fontSize: '1em', textAlign: 'justify' }}>
                                                    &emsp;&emsp;A integração de informações entre os setores estratégicos das empresas é um trunfo da Transformação Digital.
                                                        Na era digital, todos os caminhos levam à otimização, começando pela automação de funções.
                                                        Nesse sentido, um sistema de gestão integrado, é o que os negócios precisam para alcançar mais competitividade.
                                                        
                                                    </p>
                                                </Flip>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                            </SolutionsFeaturesWrapper>
                        </Container>

                        <Container fluid style={{ paddingTop: '40px' }}>

                            <SolutionsFeaturesWrapper>
                                <Row style={{ alignItems: 'center' }}>
                                    <Col md={6} xl={6} >
                                        <Row>
                                            <Col xl={10} xs={12}>
                                                <Flip top>
                                                    <h2 style={{ color: '#4D4D4D' }}>Qual o papel do ERP no novo contexto empresarial e na transformação Digital?</h2>
                                                    <p style={{ color: '#4D4D4D', fontSize: '1em', textAlign: 'justify' }}>
                                                    &emsp;&emsp;O ERP (Sistema de Gestão Empresarial), enquanto tecnologia de gestão, é a chave para uma operação mais enxuta,
    transparente e produtiva. Na atualidade é impensável que as empresas prosperem num cenário em que impera
    a falta de controle operacional e a falta de suporte às decisões estratégicas.
    
                                                    </p>
                                                    <p style={{ color: '#4D4D4D', fontSize: '1em', textAlign: 'justify' }}>
                                                    &emsp;&emsp;A ausência de informações confiáveis, é um risco sério à saúde da organização — e o ERP desponta como a ferramenta ideal para corrigir esse desvio.
    Com a capacidade de organizar dados, de integrar as mais diversas áreas de negócio e
    de munir as lideranças com relatórios transparentes (gerados em tempo real),
    o ERP coloca a tecnologia de gestão no centro da operação.
    
    
                                                    </p>
                                                    <p style={{ color: '#4D4D4D', fontSize: '1em', textAlign: 'justify' }}>
                                                    &emsp;&emsp;Esse deslocamento, por sua vez, proporciona resultados a curto, médio e longo prazos:
    há mais controle nos processos e mais disponibilidade de informações
    para que os gestores deliberem de forma crítica e segura.
                                                    </p>
                                                </Flip>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={6} xl={6} xs={12}>
                                        <Fade right>
                                            <BusinessImage style={{ backgroundPositionX: 'right' }} src='../assets/images/business4.jpg' />
                                        </Fade>
                                    </Col>

                                </Row>
                            </SolutionsFeaturesWrapper>

                        </Container>
                        <Container style={{ padding: '40px 0px' }}>
                            <SolutionBenefitsWrapper>
                                <Row>
                                    <Col md={12} xl={12} xs={12}>
                                        <Fade top>
                                            <h2 style={{
                                                textAlign: 'center',
                                                fontSize: '2.0em',
                                                color: '#4D4D4D'
                                            }}>  Quais são os benefícios do ERP <br />na transformação digital? </h2>
                                        </Fade>
                                    </Col>
                                </Row>
                                <Row style={{ justifyContent: 'center' }}>
                                    <Col md={10} xl={10} xs={12}>
                                        <Fade delay={700}>
                                            <p style={{
                                                textAlign: 'center',
                                                fontSize: '1em',
                                                color: '#4D4D4D'
                                            }}>
                                                As funcionalidades de um ERP proporcionam uma série de benefícios que contribuem para:
                                                Fortalecer os processos internos automatizando-os e reduzindo o retrabalho
                                                Maximizar o desempenho das equipes, permitindo que se concentram no que é realmente essencial à estratégia e aos resultados da companhia.
                                                Um sistema robusto, integrado e atualizado, despontam atributos capazes de alavancar todo o workflow empresarial.
                                                Integração com outras tecnologias, no mundo de hoje, toda a informação pode e deve estar conectada.
      </p>

                                        </Fade>
                                    </Col>
                                </Row>
                                <Row style={{ padding: '70px, 0px', textAlign: 'justify',  marginTop:20, display: 'flex', flexDirection: 'row', alignItems: 'stretch'}}>
                                    <Col md={4}>
                                        <CardBlack> 
                                            <h2>Mobilidade</h2>
                                            <p>
                                                As soluções em nuvem proporcionaram uma grande guinada no acesso remoto às 
                                                informações estratégicas. Agora, via dispositivos móveis, é possível 
                                                operar o ERP de qualquer lugar e a qualquer hora.
                                            </p>
                                        </CardBlack>
                                    </Col>
                                    <Col md={4}>
                                        <CardGold>
                                            <h2>BI (Business Intelligence)</h2>
                                            <p>
                                                As ferramentas de BI figuram como um aparato fundamental à administração 
                                                enxuta e visionária. Por meio da coleta, do armazenamento, da análise e do 
                                                compartilhamento dos dados é possível enxergar oportunidades e construir diferenciais.
                                            </p>
                                        </CardGold>
                                    </Col>
                                    <Col md={4}>
                                        <CardBlack>
                                            <h2>Inteligência artificial</h2>
                                            <p>
                                                Outro aspecto que deve ser incorporado aos ERPs é a inteligência artificial. 
                                                Com a ajuda dela, essas ferramentas devem, em breve, oferecer informações e 
                                                sugestões cada vez mais capazes de melhorar a produtividade nas empresas.
                                            </p>
                                        </CardBlack>
                                    </Col>
                                </Row>
                            </SolutionBenefitsWrapper>
                        </Container>
                    </Container>
                </>
            )} />
        );
    }
}


export default withRouter(DigitalManagement);