import styled from 'styled-components';
import { ButtonBack, ButtonNext } from 'pure-react-carousel';
import {device} from '../../../../css/devices'

export const OgButtonRounded = styled.a`
    padding: 8px 40px;
    background: transparent;
    border: 2px solid #EDD47A;
    border-radius: 60px;
    color: white;
    font-family: 'Helvetica';
    font-weight: 600;
    text-decoration: none;
    transition: 0.2s ease;

    :hover{
        background: #EDD47A;
        color: black;
    }
`;

export const OgTitle = styled.h1`
    color: white;
    font-size: 3em;
    font-weight: 600;
    font-family: 'Helvetica';
`

export const Subtitle = styled.h3`
    font-size: 1.7em;
    color: #CAB56D;
    font-family: 'Helvetica';
    font-weight: 500;

`;

export const Background = styled.div`
    z-index:1;    
    overflow: hidden;
    position:absolute;
    width: 100%;
    height: 475px;

    @media ${device.xs} {
            height: 620px;
        }

    ::before{
    content: ' ';
    width: 100%;
    height: 475px;
    position: absolute;
    background-image: linear-gradient(to right, rgba(0,0,0,0.9), rgba(0,0,0,0.9));
    z-index: 1;

        @media ${device.xs} {
            height: 620px;
        }
    }

  

`;

export const Image = styled.img`
    min-width: 100%;
    position: relative;
    z-index: 0;  
`;
export const CardBlack = styled.div`
    max-width: 100%;
    min-height:100%;
    color: white;
    padding: 5px 10px;
    background: rgb(36,36,36);
    background: linear-gradient(137deg, rgba(36,36,36,1) 0%, rgba(61,61,61,1) 100%); 
    
`;
export const CardGold = styled.div`
    max-width: 100%;
    min-height:100%;
    padding: 5px 10px;
    background: rgb(203,182,110);
    background: linear-gradient(137deg, rgba(203,182,110,1) 0%, rgba(236,225,186,1) 100%);  
`;



export const ImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 27px;
        
    :after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url(./assets/svg/ornament-05.svg);
        background-repeat: no-repeat;
        background-position: right;
        background-size: inherit;
    }

`

export const MsvTitle = styled.h1`
    font-family: 'Helvetica';
    font-size: 2.1em;
    text-align: center;
    color: #4D4D4D;
`
export const MsvParagraph = styled.p`
    width: 100%;
    padding: 30px;
    color: #4D4D4D;
    font-family: 'Helvetica';
    background: #EDD47A;

`
export const MsvUl = styled.ul`
    width: 100%;
    padding-top: 5px;
    padding-bottom: 15px;
    padding-left: 30px;
    color: #4D4D4D;
    font-family: 'Helvetica';
    background: #EDD47A;

`
export const SolutionBenefitsWrapper = styled.div`
    font-family: 'Helvetica';

    
`
export const SolutionsFeaturesWrapper = styled.div`
    font-family: 'Helvetica';

    
`
export const BusinessImage = styled.div`
   width: 100%;
   height:600px;
   max-height:600px;
   background-image: url(${props => props.src});
   background-repeat: no-repeat;
   background-size: 100%;
   position: relative;
   z-index: -1;

   @media ${device.xs}{
    height:340px;
   } 
    
`

export const ButtonSlideBack = styled(ButtonBack)`
    position: absolute; 
    top: 50%;
    left: 0;
    appearance: none;
    padding-top: 4px;
    border: 2px solid #4D4D4D;
    background:transparent;

    :hover{
        background: #EDD47A;        
    }
     
`

export const ButtonSlideNext = styled(ButtonNext)`
    position: absolute; 
    top: 50%;
    right: 0;
    appearance: none;
    border: 2px solid #4D4D4D;
    background:transparent;
    padding-top: 4px;

    :hover{
        background: #EDD47A;   
        border-color:  #EDD47A;   
    }
`

export const SlideContent = styled.div`
    
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    margin-left: 50px;

    @media ${device.xs || device.sm} {
        flex-direction: column;
        margin-left: 0;
    }
    
`
 
export const SlideItem = styled.div`
        width: 250px;
        height: 250px;
        background: url(${props => props.image});
        background-size: 250px;
        

        div{
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            cursor: pointer;

            h2{
                font-family: 'Helvetica';
                color: white;
                text-align: center;
            }
        }

        :hover div{
            opacity: 1;
        }

        
`
