import React from "react";
// import Background from '../assets/images/dev.png';
import Background from '../../../assets/images/card-contato.png'
import '../../../../src/main.css';

// reactstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function SectionContato() {
  return (
    <>
    <section className="section section-lg pt-0">
            <Container>
              <br />
              <br />
              <Card className="bg-gradient-warning shadow-lg border-0">
                <div className="p-5 oculto3">
                  <Row className="align-items-center">
                    <Col lg="6">
                      {/* <h3 className="">
                        Venha se juntar a nós.
                      </h3>
                      <p className="lead  mt-3">
                      A Ongold entende que o sucesso de qualquer empresa não se faz sozinho 
                      e que o trabalho em parceria é fundamental para alavancar resultados 
                      positivos. Seja você também um parceiro da Ongold...
                      </p> */}
                    </Col>
                    <Col className="ml-lg-auto" lg="6">
                    <h3 className="">
                        Venha se juntar a nós.
                      </h3>
                      <p className="lead  mt-3">
                      A Ongold entende que o sucesso de qualquer empresa não se faz sozinho 
                      e que o trabalho em parceria é fundamental para alavancar resultados 
                      positivos. Seja você também um parceiro da Ongold...
                      </p>
                      <Button
                        block
                        className="btn-white"
                        color="default"
                        href="https://wa.me/558197987157"
                        target="_blank"
                        size="lg"
                        style={{background: '#E7CF78', borderColor: '#E7CF78', color: '#fff'}}
                      >
                        FALE COM NOSSA EQUIPE
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div id='faleconosco'></div>
              </Card>
            </Container>
          </section>
  </>
  );
}

export default SectionContato;
